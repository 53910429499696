import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Schedules extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Program/Schedules</h4>
            <div className="par">
            The presentation schedule will be provided during the month of July 2024.
            {/* To download a copy of the Schedule Booklet in .pdf <a href="" target="_blank" rel="noopener noreferrer">Click Here</a> (current as of July 12, 2024) 
            <br/>
            To download a copy of the Schedule Booklet in .doc <a href="" target="_blank" rel="noopener noreferrer">Click Here</a> (current as of July 12, 2024)
            <br/>
            To download a copy of the Book of Abstracts <a href="" target="_blank" rel="noopener noreferrer">Click Here</a> (current as of July 12, 2024).

            <br/> <br/>

            To prepare the congress program/schedule, the title of all papers, authors names, affiliations were extracted from the meta-data of the draft paper submissions (manuscripts submitted by the authors for evaluation). Any changes to the titles of the papers, authors names, affiliations, ... are not reflected in this program. The FINAL versions of the papers (uploaded to the publication portal) will have the correct information.

            <br/> <br/>
            The ZOOM links will be provided (by email) to the REGISTERED authors, speakers, and participants by July 10, 2024. 
            <br/><br/>
            In case of questions during the conference dates (July 15-18, 2024), send email to: <b>CSCE.ZOOM@gmail.com</b>
            </div>
            
            <h6 className="par_header">IMPORTANT: COVID-19 Rapid Testing</h6>
            <div className="par">
            For the safety of all, it is IMPORTANT and NECESSARY that all CSCE 2024 on-site (physical) attendees and speakers to take the COVID-19 Rapid Test. CSCE 2024 has arranged a rapid testing protocol on site at the convention (at no cost to the attendees). Please schedule an appointment (all appointments will be between 06:30am and 10:30am on July 16, 2024) to take the test. In order to schedule your appointment, please use the link below - the test will take only a few minutes and the results will be provided in about 30 minutes.
            <br/> <br/>
            <a target="_blank" href="https://www.impacthealth.com/csce2024/ ">https://www.impacthealth.com/csce2024/</a>
            <br/>
            The above is a simple (user-friendly) system - but if you need instructions, please <a href="/static/COVID-19-rapidtest-CSCE2024.pdf" target="_blank" rel="noopener noreferrer">Click Here</a>
            <br/> <br/>
            In case of difficulties to use the above web site to schedule a time for your test, then please show up at the testing location on July 16, 2024 (Wednesday) between 6:30am and 10:30am. The location of COVID-19 rapid testing is very close to the conference ballrooms (only about 1 minute walk away).
            <br/>
            COVID-19 rapid testing location: EGYPTIAN BALLROOM G, Luxor hotel, 3900 Las Vegas Blvd South, Las Vegas, Nevada 89119, USA. */}
            </div>
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Schedules;