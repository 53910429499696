import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsACC extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">ACC'24 - The 8th International Conference on Applied Cognitive Computing</h4>
            <div className="par">
            <b>ACC</b> is an international conference that serves researchers, scholars, professionals, students, and academicians who are looking to both foster working relationships and gain access to the latest research results. It is being held jointly (same location and dates) with a number of other research conferences; namely, The 2024 World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE'24).
            <br/><br/>
            We anticipate having between 1,000 and 2,000 participants in the Congress. The congress includes 21 major tracks composed of: 122 technical, research, and panel sessions as well as a number of keynote lectures and tutorials; all will be held simultaneously, same location and dates: July 22-25, 2024. Last year, the Congress had attracted speakers/authors and participants affiliated with over 158 different universities (including many from the top 50 ranked institutions), major IT corporations (including: Microsoft, Google, Apple, SAP, Facebook, Oracle, Amazon, Yahoo, Samsung, IBM, Ebay, GE, Siemens, Philips, Ericsson, BAE Systems, Hitachi, NTT, Twitter, Uber Technologies, ...), major corporations (including: Exxon Mobil, Johnson & Johnson, JPMorgan Chase, PetroChina, GlaxoSmithKline, HSBC, Airbus, Boeing, Hyundai, Goldman Sachs, Deutsche Bank, ...), government research agencies (NSF, NIH, DoE, US Air Force, NSA National Security Agency, Central Intelligence Agency, ...), US national laboratories (including, NASA National Aeronautics and Space Administration, ANL Argonne National Lab, Sandia National Lab, ORNL Oak Ridge National Lab, Lawrence Berkeley National Lab, Lawrence Livermore National Lab, Los Alamos National Lab, Pacific Northwest National Lab, ...), and a number of Venture Capitalists as well as distinguished speakers discussing Intellectual Property issues. Last year, 54% of attendees were from academia, 25% from industry; 20% from government and funding agencies; and 1% unknown. About half of the attendees were from outside USA; from 69 nations.
            <br/><br/>
            Cognitive computing systems use computerized models to simulate the human cognition process to find solutions in complex situations where the answers may be ambiguous and uncertain. While the term cognitive computing is often used interchangeably with artificial intelligence (AI), the phrase is closely associated with IBM's cognitive computer system, Watson..
            <br/><br/>
            The primary goal of this conference is to provide a platform for researchers, scientists, industry experts and scholars to share their novel ideas and research results on the application of human cognition models in various practical computing applications. Through this conference, the organizers would like to develop an interdisciplinary venue to contribute and discuss the ongoing innovations, applications and solutions to challenging problems of engineering human brain processes, learning mechanisms and decision making processes.
            <br/><br/>
            <b>You are invited to submit a paper for consideration.</b> All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings of Congress (CSCE). The published proceedings will be submitted for indexing in the Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; Scopus; EI Engineering Index; Google Scholar; DBLP; and others. Therefore, this year’s proceedings will have an elevated science indexation when compared to prior years’ publications.
            <br/>
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            {/* <br />
            Papers should be typeset by using the general typesetting instructions available at (select "US letter" option for accessing templates):
            <br />
            <a href="https://www.ieee.org/conferences/publishing/templates.html " target="_blank" rel="noopener noreferrer">https://www.ieee.org/conferences/publishing/templates.html</a> <br />
            (i.e., single line spacing, 2-column format). All submissions must be original (papers must not have been previously published or currently being considered by others for publication). */}

            <br /><br />
            Past publications of proceedings: <a href="https://american-cse.org/csce2024/publisher" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/publisher</a> and <a href="https://american-cse.org/csce2024/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/special_issues</a>
            <br /> <br />
            Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <br />
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a>
            <br/><br/>
            All researchers, authors and scientists are respectfully encouraged to contribute to the conference with submissions of their novel research papers having unpublished and original research with theoretical, mathematical, empirical and experimental work in the following areas of applied cognitive computing and are cordially invited for presentation at the conference. 
            <br/>
            - Novel Computationally Intelligent algorithms <br/>
            - Bio Inspired Cognitive Algorithms<br/>
            - Improving Cognition in machine learning systems<br/>
            - Modeling Human Brain processing systems<br/>
            - Multimodal learning systems<br/>
            - Autonomous learning systems<br/>
            - Reinforced learning<br/>
            - Cognitive evolution<br/>
            - Concept Drift and Evolution<br/>
            - Cognitive inferential systems<br/>
            - Cognitive improvement in deep learning networks<br/>
            - Hebbian Learning Systems<br/>
            - Advancements in Neural Networks<br/>
            - Fuzzy Engineering<br/>
            - Multiscale Learning systems<br/>
            - Fractal based learning and decision support systems<br/>
            - Application of chaos Engineering in machine intelligence<br/>
            - Dynamical learning systems<br/>
            - Application of Information Theory in Machine Intelligence<br/>
            - Application of linear and nonlinear optimization theory in machine intelligence<br/>
            - New algorithms for Predictive Analytics and Applied Data Science<br/>
            - Self-Adaptive and Self Organizing Systems<br/>
            - Manifold and Metric learning<br/>
            - Cognitive Modeling, Visualization and Analytics of Big Data<br/>
            - Application of Graph Theoretic approaches in dimensionality reduction and machine intelligence<br/>
            - Information and Knowledge retrieval and searching algorithms<br/>
            - Big data knowledge mining<br/>
            - Mathematical modeling of Big Data and Artificial Intelligence<br/>
            - Cognitive Signal Processing<br/>
            - Rough Set Theory<br/>
            - Knowledge Representation and Classification Systems<br/>
            - Agent Based Modeling in Machine Learning Systems<br/>
            - Information Processing and Decision Making Systems<br/>
            - Natural Language Processing<br/>
            - Big Data Fusion and Information Retrieval<br/>
            - Time and Space Analysis in Machine Learning<br/>
            - New application of classical stochastic and statistical analysis for big data machine learning<br/>
            - Bayesian modeling applications<br/>
            - Hierarchical learning systems<br/>
            - Cognition in Genetic and Evolutionary algorithms for learning and decision making<br/>
            - Nature inspired cognitive computing algorithms<br/>
            - Heuristic Analysis<br/>
            - Cognitive Feature Extraction<br/>
            - Extraction of latent semantics from big data<br/>


            </div>          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsACC;