import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class Pub extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Publication</h4>
            <div className="par">
            All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings of Congress (CSCE). The published proceedings will be submitted for indexing in the Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; Scopus; EI Engineering Index; Google Scholar; DBLP; and others. Therefore, this year’s proceedings will have an elevated science indexation when compared to prior years’ publications.
            <br/>

            <br />

            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)

            {/* <br />
            <b>CSCE 2023 Proceedings (Last Year’s Xplore Indexation) can be found at:</b>
            <br />
            <a href="https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding</a> */}

            {/* <br />
            Papers should be typeset by using the general typesetting instructions available at (select "US letter" option for accessing templates):
            <br />
            <a href="https://www.ieee.org/conferences/publishing/templates.html " target="_blank" rel="noopener noreferrer">https://www.ieee.org/conferences/publishing/templates.html</a> <br />
            (i.e., single line spacing, 2-column format). All submissions must be original (papers must not have been previously published or currently being considered by others for publication). */}

            <br /><br />
            <b>Past Publications of Proceedings can be found at:</b> <a href="https://american-cse.org/csce2024/publisher" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/publisher</a> and <a href="https://american-cse.org/csce2024/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/special_issues</a>
            <br />
            {/* Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <br />
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a> <br /> */}
            CSCE 2023 Proceedings (Last Year’s Xplore Indexation) can be found at: <br />
            <a href="https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding</a>

              <br /><br />
              <b>Submission of Papers for Evaluation:</b> <br />
              Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <br />
              <a href="https://american-cse.org/drafts/">https://american-cse.org/drafts/</a>

            <br /><br />
            The sponsors and the steering committee of this Congress organize two annual conferences (CSCE in July and CSCI in December of each year). A subset of past conference proceedings (publications) of CSCI and CSCE appear below (there are also multiple books in the publication pipeline):
            <br />
            <br />
            Past conference proceedings can be accessed via IEEE Xplore Digital Library at:
            <br />
           <a href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065" target="_blank" rel="noopener noreferrer">Volume I (2014)</a> <br />
           <a href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285" target="_blank" rel="noopener noreferrer">Volume II (2014)</a> <br />
           <a href="https://ieeexplore.ieee.org/xpl/conhome/7420813/proceeding" target="_blank" rel="noopener noreferrer">Volume (2015)</a> <br />
           <a href="https://ieeexplore.ieee.org/xpl/conhome/7879541/proceeding" target="_blank" rel="noopener noreferrer">Volume (2016)</a> <br />
           <a href="https://ieeexplore.ieee.org/xpl/conhome/8554238/proceeding" target="_blank" rel="noopener noreferrer">Volume (2017)</a> <br />
           <a href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding" target="_blank" rel="noopener noreferrer">Volume (2018)</a> <br />
           <a href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding" target="_blank" rel="noopener noreferrer">Volume (2019)</a> <br />
           <a href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding" target="_blank" rel="noopener noreferrer">Volume (2020)</a> <br />
           <a href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding" target="_blank" rel="noopener noreferrer">Volume (2021)</a> <br />
           <a href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings" target="_blank" rel="noopener noreferrer">All CSCI Proceedings</a> <br />

           <br />
           The published papers can also be viewed via IEEE Xplore, Scopus, Ei Compendex, and other science indexation systems.


            </div>


          <h4 className="par_header">CSCE/CSCI Proceedings, Books & Journal Special Issues</h4>
            <div className="par">For a subset of the recent proceedings and a small subset of the books (and journal special issues) that have been published based on the extended versions of many of our congress papers (CSCE and CSCI), see the listing available at:
            <br />
            <a href="https://american-cse.org/csce2024/special_issues " target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/special_issues</a>
            </div>

          <h4 className="par_header">Citation Data</h4>
            <div className="par">
            HIGHLY CITED PAPERS (a small subset) <br/><br/>
            The list below includes a small subset of papers published based on the proceedings of this congress. These papers have received a high citation by others in their respective fields. Data extracted from Google Scholar is shown below - however, the citation data shown in Microsoft Academic is about 20% higher than shown here:
            <br/><br/>

            827 citations to: QoS Support in Wireless Sensor Networks: A Survey <br/>
            Dazhi Chen, Pramod K. Varshney<br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C11&q=QoS+Support+in+Wireless+Sensor+Networks%3A+A+Survey&btnG=">Link</a>
            <br/>
            <br/>
            935 citations to: A Survey of Face Recognition Techniques (Extended)<br/>
            Rabia Jafri, Hamid R. Arabnia<br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C11&q=A+Survey+of+Face+Recognition+Techniques&btnG=">Link</a>
            <br/>
            <br/>
            886 citations to: Energy-efficient Management of Data Center Resources for Cloud Computing: A vision, architectural elements, and open challenges<br/>
            Rajkumar Buyya, Anton Beloglazov, Jemal H. Abawajy<br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C11&q=Energy-efficient+Management+of+Data+Center+Resources+for+Cloud+Computing%3A+A+vision%2C+architectural+elements%2C+and+open+challenges&btnG=">Link</a>
            <br/>
            
            <br/>
            1051 citations to: The Class Imbalance Problem: Significance and Strategies<br/>
            Nathalie Japkowicz<br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C11&q=The+Class+Imbalance+Problem%3A+Significance+and+Strategies&btnG=">Link</a>
            <br/>
            <br/>
            430 citations to: An Economy Driven Resource Management Architecture for Global Computational Power Grids<br/>
            Rajkumar Buyya, David Abramson, Jonathan Giddy<br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C11&q=An+Economy+Driven+Resource+Management+Architecture+for+Global+Computational+Power+Grids&btnG=">Link</a>
            <br/>
            <br/>
            430 citations to: Adapting Particle Swarm Optimization to Dynamic Environments<br/>
            Anthony Jack Carlisle, Gerry V. Dozier<br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C11&q=Adapting+Particle+Swarm+Optimization+to+Dynamic+Environments&btnG=">Link</a>
            <br/>
            <br/>
            401 citations to: Prevention of Cooperative Black Hole Attack in Wireless Ad Hoc Networks<br/>
            Sanjay Ramaswamy, Huirong Fu, Manohar Sreekantaradhya, John Dixon, Kendall E. Nygard<br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C11&q=Prevention+of+Cooperative+Black+Hole+Attack+in+Wireless+Ad+Hoc+Networks&btnG=">Link</a>
            <br/>
            <br/>
            417 citations to: An Indoor Bluetooth-Based Positioning System: Concept, Implementation and Experimental Evaluation<br/>
            Silke Feldmann, Kyandoghere Kyamakya, Ana Zapater, Zighuo Lue<br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C11&q=An+Indoor+Bluetooth-Based+Positioning+System%3A+Concept%2C+Implementation+and+Experimental+Evaluation&btnG=">Link</a>
            <br/>
            <br/>
            281 citations to: Building a Sensor Ontology: A Practical Approach Leveraging ISO and OGC Models<br/>
            David J. Russomanno, Cartik R. Kothari, Omoju A. Thomas<br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C11&q=Building+a+Sensor+Ontology%3A+A+Practical+Approach+Leveraging+ISO+and+OGC+Models&btnG=">Link</a>
            <br/>
            <br/>
            250 citations to: A Deadline and Budget Constrained Cost-Time Optimisation Algorithm for Scheduling Task Farming Applications on Global Grids<br/>
            Rajkumar Buyya, M. Manzur Murshed<br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C11&q=A+Deadline+and+Budget+Constrained+Cost-Time+Optimisation+Algorithm+for+Scheduling+Task+Farming+Applications+on+Global+Grids&btnG=">Link</a>
            <br/>
            <br/>
            250 citations to: Runtime Assurance Based On Formal Specifications<br/>
            Insup Lee, Sampath Kannan, Moonjoo Kim, Oleg Sokolsky, Mahesh Viswanathan<br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C11&q=Runtime+Assurance+Based+On+Formal+Specifications&btnG=">Link</a>
            <br/>
            <br/>
            251 citations to: Folk Music Classification Using Hidden Markov Models<br/>
            Wei Koong Chai, Barry L. Vercoe<br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C11&q=Folk+Music+Classification+Using+Hidden+Markov+Models&btnG=">Link</a>
            <br/>
            <br/>
            201 citations to: MPEG Video Encryption in Real-time Using Secret Key Cryptography<br/>
            Changgui Shi, Sheng-Yih Wang, Bharat K. Bhargava<br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C11&q=MPEG+Video+Encryption+in+Real-time+Using+Secret+Key+Cryptography&btnG=">Link</a>
            <br/>
            <br/>
            195 citations to: Alchemi: A .NET-based Enterprise Grid Computing System<br/>
            Akshay Luther, Rajkumar Buyya, Rajiv Ranjan, Srikumar Venugopal<br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C11&q=Alchemi%3A+A+.NET-based+Enterprise+Grid+Computing+System&btnG=">Link</a>
            <br/>
            <br/>
            195 citations to: Towards Realistic Million-Node Internet Simulation<br/>
            James H. Cowie, Hongbo Liu, Jason Liu, David M. Nicol, Andrew T. Ogielski<br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C11&q=Towards+Realistic+Million-Node+Internet+Simulation&btnG=">Link</a>
            <br/>
            <br/>
            191 citations to: Bayesian Biclustering of Gene Expression Data<br/>
            Jiajun Gu, Jun S. Liu<br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C11&q=Bayesian+Biclustering+of+Gene+Expression+Data&btnG=">Link</a>
            <br/>
            <br/>
            There are over 566 other papers published by the congress that have received citations above 50.<br/>
            <br/><br/>
            {/* <b>OVERALL CITATION DATA</b>
            <br/>
            As of December 30, 2021, the proceedings of the World Congress in Computer Science, Computer Engineering, & Applied Computing (CSCE) have received 35,382 citations (includes 3,805 self-citations). Citation data is obtained from Microsoft Academic Search. The citation data does not even include more than 18,500 other citations to papers published by conferences whose first offerings were initiated by the Congress. The paper acceptance rate for each track has been between 18% and 25% with about 16% of the remaining papers accepted as posters. All such information is published in the printed conference books/proceedings. As of December 30, 2021, there has been 648,800 book chapter/paper downloads from the books published based on the CSCE publications. */}
            </div>

            
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Pub;