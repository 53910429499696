import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Keynote10 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Keynotes/Invited Lectures</h4>
            <br />
            <div style={{textAlign: "center"}}>
            <b>The Future is Here: AI & LLMs</b><br/>
            Massoud Alibakhsh<br/>
            Founder and CEO<br />
            OMADEUS<br />
            Atlanta Georgia, USA <br />  
          </div>
        
            <h6 className="par_header">Abstract:</h6>
            <div className="par">
            
            The proliferation of AI tools, such as ChatGPT and other Large Language Models (LLMs), has raised expectations regarding their widespread adoption and the future of automation. The hype and expectations suggest futuristic and truly intelligent machines with which humans interact using only natural language and thereby ending the need for traditional UIs where users have to direct and manage information processing. The current market trajectory suggests that most financial and expert human resources are focused on improvements of LLMs (more parameters and faster processing) towards achieving this goal. However, this talk delves into the limitations and shortcomings of LLMs which makes achieving this goal highly unlikely if not impossible. <br />
            We discuss and base this conclusion mainly on the lack of reliability of the generative part of LLMs  and the challenges of adopting workflows 
Instead we present a new model where a collection of intelligent agents/programs based on OMIO with focused access to constrained datasets such as a person’s banking information or a diet plan are arranged within an OMIO based operating system.
<br />
This is similar to current mobile platforms but the apps will be rewritten with OMIO and the OS is also OMIO based. In this way, the operating system can communicate with the user and the installed apps  using natural language. The OS executes the user’s wishes using available specialized apps on its platform.
The Object Messaging and Intelligent Objects paradigm offers an elegant path for deep integration of LLMs and software applications with structured data, while optimizing communication among humans[1] in the workplace producing human and AI collective intelligence.
            </div>

            <h6 className="par_header">Speaker's Biography:</h6>
            <div className="par">
            Massoud is a tech entrepreneur with a background in Electrical Engineering and Computer Science. He's currently leading the task of creating the next revolution in human and machine communication at Xeba Technologies. From 2000 to 2018 Massoud was founder, CEO and chief architect for one of the first cloud-based Medical Practice Management and Electronic Health Record Systems marketed under the NueMD brand. NueMD was a small offshoot startup which survived the dot com era and grew from a handful of talents to over 250 employees when it was acquired in 2018. Today it is part of Global Payment Network, a fortune 500 company. Creating multiple software technology startups & building comprehensive operations consisting of production, marketing, sales, support, implementation, and administration has given him a true and unique insight into the fundamental challenges and the urgent need for optimal and synchronized communication especially in tech producing organizations. 

            <br/> <br/>
            <div style={{textAlign: "center"}}>
                <img src="/static/alibakhsh.jpeg" height="250px" alt=""/>
            </div>
            </div>

          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Keynote10;