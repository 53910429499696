import React, { Component } from 'react';
import { Grid, Row, Col } from 'rsuite';
import { Link } from 'react-router';

class Committees extends Component {

  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Organization Committees</h4>

          <h6 className="par_header">Steering Committee Co-chairs</h6>
          <div className="par">
          <b>- Professor Hamid R. Arabnia</b> - Editor-in-Chief, The Journal of Supercomputing (Springer); Fellow & Adviser of Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC); Professor Emeritus, School of Computing, The University of Georgia, Georgia, USA <br />
          <b>- Professor Leonidas Deligiannidis</b> - Professor, School of Computing and Data Science, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
          <b>- Professor Fernando G. Tinetti</b> - Facultad de Informatica, Universidad Nacional de La Plata, La Plata, Argentina; Researcher CIC Prov. Bs. As., Argentina; Recipient of the NSF-TCPP Early Adopter Award, NSF/IEEE-TCPP Curriculum Initiative on Parallel and Distributed Computing <br />
          <b>- Professor Quoc-Nam Tran</b> - Department of Computer Science, Southeastern Louisiana University, Louisiana, USA <br />
          </div>

          <h6 className="par_header">Members of Steering Committee</h6>
          <div className="par">
          <b>- Professor Babak Akhgar</b> - Fellow of the British Computer Society, CITP, Professor of Informatics, Co-Director of CENTRIC (Centre of Excellence in Terrorism, Resilience, Intelligence & organized Crime research), Sheffield Hallam University, Sheffield, United Kingdom <br />
          <b>- Professor Abbas M. Al-Bakry</b> - University President, University of IT & Communications, Baghdad, Iraq <br />
          <b>- Professor Emeritus Nizar Al-Holou</b> - Electrical and Computer Engineering; Vice Chair, IEEE/SEM-Computer Chapter, University of Detroit Mercy, Michigan, USA <br />
          <b>- Professor Hamid R. Arabnia</b> - Editor-in-Chief, The Journal of Supercomputing (Springer); Fellow & Adviser of Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC); Professor Emeritus, School of Computing, The University of Georgia, Georgia, USA <br />
          <b>- Professor Rajab Challoo</b> - Professor and Chairman, EECS Department; Director, Center for Controls, Robotics, Intelligent Systems and Sensors Research, CRISS; President, Faculty Senate; Former Chair of Computer Society (for 7 years); Texas A&M University-Kingsville, Kingsville, Texas, USA <br />
          <b>- Professor Chien-Fu Cheng</b> - Professor of CS, Department of Computer Science and Information Engineering, Graduate Institute of Networking & Communication, Tamkang University, Taiwan, R.O.C. <br />
          <b>- Professor Hyunseung Choo</b> - Director of Intelligent HCI Convergence Research Center (ITRC) of Ministry of Information and Communication; International Advisory Chair, ACM RACS; College of Information and Communication Engineering, Professor of IS, Department of Interaction Science, Sungkyunkwan University (SKKU), Suwon, Republic of Korea <br />
          <b>- Professor Emeritus Kevin Daimi</b> - Computer Science and Software Engineering Programs, Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
          <b>- Professor Leonidas Deligiannidis</b> - Professor, School of Computing and Data Science, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
          <b>- Professor Eman M. El-Sheikh</b> - Director, Center for Cybersecurity; Member of AWIS (Association for Women in Science); Professor of CS, Department of Computer Science, University of West Florida, Pensacola, Florida, USA <br />
          <b>- Professor Mary Mehrnoosh Eshaghian-Wilner</b> – Adjunct Professor of Electrical Engineering, University of California Los Angeles, UCLA, California, USA <br />
          <b>- Dr. David L. Foster</b> - Associate Professor, Computer Engineering; Kettering University, Michigan, USA <br />
          <b>- Professor Henry Hexmoor</b> - Department of Computer Science, Southern Illinois University at Carbondale, Carbondale, Illinois, USA <br />
          <b>- Professor Ching-Hsien (Robert) Hsu</b> - Department of Computer Science and Information Engineering, Chung Hua University, Hsinchu, Taiwan, R.O.C.; and Distinguished Chair Professor, Tianjin University of Technology, P. R. China <br />
          <b>- Professor James J. (Jong Hyuk) Park</b> - Department of Computer Science and Engineering (DCSE), SeoulTech, Republic of Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Republic of Korea <br />
          <b>- Professor Mohammad S. Obaidat</b> - Fellow of IEEE; Fellow of SCS; Past President of Society for Modeling and Simulation International (SCS); Editor-in-Chief of International Journal of Communication Systems (Wiley); Editor, IEEE Wireless Communications; Professor, Monmouth University, New Jersey, USA <br />
          <b>- Dr. Marwan Omar </b> - Information Technology and Management, Illinois Institute of Technology, Chicago, Illinois, USA <br />
          <b>- Professor Shahram Rahimi</b> - Chair, Department of Computer Science and Engineering, Member of IEEE Computational Intelligence New Standards Committee; Editor-in-Chief, Computational Intelligence Theory & Practice; Mississippi State University, Mississippi, USA <br />
          <b>- Dr. Gerald Schaefer</b> - Senior Lecturer, Computer Science; Leader of the Vision, Autonomous and Human-Computer Systems Research Group (VAHC); Loughborough University, United Kingdom <br />
          <b>- Professor Fernando G. Tinetti</b> - Facultad de Informatica, Universidad Nacional de La Plata, La Plata, Argentina; Researcher CIC Prov. Bs. As., Argentina; Recipient of the NSF-TCPP Early Adopter Award, NSF/IEEE-TCPP Curriculum Initiative on Parallel and Distributed Computing <br />
          <b>- Professor Quoc-Nam Tran</b> – Department of Computer Science, Southeastern Louisiana University, Louisiana, USA <br />
          <b>- Professor Shiuh-Jeng Wang</b> - Department of Information Management; Director, Information Cryptology & Construction Lab. (ICCL); Vice-President / Deputy President, Chinese Cryptology and Information Security Association (CCISA), R.O.C.; Chair, Forensic Research & Development task force group (ICCL-FROG); Professor, Central Police University, Taiwan, R.O.C. <br />
          <b>- Professor Layne T. Watson</b> - Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
          <b>- Professor Chao-Tung Yang</b> - Director, Computer Center; Executive Secretary, Advisory Committee for Research and Lab. Equipment; High-Performance Computing Laboratory; Professor, Department of Computer Science, Tunghai University, Taichung City, Taiwan R.O.C. <br />
          <b>- Professor Mary Yang</b> - Director of MidSouth Bioinformatics Center and Director of Joint Bioinformatics Ph.D. Program, University of Arkansas George Washington Donaghey College of Engineering & IT and University of Arkansas for Medical Sciences, Little Rock, Arkansas, USA <br />
          </div>

          <h6 className="par_header">Co-Chairs of Research Tracks</h6>
          <div className="par">
          <b>- Professor Abeer Alsadoon (Co-Chair, Health Informatics)</b> - Charles Sturt University, Australia <br />
          <b>- Dr. Soheyla Amirian (Co-Chair, Computer Vision & AI)</b> - Faculty, School of Computing, University of Georgia, USA <br />
          <b>- Professor Hamid R. Arabnia (C-Chair, HPC)</b> - Editor-in-Chief, The Journal of Supercomputing (Springer); Professor Emeritus, School of Computing, The University of Georgia, Georgia, USA <br />
          <b>- Professor Kevin Daimi (Co-Chair, Security)</b> – Professor Emeritus, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
          <b>- Professor Leonidas Deligiannidis (Co-Chair, Imaging Science)</b> - Professor, School of Computing and Data Science, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
          <b>- Dr. Richard Dill (Co-Chair, Military and Defense Modeling)</b> - Computer Science and Engineering Department, US Air Force Institute of Technology (AFIT), USA <br />
          <b>- Professor Ken Ferens (Co-Chair, Cognitive Computing)</b>; Department of Electrical and Computer Engineering, University of Manitoba, Winnipeg, MB, Canada <br />
          <b>- Dr. Farid Gharehmohammadi (Co-Chair, Computer Vision & AI)</b> - Mayo Clinic, Jacksonville, Florida, USA <br />
          <b>- Professor Michael R. Grimaila (Co-Chair, Military and Defense Modeling)</b> - Head, Systems Engineering & Management Department, US Air Force Institute of Technology (AFIT), USA <br />
          <b>- Professor Douglas D. Hodson (Co-Chair, Military and Defense Modeling)</b> - Computer Science and Engineering Department, US Air Force Institute of Technology (AFIT), USA <br />
          <b>- Dr. Javier Ordus (Co-Chair, Quantum Computing & AI)</b> - Adjunct Faculty, School of Engineering & Computer Science, Baylor University, Waco, Texas, USA <br />
          <b>- Dr. Pablo Rivas (Co-Chair, Quantum Computing & AI)</b> - School of Engineering & Computer Science, Baylor University, Waco, Texas, USA <br />
          <b>- Dr. Farzan Shenavarmasouleh (Co-Chair, Computer Vision & AI)</b> - R&D Engineer, MediaLab Inc, Georgia, USA <br />
          <b>- Dr. Robert Stahlbock (Co-Chair, Data Mining)</b> - Universitat Hamburg, Hamburg, Germany <br />
          <b>- Professor Masami Takata (Co-Chair, Mathematical Modeling)</b> - Nara Women's University, Nara-shi, Nara, Japan <br />
          <b>- Professor Quoc-Nam Tran (Co-Chair, Education & Bioinformatics)</b> – Department of Computer Science, Southeastern Louisiana University, Louisiana, USA <br />
          </div>

            <h4 className="par_header">Program Committees</h4>



            <h6 className="par_header">Applied Cognitive Computing (ACC) 2024 - Program Committee
</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            -	Prof. Emeritus Hamid R. Arabnia (Steering Committee); University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer); Editor-in-Chief, Transactions of Computational Science & Computational Intelligence (Springer); Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
            -	Dr. Afsaneh Banitalebi Dehkordi; Faculty of Computer Engineering, PNU University, Shahrekord, Iran <br />
            -	Prof. Juan Jose Martinez Castillo; Director, The Acantelys Alan Turing Nikola Tesla Research Group and GIPEB, Universidad Nacional Abierta, Venezuela <br />
            -	Prof. Leonidas Deligiannidis (Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
            -	Dr. Ken Ferens (Co-Chair); Department of Electrical and Computer Engineering, University of Manitoba, Winnipeg, MB, Canada <br />
            -	Prof. George A. Gravvanis; Director, Physics Laboratory & Head of Advanced Scientific Computing, Applied Math & Applications Research Group; Professor of Applied Mathematics and Numerical Computing and Department of ECE, School of Engineering, Democritus University of Thrace, Xanthi, Greece. <br />
            -	Prof. Byung-Gyu Kim; Multimedia Processing Communications Lab.(MPCL), Department of CSE, College of Engineering, SunMoon University, South Korea <br />
            -	Prof. Tai-hoon Kim; School of Information and Computing Science, University of Tasmania, Australia <br />
            -	Dr. Changyu Liu; College of Mathematics and Informatics, South China Agricultural University, Guangzhou, P. R. China and Visiting scientist, School of Computer Science, Carnegie Mellon University, USA <br />
            -	Dr. Muhammad Naufal Bin Mansor; Faculty of Engineering Technology, Department of Electrical, Universiti Malaysia Perlis (UniMAP), Perlis, Malaysia <br />
            -	Prof. Dr., Eng. Robert Ehimen Okonigene; Department of Electrical & Electronics Engineering, Faculty of Engineering and Technology, Ambrose Alli University, Edo State, Nigeria <br />
            -	Prof. James J. (Jong Hyuk) Park; DCSE, SeoulTech, Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Korea <br />
            -	Prof. Fernando G. Tinetti (Steering Committee); School of Computer Science, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
            -	Prof. Hahanov Vladimir; Vice Rector, and Dean of the Computer Engineering Faculty, Kharkov National University of Radio Electronics, Ukraine and Professor of Design Automation Department, Computer Engineering Faculty, Kharkov; IEEE Computer Society Golden Core Member; National University of Radio Electronics, Ukraine <br />
            -	Prof. Shiuh-Jeng Wang (Steering Committee); Director of Information Cryptology and Construction Laboratory (ICCL) and Director of Chinese Cryptology and Information Security Association (CCISA); Department of Information Management, Central Police University, Taoyuan, Taiwan; Guest Ed., IEEE Journal on Selected Areas in Communications. <br />
            -	Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
            -	Dr. Heng (Fred) Wu; Mathematics and Computer Science, West Virginia State University, Institute, West Virginia, USA <br />
            -	Prof. Jane You; Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong <br />
            -	Dr. Farhana H. Zulkernine; Coordinator of the Cognitive Science Program, School of Computing, Queen's University, Kingston, ON, Canada <br />
            </div>

            <h6 className="par_header">Bioinformatics & Computational Biology (BIOCOMP) 2024 - Program Committee</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            -	Prof. Abbas M. Al-Bakry; University President, University of IT and Communications, Baghdad, Iraq  <br />
-	Prof. Emeritus Nizar Al-Holou; Electrical and Computer Engineering Department; Vice Chair, IEEE/SEM-Computer Chapter; University of Detroit Mercy, Michigan, USA <br />
-	Prof. Emeritus Hamid R. Arabnia (Congress Steering Committee); School of Computing, The University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer); Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
-	Prof. Hikmet Budak; Professor and Winifred-Asbjornson Plant Science Chair Department of Plant Sciences and Plant Pathology Genomics Lab, Montana State University, Bozeman, Montana, USA; Editor-in-Chief, Functional and Integrative Genomics; Associate Editor of BMC Genomics; Academic Editor of PLosONE <br />
-	Prof. Emeritus Kevin Daimi (Congress Steering Committee); Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Leonidas Deligiannidis (Congress Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
-	Prof. Youping Deng; Professor & Director of Bioinformatics Core, Department of Complementary & Integrative Medicine; Co-Director, Genomics Shared Resource, University of Hawaii Cancer Center, University of Hawaii John A. Burns School of Medicine, Honolulu, Hawaii, USA <br />
-	Prof. Mary Mehrnoosh Eshaghian-Wilner (Congress Steering Committee); Professor of Engineering Practice, University of Southern California, California, USA; Adjunct Professor, Electrical Engineering, University of California Los Angeles, Los Angeles (UCLA), California, USA <br />
-	Prof. Ray Hashemi (Vice-Chair); College of Engineering and Computing, Georgia Southern University, Georgia, USA <br />
-	Prof. George Jandieri; Georgian Technical University, Tbilisi, Georgia; Chief Scientist, The Institute of Cybernetics, Georgian Academy of Science, Georgia; Ed. Member, International Journal of Microwaves and Optical Technology, The Open Atmospheric Science Journal, American Journal of Remote Sensing, Georgia <br />
-	Prof. Dr. Abdeldjalil Khelassi; Computer Science Department, Abou beker Belkaid University of Tlemcen, Algeria; Editor-in-Chief, Medical Technologies Journal; Associate Editor, Electronic Physician Journal (EPJ) - Pub Med Central <br />
-	Prof. Byung-Gyu Kim; Multimedia Processing Communications Lab.(MPCL), Department of Computer Science and Engineering, College of Engineering, SunMoon University, South Korea <br />
-	Prof. Dr. Guoming Lai; Computer Science and Technology, Sun Yat-Sen University, Guangzhou, P. R. China <br />
-	Dr. Ying Liu; Division of Computer Science, Mathematics and Science, College of Professional Studies, St. John's University, Queens, New York, USA <br />
-	Dr. Prashanti Manda; Department of Computer Science, University of North Carolina at Greensboro, USA <br />
-	Dr. Muhammad Naufal Bin Mansor; Faculty of Engineering Technology, Department of Electrical, Universiti Malaysia Perlis (UniMAP), Perlis, Malaysia <br />
- Dr. Andrew Marsh; CEO, HoIP Telecom Ltd (Healthcare over Internet Protocol), UK; Secretary General of World Academy of BioMedical Sciences and Technologies (WABT) a UNESCO NGO, The United Nations <br />
- Prof. Dr., Eng. Robert Ehimen Okonigene; Department of Electrical & Electronics Engineering, Faculty of Eng. and Technology, Ambrose Alli University, Edo State, Nigeria <br />
- Prof. James J. (Jong Hyuk) Park; Department of Computer Science and Engineering (DCSE), SeoulTech, Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Korea <br />
- Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
- Dr. Tse Guan Tan; Faculty of Creative Technology and Heritage, Universiti Malaysia Kelantan, Malaysia <br />
- Prof. Fernando G. Tinetti (Congress Steering Committee); School of Computer Science, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
- Prof. Quoc-Nam Tran (Congress Steering Committee); Department of Computer Science, Southeastern Louisiana University, Hammond, Louisiana, USA <br />
- Prof. Shiuh-Jeng Wang (Congress Steering Committee); Director of Information Cryptology and Construction Laboratory (ICCL) and Director of Chinese Cryptology and Information Security Association (CCISA); Department of Information Management, Central Police University, Taoyuan, Taiwan; Guest Ed., IEEE Journal on Selected Areas in Communications. <br />
- Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
- Prof. Mary Yang; Director, Mid-South Bioinformatics Center and Joint Bioinformatics Ph.D. Program, Medical Sciences and George W. Donaghey College of Engineering and Information Technology, University of Arkansas, USA <br />
- Prof. Jane You; Associate Head, Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong <br />
- Dr. Wen Zhang; Icahn School of Medicine at Mount Sinai, New York City, Manhattan, New York, USA; Board member, Journal of Bioinformatics and Genomics; Board member, Science Research Association <br />
- Dr. Hao Zheng; Novo Vivo, VP of Bioinformatics, California, USA
            </div>


            <h6 className="par_header">Biomedical Engineering & Sciences (BIOENG) 2024 - Program Committee</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            - Prof. Abeer Alsadoon (Co-Chair, Health Informatics) - Charles Sturt University, Australia <br />
- Prof. Emeritus Nizar Al-Holou; ECE Department; Vice Chair, IEEE/SEM-Computer Chapter; University of Detroit Mercy, Detroit, Michigan, USA <br />
- Prof. Emeritus Hamid R. Arabnia (Congress Steering Committee); School of Computing, The University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer); Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
- Prof. Emeritus Kevin Daimi (Congress Steering Committee); Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
- Prof. Leonidas Deligiannidis (Congress Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
- Prof. Mary Mehrnoosh Eshaghian-Wilner (Congress Steering Committee); Professor of Engineering Practice, University of Southern California, California, USA; Adjunct Professor, Electrical Engineering, University of California Los Angeles, Los Angeles (UCLA), California, USA <br />
- Prof. David Foster; Computer Engineering; Kettering University, Michigan, USA <br />
- Dr. Farid Gharehmohammadi (Co-Chair, Computer Vision & AI); Department of Radiology & Center for Augmented Intelligence (CAI),  Mayo Clinic, Florida, USA <br />
- Prof. Ray Hashemi (Vice-Chair); College of Engineering and Computing, Georgia Southern University, Georgia, USA <br />
- Prof. Byung-Gyu Kim; Multimedia Processing Communications Lab.(MPCL), Department of Computer Science and Engineering, College of Engineering, SunMoon University, South Korea <br />
- Prof. Tai-hoon Kim; School of Information and Computing Science, University of Tasmania, Australia <br />
- Prof. Dr. Guoming Lai; Computer Science and Technology, Sun Yat-Sen University, Guangzhou, P. R. China <br />
- Dr. Muhammad Naufal Bin Mansor; Faculty of Engineering Technology, Department of Electrical, Universiti Malaysia Perlis (UniMAP), Perlis, Malaysia <br />
- Dr. Andrew Marsh; CEO, HoIP Telecom Ltd (Healthcare over Internet Protocol), UK; Secretary General of World Academy of BioMedical Sciences and Technologies (WABT) a UNESCO NGO, The United Nations <br />
- Prof. Dr., Eng. Robert Ehimen Okonigene; Department of Electrical & Electronics Engineering, Faculty of Eng. and Technology, Ambrose Alli University, Edo State, Nigeria <br />
- Prof. James J. (Jong Hyuk) Park; Department of Computer Science and Engineering (DCSE), SeoulTech, Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Korea <br />
- Dr. Farzan Shenavarmasouleh (Co-Chair, Computer Vision & AI); R&D Engineer, MediaLab Inc, Georgia, USA <br />
-	Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
-	Prof. Fernando G. Tinetti (Congress Steering Committee); School of Computer Science, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
-	Prof. Quoc-Nam Tran (Congress Steering Committee); Department of Computer Science, Southeastern Louisiana University, Hammond, Louisiana, USA <br />
-	Prof. Shiuh-Jeng Wang (Congress Steering Committee); Director of Information Cryptology and Construction Laboratory (ICCL) and Director of Chinese Cryptology and Information Security Association (CCISA); Department of Information Management, Central Police University, Taoyuan, Taiwan; Guest Ed., IEEE Journal on Selected Areas in Communications. <br />
-	Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
-	Prof. Jane You; Associate Head, Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong <br />
-	Dr. Wen Zhang; Icahn School of Medicine at Mount Sinai, New York City, Manhattan, New York, USA; Board member, Journal of Bioinformatics and Genomics; Board member, Science Research Association <br />
            </div>

            <h6 className="par_header">Scientific Computing (CSC) 2024 - Program Committee </h6>
            <div className="par" style={{fontSize: "9px"}}>            
            -	Prof. Abbas M. Al-Bakry; University of IT and Communications, Baghdad, Iraq <br />
-	Prof. Emeritus Nizar Al-Holou; ECE Department; Vice Chair, IEEE/SEM-Computer Chapter; University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Emeritus Hamid R. Arabnia (Steering Committee); University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer); Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
-	Dr. Azita Bahrami (Co-Editor, EEE); President, IT Consult, USA <br />
-	Prof. Dr. Juan-Vicente Capella-Hernandez; Universitat Politecnica de Valencia (UPV), Department of Computer Engineering (DISCA), Valencia, Spain <br />
-	Prof. Emeritus Kevin Daimi (Steering Committee); Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Zhangisina Gulnur Davletzhanovna; Central-Asian University, Kazakhstan, Almaty; Vice President of International Academy of Informatization, Kazskhstan, Almaty, Republic of Kazakhstan <br />
-	Prof. Leonidas Deligiannidis (Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
-	Dr. Richard Dill (Co-Chair, Military and Defense Modeling); Computer Science and Engineering Department, US Air Force Institute of Technology (AFIT), USA <br />
-	Dr. Ryan D. Engle; US Air Force Institute of Technology (AFIT), USA <br />
-	Prof. George A. Gravvanis; Director, Physics Laboratory & Head of Advanced Scientific Computing, Applied Math & Applications Research Group; Professor of Applied Mathematics and Numerical Computing and Department of ECE, Democritus University of Thrace, Xanthi, Greece. <br />
-	Prof. Michael R. Grimaila (Co-Chair, Military and Defense Modeling); Head, Systems Engineering & Management Department, US Air Force Institute of Technology (AFIT), USA <br />
-	Prof. Houcine Hassan; Department of Computer Engineering (Systems Data Processing and Computers), Universitat Politecnica de Valencia, Spain <br />
-	Prof. Douglas D. Hodson (Co-Chair, Military and Defense Modeling); Computer Science and Engineering Department, US Air Force Institute of Technology (AFIT), USA <br />
-	Prof. George Jandieri; Georgian Technical University, Tbilisi, Georgia; Chief Scientist, The Institute of Cybernetics, Georgian Academy of Science, Georgia; Ed. Member, International Journal of Microwaves and Optical Technology, The Open Atmospheric Science Journal, American Journal of Remote Sensing, Georgia <br />
-	Prof. Dr. Abdeldjalil Khelassi; CS Department, Abou beker Belkaid University of Tlemcen, Algeria; Editor-in-Chief, Medical Tech. Journal; Assoc. Editor, Electronic Physician Journal (EPJ) - Pub Med Central <br />
-	Prof. Byung-Gyu Kim; Multimedia Processing Communications Lab.(MPCL), Department of CSE, College of Engineering, SunMoon University, South Korea <br />
-	Dr. Andrew Marsh; CEO, HoIP Telecom Ltd (Healthcare over Internet Protocol), UK; Secretary General of World Academy of BioMedical Sciences and Technologies (WABT) a UNESCO NGO, The United Nations <br />
-	Dr. Ali Mostafaeipour; Civil & Environmental Engineering Department, California State University, Fullerton, California, USA <br />
-	Dr. Houssem Eddine Nouri; Informatics Applied in Management, Institut Superieur de Gestion de Tunis, University of Tunis, Tunisia <br />
-	Prof. Dr., Eng. Robert Ehimen Okonigene; Department of Electrical & Electronics Engineering, Faculty of Engineering and Technology, Ambrose Alli University, Nigeria <br />
-	Prof. James J. (Jong Hyuk) Park; Department of Computer Science and Engineering (DCSE), SeoulTech; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Korea <br />
-	Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
-	Prof. Fernando G. Tinetti (Steering Committee); School of Computer Science, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
-	Prof. Hahanov Vladimir; Vice Rector, and Dean of the Computer Engineering Faculty, Kharkov National University of Radio Electronics, Ukraine and Professor of Design Automation Department, Computer Engineering Faculty, Kharkov; IEEE Computer Society Golden Core Member; National University of Radio Electronics, Ukraine <br />
-	Prof. Shiuh-Jeng Wang (Steering Committee); Director of Information Cryptology and Construction Laboratory (ICCL) and Director of Chinese Cryptology and Information Security Association (CCISA); Department of Information Management, Central Police University, Taoyuan, Taiwan; Guest Ed., IEEE Journal on Selected Areas in Communications. <br />
-	Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
-	Prof. Jane You; Associate Head, Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong <br />
-	Prof. Masami Takata (Co-Chair, Mathematical Modeling); Nara Women's University, Nara-shi, Nara, Japan <br />
-	Dr. Yunlong Wang; Advanced Analytics at QuintilesIMS, Pennsylvania, USA <br />
-	Dr. Heng (Fred) Wu; Mathematics and Computer Science, West Virginia State University, Institute, West Virginia, USA <br />
            </div>

            <h6 className="par_header">e-Learning, e-Business, Enterprise Information Systems, & e-Government (EEE) 2024 - Program Committee</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            -	Prof. Abbas M. Al-Bakry; University President, University of IT and Communications, Baghdad, Iraq <br />
-	Prof. Emeritus Nizar Al-Holou; ECE Department; Vice Chair, IEEE/SEM-Computer Chapter; University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Emeritus Hamid R. Arabnia (Congress Steering Committee); The University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer); Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
-	Dr. Azita Bahrami (Vice-Chair); President, IT Consult, USA <br />
-	Prof. Dr. Juan-Vicente Capella-Hernandez; Universitat Politecnica de Valencia (UPV), Department of Computer Engineering (DISCA), Valencia, Spain <br />
-	Prof. Emeritus Kevin Daimi (Congress Steering Committee); Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Zhangisina Gulnur Davletzhanovna; Vice-rector of the Science, Central-Asian University, Kazakhstan, Almaty, Republic of Kazakhstan; Vice President of International Academy of Informatization, Kazskhstan, Almaty, Republic of Kazakhstan <br />
-	Prof. Leonidas Deligiannidis (Congress Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
-	Prof. Mary Mehrnoosh Eshaghian-Wilner (Congress Steering Committee); Professor of Engineering Practice, University of Southern California, California, USA; Adjunct Professor, Electrical Engineering, University of California Los Angeles, Los Angeles (UCLA), California, USA <br />
-	Prof. George A. Gravvanis; Director, Physics Laboratory & Head of Advanced Scientific Computing, Applied Math & Applications Research Group; Professor of Applied Mathematics and Numerical Computing and Department of ECE, School of Engineering, Democritus University of Thrace, Xanthi, Greece. <br />
-	Prof. Houcine Hassan; Department of Computer Engineering (Systems Data Processing and Computers), Universitat Politecnica de Valencia, Spain <br />
-	Prof. George Jandieri; Georgian Technical University, Tbilisi, Georgia; Chief Scientist, The Institute of Cybernetics, Georgian Academy of Science, Georgia; Ed. Member, International Journal of Microwaves and Optical Technology, The Open Atmospheric Science Journal, American Journal of Remote Sensing, Georgia <br />
-	Prof. Dr. Abdeldjalil Khelassi; CS Department, Abou beker Belkaid University of Tlemcen, Algeria; Editor-in-Chief, Medical Tech. Journal; Assoc. Editor, Electronic Physician Journal (EPJ) - Pub Med Central <br />
-	Prof. Byung-Gyu Kim; Multimedia Processing Communications Lab.(MPCL), Department of CSE, College of Engineering, SunMoon University, South Korea <br />
-	Dr. Andrew Marsh; CEO, HoIP Telecom Ltd (Healthcare over Internet Protocol), UK; Secretary General of World Academy of BioMedical Sciences and Technologies (WABT) a UNESCO NGO, The United Nations <br />
-	Dr. Ali Mostafaeipour; Civil & Environmental Engineering Department, California State University, Fullerton, California, USA <br />
-	Dr. Houssem Eddine Nouri; Informatics Applied in Management, Institut Superieur de Gestion de Tunis, University of Tunis, Tunisia <br />
- Prof. Dr., Eng. Robert Ehimen Okonigene; Department of Electrical & Electronics Engineering, Faculty of Engineering and Tech., Ambrose Alli University, Edo State, Nigeria <br />
- Prof. James J. (Jong Hyuk) Park; Department of Computer Science and Engineering (DCSE), SeoulTech, Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Korea <br />
- Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
- Dr. Vivian Saltan, California State University, Los Angeles, California, USA <br />
- Prof. Fernando G. Tinetti (Congress Steering Committee); School of Computer Science, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
- Prof. Hahanov Vladimir; Vice Rector, and Dean of the Computer Engineering Faculty, Kharkov National University of Radio Electronics, Ukraine and Professor of Design Automation Department, Computer Engineering Faculty, Kharkov; IEEE Computer Society Golden Core Member; National University of Radio Electronics, Ukraine <br />
- Prof. Shiuh-Jeng Wang (Congress Steering Committee); Director of Information Cryptology and Construction Laboratory (ICCL) and Director of Chinese Cryptology and Information Security Association (CCISA); Department of Information Management, Central Police University, Taoyuan, Taiwan; Guest Ed., IEEE Journal on Selected Areas in Communications. <br />
- Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
            </div>

            <h6 className="par_header">Embedded Systems, Cyber-physical Systems, & Applications (ESCS) 2024 - Program Committee</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            -	Prof. Emeritus Nizar Al-Holou; Professor and Chair, ECE Department; Vice Chair, IEEE/SEM-Computer Chapter; University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Emeritus Hamid R. Arabnia (Congress Steering Committee); The University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer); Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
-	Dr. P. Balasubramanian; School of CSE,  Nanyang Technological University, Singapore <br />
-	Dr. Afsaneh Banitalebi Dehkordi; Faculty of Computer Engineering, PNU University, Shahrekord, Iran <br />
-	Prof. Dr. Juan-Vicente Capella-Hernandez; Universitat Politecnica de Valencia (UPV), Department of Computer Engineering (DISCA), Valencia, Spain <br />
-	Prof. Emeritus Kevin Daimi (Congress Steering Committee); Director, Computer Science and Software Engineering Programs, Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Leonidas Deligiannidis (Congress Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
-	Prof. Mary Mehrnoosh Eshaghian-Wilner (Congress Steering Committee); Professor of Engineering Practice, University of Southern California, California, USA; Adjunct Professor, Electrical Engineering, University of California Los Angeles, Los Angeles (UCLA), California, USA <br />
-	Prof. Houcine Hassan; Department of Computer Engineering (Systems Data Processing and Computers), Universitat Politecnica de Valencia, Spain <br />
-	Prof. Dr. Guoming Lai; Computer Science and Technology, Sun Yat-Sen University, Guangzhou, P. R. China <br />
-	Dr. Andrew Marsh (Congress Steering Committee); CEO, HoIP Telecom Ltd (Healthcare over Internet Protocol), UK; Secretary General of World Academy of BioMedical Sciences and Technologies (WABT) a UNESCO NGO, The United Nations <br />
-	Dr. Ali Mostafaeipour; Civil & Environmental Engineering Department, California State University, Fullerton, California, USA <br />
-	Prof. Dr., Eng. Robert Ehimen Okonigene; Department of Electrical & Electronics Engineering, Faculty of Engineering and Tech., Ambrose Alli University, Edo State, Nigeria <br />
-	Dr. Benaoumeur Senouci; Embedded Systems Department, LACSC Laboratory- Central Electronic Engineering School, ECE, Paris, France <br />
-	Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
-	Prof. Fernando G. Tinetti (Congress Steering Committee); School of CS, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
-	Prof. Hahanov Vladimir; Vice Rector, and Dean of the Computer Engineering Faculty, Kharkov National University of Radio Electronics, Ukraine and Professor of Design Automation Department, Computer Engineering Faculty, Kharkov; IEEE Computer Society Golden Core Member; National University of Radio Electronics, Ukraine <br />
-	Prof. Shiuh-Jeng Wang (Congress Steering Committee); Director of Information Cryptology and Construction Laboratory (ICCL) and Director of Chinese Cryptology and Information Security Association (CCISA); Department of Information Management, Central Police University, Taoyuan, Taiwan; Guest Ed., IEEE Journal on Selected Areas in Communications. <br />
-	Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
-	Prof. Hyun Yoe; Director of Agrofood IT Research Center and Vice President of Korea Association of ICT Convergence in the Agriculture and Food Business (KAICAF); Director of Agriculture IT Convergence Support Center (AITCSC); Department of of Information and Communication Engineering, Sunchon National University, Suncheon, Republic of Korea (South Korea) <br />
-	Prof. Jane You; Associate Head, Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong <br />
            </div>

            <h6 className="par_header">Foundations of Computer Science (FCS) 2024 - Program Committee</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            -	Prof. Emeritus Hamid R. Arabnia (Congress Steering Committee); The University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer);Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
-	Prof. Juan Jose Martinez Castillo; Director, The Acantelys Alan Turing Nikola Tesla Research Group and GIPEB, Universidad Nacional Abierta, Venezuela <br />
-	Prof. Emeritus Kevin Daimi (Congress Steering Committee); Department of Mathematics, Computer Science & Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Zhangisina Gulnur Davletzhanovna; Vice-rector of the Science, Central-Asian University, Kazakhstan, Almaty, Republic of Kazakhstan; Vice President of International Academy of Informatization, Kazskhstan, Almaty, Republic of Kazakhstan <br />
-	Prof. Leonidas Deligiannidis (Congress Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
-	Prof. Tai-hoon Kim; School of Information and Computing Science, University of Tasmania, Australia <br />
-	Prof. Dr. Guoming Lai; Computer Science and Technology, Sun Yat-Sen University, Guangzhou, P. R. China <br />
-	Dr. Vitus S. W. Lam; Senior IT Manager, Information Technology Services, The University of Hong Kong, Kennedy Town, Hong Kong; Chartered Member of The British Computer Society, UK; Former Vice Chairman of the British Computer Society (Hong Kong Section); Chartered Engineer & Fellow of the Institution of Analysts and Programmers <br />
-	Prof. Dr., Eng. Robert Ehimen Okonigene; Department of Electrical & Electronics Engineering, Faculty of Engineering and Tech., Ambrose Alli University, Edo State, Nigeria <br />
-	Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
-	Dr. Tse Guan Tan; Faculty of Creative Technology and Heritage, Universiti Malaysia Kelantan, Kelantan, Malaysia <br />
-	Prof. Fernando G. Tinetti (Congress Steering Committee); School of Computer Science, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
-	Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
-	Dr. Heng (Fred) Wu; Mathematics and Computer Science, West Virginia State University, Institute, West Virginia, USA <br />
-	Prof. Jane You; Associate Head, Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong
            </div>

            <h6 className="par_header">Frontiers in Education: Computer science & Computer Engineering (FECS) 2024 - Program Committee</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            - Prof. Afrand Agah; Department of Computer Science, West Chester University of Pennsylvania, West Chester, PA, USA <br />
- Prof. Abbas M. Al-Bakry; University President, University of IT and Communications, Baghdad, Iraq <br />
- Prof. Emeritus Nizar Al-Holou; Vice Chair, IEEE/SEM-Computer Chapter; University of Detroit Mercy, Detroit, Michigan, USA <br />
- Dr. Soheyla Amirian (Co-Chair, Computer Vision & AI); Faculty, School of Computing, University of Georgia, USA <br />
- Prof. Emeritus Hamid R. Arabnia (Congress Steering Committee); The University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer); Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
- Prof. Mehran Asadi; Department of Business and Entrepreneurial Studies, The Lincoln University, Pennsylvania, USA <br />
- Dr. Azita Bahrami (Vice-Chair); President, IT Consult, USA <br />
- Prof. Dr. Juan-Vicente Capella-Hernandez; Universitat Politecnica de Valencia (UPV), Department of Computer Engineering (DISCA), Valencia, Spain <br />
- Prof. Juan Jose Martinez Castillo; Director, The Acantelys Alan Turing Nikola Tesla Research Group and GIPEB, Universidad Nacional Abierta, Venezuela <br />
- Prof. Emeritus Kevin Daimi (Congress Steering Committee); Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
- Prof. Zhangisina Gulnur Davletzhanovna; Vice-rector of the Science, Central-Asian University, Kazakhstan, Almaty, Republic of Kazakhstan; Vice President of International Academy of Informatization, Kazskhstan, Almaty, Republic of Kazakhstan <br />
- Prof. Leonidas Deligiannidis (Congress Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
- Prof. Mary Mehrnoosh Eshaghian-Wilner (Congress Steering Committee); Professor of Engineering Practice, University of Southern California, California, USA; Adjunct Professor, Electrical Engineering, University of California Los Angeles, Los Angeles (UCLA), California, USA <br />
- Prof. David Foster; Computer Engineering; Kettering University, Michigan, USA <br />
- Dr. Farid Gharehmohammadi (Co-Chair, Computer Vision & AI); Department of Radiology & Center for Augmented Intelligence (CAI),  Mayo Clinic, Florida, USA <br />
- Prof. George A. Gravvanis; Director, Physics Laboratory & Head of Advanced Scientific Computing, Applied Math & Applications Research Group; Professor of Applied Mathematics and Numerical Computing and Department of ECE, School of Engineering, Democritus University of Thrace, Xanthi, Greece <br />
- Prof. Michael R. Grimaila; US Air Force Institute of Technology (AFIT), USA <br />
- Prof. Ray Hashemi (Vice-Chair); College of Engineering and Computing, Georgia Southern University, Georgia, USA <br />
- Prof. Houcine Hassan; Department of Computer Engineering (Systems Data Processing and Computers), Universitat Politecnica de Valencia, Spain <br />
- Prof. Douglas D. Hodson; US Air Force Institute of Technology (AFIT), USA <br />
- Prof. George Jandieri; Georgian Technical University, Tbilisi, Georgia; Chief Scientist, The Institute of Cybernetics, Georgian Academy of Science, Georgia; Ed. Member, International Journal of Microwaves and Optical Technology, The Open Atmospheric Science Journal, American Journal of Remote Sensing, Georgia <br />
- Prof. Byung-Gyu Kim; Multimedia Processing Communications Lab.(MPCL), Department of Computer Science and Engineering, College of Engineering, SunMoon University, South Korea <br />
- Prof. Tai-hoon Kim; School of Information and Computing Science, University of Tasmania, Australia <br />
- Prof. Dr. Guoming Lai; Computer Science and Technology, Sun Yat-Sen University, Guangzhou, P. R. China <br />
- Dr. Andrew Marsh; CEO, HoIP Telecom Ltd (Healthcare over Internet Protocol), UK; Secretary General of World Academy of BioMedical Sciences and Technologies (WABT) a UNESCO NGO, The United Nations <br />
- Prof. Dr., Eng. Robert Ehimen Okonigene; Department of Electrical & Electronics Engineering, Faculty of Engineering and Technology, Ambrose Alli University, Nigeria <br />
- Prof. James J. (Jong Hyuk) Park; Department of Computer Science and Engineering (DCSE), SeoulTech, Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Korea <br />
- Dr. Farzan Shenavarmasouleh (Co-Chair, Computer Vision & AI); R&D Engineer, MediaLab Inc, Georgia, USA <br />
- Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
- Prof. Dr. Ir. Sim Kok Swee; Fellow, IEM; Senior Member, IEEE; Faculty of Engineering and Technology, Multimedia University, Melaka, Malaysia <br />
- Prof. Fernando G. Tinetti (Congress Steering Committee); School of Computer Science, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
- Prof. Quoc-Nam Tran (Congress Steering Committee); Department of Computer Science, Southeastern Louisiana University, Louisiana, USA <br />
- Prof. Hahanov Vladimir; Vice Rector, and Dean of the Computer Engineering Faculty, Kharkov National University of Radio Electronics, Ukraine and Professor of Design Automation Department, Computer Engineering Faculty, Kharkov; IEEE Computer Society Golden Core Member; National University of Radio Electronics, Ukraine <br />
- Prof. Shiuh-Jeng Wang (Congress Steering Committee); Director of Information Cryptology and Construction Laboratory (ICCL) and Director of Chinese Cryptology and Information Security Association (CCISA); Department of Information Management, Central Police University, Taoyuan, Taiwan; Guest Ed., IEEE Journal on Selected Areas in Communications <br />
- Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
- Dr. Heng (Fred) Wu; Mathematics and Computer Science, West Virginia State University, Institute, West Virginia, USA <br />
- Prof. Jane You; Associate Head, Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong <br />
            </div>

            <h6 className="par_header">Grid, Cloud, & Cluster Computing (GCC) 2024 - Program Committee</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            -	Prof. Emeritus Nizar Al-Holou; ECE Department; Vice Chair, IEEE/SEM-Computer Chapter; University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Hamid R. Arabnia (Steering Committee); University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer); Editor-in-Chief, Transactions of Computational Science & Computational Intelligence (Springer); Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
-	Prof. Dr. Juan-Vicente Capella-Hernandez; Universitat Politecnica de Valencia (UPV), Department of Computer Engineering (DISCA), Valencia, Spain <br />
-	Prof. Emeritus Kevin Daimi (Steering Committee); Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Leonidas Deligiannidis (Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
-	Prof. Mary Mehrnoosh Eshaghian-Wilner (Steering Committee); Professor of Engineering Practice, University of Southern California, California, USA; Adjunct Professor, Electrical Engineering, University of California Los Angeles, Los Angeles (UCLA), California, USA <br />
-	Prof. Hyo Jong Lee; Director, Center for Advanced Image and Information Technology, Division of Computer Science and Engineering, Chonbuk National University, South Korea <br />
-	Dr. Houssem Eddine Nouri; Informatics Applied in Management, Institut Superieur de Gestion de Tunis, University of Tunis, Tunisia <br />
-	Prof. Dr., Eng. Robert Ehimen Okonigene; Department of Electrical & Electronics Engineering, Faculty of Engineering and Technology, Ambrose Alli University, Edo State, Nigeria <br />
-	Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
-	Prof. Fernando G. Tinetti (Steering Committee); School of Computer Science, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
-	Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
-	Prof. Jane You; Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong <br />
-	Dr. Farhana H. Zulkernine; Coordinator of the Cognitive Science Program, School of Computing, Queen's University, Kingston, ON, Canada <br />
            </div>

            <h6 className="par_header">Health Informatics & Medical Systems (HIMS) 2024 - Program Committee</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            In addition to the list below, please check additional committee members posted at: <br />
            <a href="https://www.himscsce.org/hims24/organization_com.html " target="_blank" rel="noopener noreferrer">https://www.himscsce.org/hims24/organization_com.html</a> <br />
            <a href="https://www.himscsce.org/hims24/program_com.html " target="_blank" rel="noopener noreferrer">https://www.himscsce.org/hims24/program_com.html</a> <br /> <br />
            - Prof. Abeer Alsadoon (Co-Chair, Health Informatics); Charles Sturt University, Australia <br />
- Prof. Abbas M. Al-Bakry; University President, University of IT and Communications, Baghdad, Iraq <br />
- Prof. Emeritus Nizar Al-Holou; Electrical & Computer Engineering Department; Vice Chair, IEEE/SEM-Computer Chapter; University of Detroit Mercy, Michigan, USA <br />
- Dr. Soheyla Amirian (Co-Chair, Computer Vision & AI); Faculty, School of Computing, University of Georgia, USA <br />
- Prof. Emeritus Hamid R. Arabnia (Congress Steering Committee); School of Computing, The University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer); Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
- Prof. Dr. Juan-Vicente Capella-Hernandez; Universitat Politecnica de Valencia (UPV), Department of Computer Engineering (DISCA), Valencia, Spain <br />
- Prof. Juan Jose Martinez Castillo; Director, The Acantelys Alan Turing Nikola Tesla Research Group and GIPEB, Universidad Nacional Abierta, Venezuela <br />
- Prof. Emeritus Kevin Daimi (Congress Steering Committee); Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
- Prof. Zhangisina Gulnur Davletzhanovna; Vice-rector of the Science, Central-Asian University, Kazakhstan, Almaty, Republic of Kazakhstan; Vice President of International Academy of Informatization, Kazskhstan, Almaty, Republic of Kazakhstan <br />
- Prof. Leonidas Deligiannidis (Congress Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
- Prof. Mary Mehrnoosh Eshaghian-Wilner (Congress Steering Committee); Professor of Engineering Practice, University of Southern California, California, USA; Adjunct Professor, Electrical Engineering, University of California Los Angeles, Los Angeles (UCLA), California, USA <br />
- Dr. Farid Gharehmohammadi (Co-Chair, Computer Vision & AI); Department of Radiology & Center for Augmented Intelligence (CAI),  Mayo Clinic, Florida, USA <br />
- Hindenburgo Elvas Goncalves de Sa; Robertshaw Controls (Multi-National Company), System Analyst, Brazil; Information Technology Coordinator and Manager, Brazil <br />
- Prof. Ray Hashemi (Vice-Chair); College of Engineering and Computing, Georgia Southern University, Georgia, USA <br />
- Prof. Byung-Gyu Kim; Multimedia Processing Communications Lab.(MPCL), Department of Computer Science and Engineering, College of Engineering, SunMoon University, South Korea <br />
- Prof. Tai-hoon Kim; School of Information and Computing Science, University of Tasmania, Australia <br />
- Prof. Dr. Guoming Lai; Computer Science and Technology, Sun Yat-Sen University, Guangzhou, P. R. China <br />
- Prof. Hyo Jong Lee; Director, Center for Advanced Image and Information Technology, Division of Computer Science and Engineering, Chonbuk National University, South Korea <br />
- Dr. Muhammad Naufal Bin Mansor; Faculty of Engineering Technology, Department of Electrical, Universiti Malaysia Perlis (UniMAP), Perlis, Malaysia <br />
- Dr. Andrew Marsh; CEO, HoIP Telecom Ltd (Healthcare over Internet Protocol), UK; Secretary General of World Academy of BioMedical Sciences and Technologies (WABT) a UNESCO NGO, The United Nations <br />
- Michael B. O'Hara; CEO, KB Computing, LLC, USA; Certified Information System Security Professional (CISSP); Certified Cybersecurity Architect (CCSA); Certified HIPAA Professional (CHP); Certified Security Compliance Specialist (CSCS) <br />
- Prof. Dr., Eng. Robert Ehimen Okonigene; Department of Electrical & Electronics Engineering, Faculty of Engineering and Technology, Ambrose Alli University, Nigeria <br />
- Prof. James J. (Jong Hyuk) Park; Department of Computer Science and Engineering (DCSE), SeoulTech, Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Korea <br />
- Dr. Farzan Shenavarmasouleh (Co-Chair, Computer Vision & AI); R&D Engineer, MediaLab Inc, Georgia, USA <br />
- Prof. Hayaru Shouno; Chair, Technical Committee of Neuro-Computing (NC), Institute of Electronics, Information & Communication Engineers (IEICE), Japan and University of Electro-Communications, Japan <br />
- Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
- Prof. Dr. Ir. Sim Kok Swee; Fellow, IEM; Senior Member, IEEE; Faculty of Engineering and Technology, Multimedia University, Melaka, Malaysia <br />
- Prof. Fernando G. Tinetti (Congress Steering Committee); School of Computer Science, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
- Prof. Quoc-Nam Tran (Congress Steering Committee); Department of Computer Science, Southeastern Louisiana University, Hammond, Louisiana, USA <br />
- Prof. Hahanov Vladimir; Vice Rector, and Dean of the Computer Engineering Faculty, Kharkov National University of Radio Electronics, Ukraine and Professor of Design Automation Department, Computer Engineering Faculty, Kharkov; IEEE Computer Society Golden Core Member; National University of Radio Electronics, Ukraine <br />
- Prof. Shiuh-Jeng Wang (Congress Steering Committee); Director of Information Cryptology and Construction Laboratory (ICCL) and Director of Chinese Cryptology and Information Security Association (CCISA); Department of Information Management, Central Police University, Taoyuan, Taiwan; Guest Ed., IEEE Journal on Selected Areas in Communications. <br />
- Dr. Yunlong Wang; Advanced Analytics at QuintilesIMS, Pennsylvania, USA <br />
- Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
- Prof. Jane You; Associate Head, Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong <br />
- Dr. Farhana H. Zulkernine; Coordinator of the Cognitive Science Program, School of Computing, Queen's University, Kingston, ON, Canada

            </div>

            <h6 className="par_header">Artificial Intelligence (ICAI) 2024 - Program Committee </h6>
            <div className="par" style={{fontSize: "9px"}}>            
            - Prof. Abbas M. Al-Bakry; University of IT and Communications, Baghdad, Iraq <br />
- Prof. Emeritus Nizar Al-Holou; Electrical and Computer Engineering Department; Vice Chair, IEEE/SEM-Computer Chapter; University of Detroit Mercy, Detroit, Michigan, USA <br />
- Dr. Soheyla Amirian (Co-Chair, Computer Vision & AI); Faculty, School of Computing, University of Georgia, USA <br />
- Prof. Emeritus Hamid R. Arabnia (Steering Committee); University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer); Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
- Prof. Mehran Asadi; Department of Business and Entrepreneurial Studies, The Lincoln University, Pennsylvania, USA <br />
- Alireza Bagheri Rajeoni; College of Engineering and Computer Science, University of South Carolina, Columbia, South Carolina, USA <br />
- Prof. Juan Jose Martinez Castillo; Director, The Acantelys Alan Turing Nikola Tesla Research Group and GIPEB, Universidad Nacional Abierta, Venezuela <br />
- Dr. Arianna D'Ulizia; Institute of Research on Population and Social Policies, National Research Council of Italy (IRPPS), Rome, Italy <br />
- Prof. Emeritus Kevin Daimi (Steering Committee); Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
- Prof. Zhangisina Gulnur Davletzhanovna; Vice-Rector of the Science, Central-Asian University, Kazakhstan, Almaty, Republic of Kazakhstan; Vice President of International Academy of Informatization, Kazskhstan, Almaty, Republic of Kazakhstan <br />
- Prof. Leonidas Deligiannidis (Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
- Dr. Roger Dziegiel; US Air Force Research Lab, AFRL/RIEA, USA <br />
- Prof. Mary Mehrnoosh Eshaghian-Wilner (Steering Committee); Professor of Engineering Practice, University of Southern California, California, USA; Adjunct Professor, Electrical Engineering, University of California Los Angeles, Los Angeles (UCLA), California, USA <br />
- Prof. Ken Ferens (Steering Committee); Department of Electrical and Computer Engineering, University of Manitoba, Winnipeg, Canada <br />
- Dr. David de la Fuente (Chapter Editor); University of Oviedo, Spain <br />
- Hindenburgo Elvas Goncalves de Sa; Robertshaw Controls (Multi-National Company), System Analyst, Brazil; Information Technology Coordinator and Manager, Brazil <br />
- Dr. Farid Gharehmohammadi (Co-Chair, Computer Vision & AI); Department of Radiology & Center for Augmented Intelligence (CAI),  Mayo Clinic, Florida, USA <br />
- Prof. George A. Gravvanis; Director, Physics Laboratory & Head of Advanced Scientific Computing, Applied Math & Applications Research Group; Professor of Applied Mathematics and Numerical Computing and Department of ECE, School of Engineering, Democritus University of Thrace, Xanthi, Greece <br />
- Prof. Michael R. Grimaila; US Air Force Institute of Technology (AFIT), USA <br />
- Prof. Douglas D. Hodson; US Air Force Institute of Technology (AFIT), USA <br />
- Prof. George Jandieri; Georgian Technical University, Tbilisi, Georgia; Chief Scientist, The Institute of Cybernetics, Georgian Academy of Science, Georgia; Ed. Member, International Journal of Microwaves and Optical Technology, The Open Atmospheric Science Journal, American Journal of Remote Sensing, Georgia <br />
- Prof. Byung-Gyu Kim; Multimedia Processing Communications Lab.(MPCL), Department of CSE, College of Engineering, SunMoon University, South Korea <br />
- Prof. Tai-hoon Kim; School of Information and Computing Science, University of Tasmania, Australia <br />
- Dr. Elena B. Kozerenko; Institute of Informatics Problems of the Russian Academy of Sciences, Moscow, Russia <br />
- Prof. Dr. Guoming Lai; Computer Science and Technology, Sun Yat-Sen University, Guangzhou, P. R. China <br />
- Dr. Peter M. LaMonica; US Air Force Research Lab, AFRL/RIEBB, USA <br />
- Prof. Hyo Jong Lee; Director, Center for Advanced Image and Information Technology, Division of Computer Science and Engineering, Chonbuk National University, South Korea <br />
- Dr. Changyu Liu; College of Mathematics and Informatics, South China Agricultural University, Guangzhou, P. R. China and Visiting scientist, School of Computer Science, Carnegie Mellon University, USA <br />
- Dr. Muhammad Naufal Bin Mansor; Faculty of Engineering Technology, Department of Electrical, Universiti Malaysia Perlis (UniMAP), Perlis, Malaysia <br />
- Dr. Andrew Marsh; CEO, HoIP Telecom Ltd (Healthcare over Internet Protocol), UK; Secretary General of World Academy of BioMedical Sciences and Technologies (WABT) a UNESCO NGO, The United Nations <br />
- Dr. Mohamed Arezki Mellal; Faculty of Engineering Sciences (FSI), M'Hamed Bougara University, Boumerdes, Algeria <br />
- Dr. Ali Mostafaeipour; Civil & Environmental Engineering Department, California State University, Fullerton, California, USA <br />
- Dr. Houssem Eddine Nouri; Informatics Applied in Management, Institut Superieur de Gestion de Tunis, University of Tunis, Tunisia <br />
- Prof. Dr., Eng. Robert Ehimen Okonigene; Department of EEE, Faculty of Engineering and Technology, Ambrose Alli University, Edo State, Nigeria <br />
- Dr. Jose A. Olivas (Chapter Editor); University of Castilla - La Mancha, Spain <br />
- Dr. Marwan Omar - Information Technology and Management, Illinois Institute of Technology, Chicago, Illinois, USA <br />
- Dr. Javier Ordus (Co-Chair, Quantum Computing & AI); Adjunct Faculty, School of Engineering & Computer Science, Baylor University, Waco, Texas, USA <br />
- Prof. James J. (Jong Hyuk) Park; DCSE, SeoulTech, Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Korea <br />
- Dr. Xuewei Qi; Research Faculty & PI, Center for Environmental Research and Technology, University of California, Riverside, California, USA <br />
- Dr. Charlie (Seungmin) Rho (Chapter Editor); Department Software, Sejong University, Gwangjin-gu, Seoul, Republic of Korea <br />
- Dr. Pablo Rivas (Co-Chair, Quantum Computing & AI); School of Engineering & Computer Science, Baylor University, Waco, Texas, USA <br />
- Prof. Abdel-Badeeh M. Salem; Head of Artificial Intelligence and Knowledge Engineering Research Labs and Professor of Computer Science, Faculty of Computer and Information Sciences, Ain Shams University, Cairo, Egypt; Editor-In-Chief, Egyptian Computer Science Journal; Editor-In-Chief, International Journal of Bio-Medical Informatics and e-Health (IJBMIeH); Associate-Editor-In-Chief, International Journal of Applications of Fuzzy Sets and Artificial Intelligence ( IJAFSAI) <br />
- Dr. Farzan Shenavarmasouleh (Co-Chair, Computer Vision & AI); R&D Engineer, MediaLab Inc, Georgia, USA <br />
- Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
- Dr. Tse Guan Tan; Faculty of Creative Technology and Heritage, Universiti Malaysia Kelantan, Malaysia <br />
- Prof. Fernando G. Tinetti (Steering Committee); School of Computer Science, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
- Prof. Hahanov Vladimir; Vice Rector, and Dean of the Computer Engineering Faculty, Kharkov National University of Radio Electronics, Ukraine and Professor of Design Automation Department, Computer Engineering Faculty, Kharkov; IEEE Computer Society Golden Core Member; National University of Radio Electronics, Ukraine <br />
- Prof. Shiuh-Jeng Wang (Steering Committee); Director of Information Cryptology and Construction Laboratory (ICCL) and Director of Chinese Cryptology and Information Security Association (CCISA); Department of Information Management, Central Police University, Taoyuan, Taiwan; Guest Ed., IEEE Journal on Selected Areas in Communications. <br />
- Dr. Todd Waskiewicz; US Air Force Research Lab, AFRL/RIED, USA <br />
- Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
- Dr. Xiaokun Yang; College of Science and Engineering, University of Houston Clear Lake, Houston, Texas, USA <br />
- Prof. Jane You; Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong
            </div>

            <h6 className="par_header">Data Science (ICDATA) 2024 - Program Committee</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            In addition to the list below, please check additional committee members posted at: <br />
            <a href="https://icdatascience.org/committees/ " target="_blank" rel="noopener noreferrer">https://icdatascience.org/committees/ </a> <br /> <br />
            -	Prof. Afrand Agah; Department of Computer Science, West Chester University of Pennsylvania, West Chester, Pennsylvania, USA <br />
- Dr. Mehmet Fatih Akay, Department of Computer Engineering, Cukurova University, Turkey <br />
- Prof. Abbas M. Al-Bakry (Congress Steering Committee); University President, University of IT and Communications, Baghdad, Iraq <br />
- Prof. Emeritus Nizar Al-Holou; ECE Department; Vice Chair, IEEE/SEM-Computer Chapter; University of Detroit Mercy, Detroit, Michigan, USA <br />
- Dr. Hamid Ali Abed Alasadi; Head, Department of Computer Science, Basra University, Iraq; Member of Optical Society of America (OSA), USA; Member of The International Society for Optical Engineering (SPIE), Bellingham, Washington, USA <br />
- Dr. Soheyla Amirian (Co-Chair, Computer Vision & AI); Faculty, School of Computing, University of Georgia, USA <br />
- Prof. Emeritus Hamid R. Arabnia (Congress Steering Committee); School of Computing, The University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer) <br />
- Prof. Mellal Mohamed Arezki; Faculty of Engineering Sciences (FSI), M'Hamed Bougara University, Boumerdes, Algeria <br />
- Prof. Mehran Asadi; Department of Business and Entrepreneurial Studies, The Lincoln University, Pennsylvania, USA <br />
- Prof. Hans-Peter Bischof; Department of Computer Science, Rochester Institute of Technology, New York, USA <br />
- Prof. Juan Jose Martinez Castillo; Director, The Acantelys Alan Turing Nikola Tesla Research Group and GIPEB, Universidad Nacional Abierta, Venezuela <br />
- Prof. Emeritus Kevin Daimi (Congress Steering Committee); Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
- Prof. Mary Mehrnoosh Eshaghian-Wilner (Congress Steering Committee); Professor of Engineering Practice, University of Southern California, California, USA; Adjunct Professor, Electrical Engineering, University of California Los Angeles, Los Angeles (UCLA), California, USA <br />
- Dr. Farid Gharehmohammadi (Co-Chair, Computer Vision & AI); Department of Radiology & Center for Augmented Intelligence (CAI),  Mayo Clinic, Florida, USA <br />
- Prof. George A. Gravvanis; Director, Physics Laboratory & Head of Advanced Scientific Computing, Applied Math & Applications Research Group; Professor of Applied Mathematics and Numerical Computing and Department of ECE, School of Engineering, Democritus University of Thrace, Xanthi, Greece; former President of the Technical Commission on Data Processing, Social Security for the Migrant Workers, European Commission, Hellenic Presidency, Greece <br />
- Prof. Mohammad Shahadat Hossain (PhD, UMIST, Manchester), MBCS; Department of Computer Science and Engineering, University of Chittagong, Bangladesh <br />
- Prof. George Jandieri; Georgian Technical University, Tbilisi, Georgia; Chief Scientist, The Institute of Cybernetics, Georgian Academy of Science, Georgia; Editorial Board Member: International Journal of Microwaves and Optical Technology, The Open Atmospheric Science Journal, American Journal of Remote Sensing <br />
- Prof. Byung-Gyu Kim; Multimedia Processing Communications Lab.(MPCL), Department of Computer Science and Engineering, College of Engineering, SunMoon University, South Korea <br />
- Dr. Bo Liu; Spatio-temporal Data Analysis Research Department, NEC Labs China, Beijing, P. R. China (formerly at Argonne National Laboratory, USA) <br />
- Dr. Yan Luo; National Institutes of Health,  Bethesda, Maryland, USA <br />
- Andrew Marsh; CEO, HoIP Telecom Ltd (Healthcare over Internet Protocol), UK; Secretary General of World Academy of BioMedical Sciences and Technologies (WABT) a UNESCO NGO, The United Nations <br />
- Prof. Francesc D. Munoz-Escoi; E.T.S. Informatica (ETSInf), Departamento de Sistemas Informaticos y Computacion (DSIC), Instituto Universitario Mixto Tecnologico de Informatica (ITI), Universitat Politecnica de Valencia (Polytechnical University of Valencia), Valencia, Spain <br />
- Prof. James J. (Jong Hyuk) Park; Department of Computer Science and Engineering (DCSE), SeoulTech, Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Korea <br />
- Dr. Alvaro Rubio-Largo; University of Extremadura, Caceres, Spain <br />
- Prof. Khemaissia Seddik; University of Tebessa, Algerie, Algeria <br />
- Ashu M. G. Solo, (Publicity Chair), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
- Dr. Farzan Shenavarmasouleh (Co-Chair, Computer Vision & AI); R&D Engineer, MediaLab Inc, Georgia, USA <br />
- Dr. Robert Stahlbock (Chair, Data Mining); Universitat Hamburg, Hamburg, Germany <br />
- Prof. Fernando G. Tinetti (Congress Steering Committee); School of Computer Science, Universidad Nacional de La Plata, La Plata, Argentina; Co-editor, Journal of Computer Science & Technology (JCS&T) <br />
- Prof. Shiuh-Jeng Wang; Department of Information Management, Central Police University, Taiwan; Program Chair, Security & Forensics, Taiwan; Director, Information Crypto and Construction Lab (ICCL) & ICCL-FROG <br />
- Prof. Dr. Bernd E. Wolfinger; Telecommunications and Computer Networks Division, Computer Science Department, University of Hamburg, Hamburg (Stellingen), Germany <br />
- Prof. Mary Q. Yang; Director, Mid-South Bioinformatics Center and Joint Bioinformatics Ph.D. Program, Medical Sciences and George W. Donaghey College of Engineering and Information Technology, University of Arkansas, USA <br />
- Prof. Jane You; Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong <br />
- Dr. Fang Zheng; IBM T.J. Watson Research Center, Yorktown Heights, NY, USA
            </div>

            <h6 className="par_header">Emergent Quantum Technologies (Includes Machine Learning) (ICEQT) 2024 - Program Committee </h6>
            <div className="par" style={{fontSize: "9px"}}> 
            Please check the list of committee members posted at: <br />
            <a href="https://baylor.ai/iceqt/ " target="_blank" rel="noopener noreferrer">https://baylor.ai/iceqt/ </a>
            <br /> <br />
            -	Dr. Javier Ordus (Co-Chair, Quantum Computing & AI) - Adjunct Faculty, School of Engineering & Computer Science, Baylor University, Waco, Texas, USA <br />
-	Dr. Pablo Rivas (Co-Chair, Quantum Computing & AI) - School of Engineering & Computer Science, Baylor University, Waco, Texas, USA <br />
<br />
Please also refer to ICAI 2024 list of Program Committees.
            </div>


            <h6 className="par_header">Internet Computing & IoT (ICOMP) 2024 - Program Committee </h6>
            <div className="par" style={{fontSize: "9px"}}>            
            -	Prof. Afrand Agah; Department of Computer Science, West Chester University of Pennsylvania, West Chester, PA, USA <br />
-	Prof. Emeritus Nizar Al-Holou; Electrical and Computer Engineering Department; Vice Chair, IEEE/SEM-Computer Chapter; University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Abeer Alsadoon (Co-Chair, Health Informatics); Charles Sturt University, Australia <br />
-	Prof. Emeritus Hamid R. Arabnia (Congress Steering Committee); The University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer); Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
-	Dr. Soheyla Amirian (Co-Chair, Computer Vision & AI); Faculty, School of Computing, University of Georgia, USA <br />
-	Dr. Afsaneh Banitalebi Dehkordi; Faculty of Computer Engineering, PNU University, Shahrekord, Iran <br />
-	Prof. Dr. Juan-Vicente Capella-Hernandez; Universitat Politecnica de Valencia (UPV), Department of Computer Engineering (DISCA), Valencia, Spain <br />
-	Prof. Emeritus Kevin Daimi (Congress Steering Committee); Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Zhangisina Gulnur Davletzhanovna; Vice-rector of the Science, Central-Asian University, Kazakhstan, Almaty, Republic of Kazakhstan; Vice President of International Academy of Informatization, Kazskhstan, Almaty, Republic of Kazakhstan <br />
-	Prof. Leonidas Deligiannidis (Congress Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
-	Prof. Mary Mehrnoosh Eshaghian-Wilner (Congress Steering Committee); Professor of Engineering Practice, University of Southern California, California, USA; Adjunct Professor, Electrical Engineering, University of California Los Angeles, Los Angeles (UCLA), California, USA <br />
-	Prof. David Foster; Computer Engineering; Kettering University, Michigan, USA <br />
-	Dr. Farid Gharehmohammadi (Co-Chair, Computer Vision & AI); Department of Radiology & Center for Augmented Intelligence (CAI),  Mayo Clinic, Florida, USA <br />
-	Prof. Houcine Hassan; Department of Computer Engineering (Systems Data Processing and Computers), Universitat Politecnica de Valencia, Spain <br />
-	Prof. Tai-hoon Kim; School of Information and Computing Science, University of Tasmania, Australia <br />
-	Prof. Dr. Guoming Lai; Computer Science and Technology, Sun Yat-Sen University, Guangzhou, P. R. China <br />
-	Dr. Andrew Marsh; CEO, HoIP Telecom Ltd (Healthcare over Internet Protocol), UK; Secretary General of World Academy of BioMedical Sciences and Technologies (WABT) a UNESCO NGO, The United Nations <br />
-	Dr. Ali Mostafaeipour; Civil & Environmental Engineering Department, California State University, Fullerton, California, USA <br />
-	Prof. Dr., Eng. Robert Ehimen Okonigene; Department of Electrical & Electronics Engineering, Faculty of Engineering and Technology, Ambrose Alli University, Nigeria <br />
-	Prof. James J. (Jong Hyuk) Park; Department of Computer Science and Engineering (DCSE), SeoulTech, Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Korea <br />
-	Dr. Xuewei Qi; Research Faculty & PI, Center for Environmental Research and Technology, University of California, Riverside, California, USA <br />
-	Dr. Farzan Shenavarmasouleh (Co-Chair, Computer Vision & AI); R&D Engineer, MediaLab Inc, Georgia, USA <br />
-	Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
-	Prof. Fernando G. Tinetti (Congress Steering Committee); School of CS, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
-	Prof. Hahanov Vladimir; Vice Rector, and Dean of the Computer Engineering Faculty, Kharkov National University of Radio Electronics, Ukraine and Professor of Design Automation Department, Computer Engineering Faculty, Kharkov; IEEE Computer Society Golden Core Member; National University of Radio Electronics, Ukraine <br />
-	Prof. Shiuh-Jeng Wang (Congress Steering Committee); Director of Information Cryptology and Construction Laboratory (ICCL) and Director of Chinese Cryptology and Information Security Association (CCISA); Department of Information Management, Central Police University, Taoyuan, Taiwan; Guest Ed., IEEE Journal on Selected Areas in Communications. <br />
-	Dr. Yunlong Wang; Advanced Analytics at QuintilesIMS, Pennsylvania, USA <br />
-	Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
-	Prof. Hyun Yoe; Director of Agrofood IT Research Center and Vice President of Korea Association of ICT Convergence in the Agriculture and Food Business (KAICAF); Director of Agriculture IT Convergence Support Center (AITCSC); Department of of Information and Communication Engineering, Sunchon National University, Suncheon, Republic of Korea (South Korea) <br />
-	Prof. Jane You; Associate Head, Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong <br />
-	Dr. Farhana H. Zulkernine; Coordinator of the Cognitive Science Program, School of Computing, Queen's University, Kingston, ON, Canada
            </div>

            <h6 className="par_header">Wireless Networks (ICWN) 2024 - Program Committee</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            -	Prof. Emeritus Nizar Al-Holou; ECE Department; Vice Chair, IEEE/SEM-Computer Chapter; University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Emeritus Hamid R. Arabnia (Congress Steering Committee); The University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer); Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC). <br />
-	Dr. Travis Atkison; Director, Digital Forensics and Control Systems Security Lab, Department of Computer Science, College of Engineering, The University of Alabama, Tuscaloosa, Alabama, USA <br />
-	Dr. Afsaneh Banitalebi Dehkordi; Faculty of Computer Engineering, PNU University, Shahrekord, Iran <br />
-	Prof. Dr. Juan-Vicente Capella-Hernandez; Universitat Politecnica de Valencia (UPV), Department of Computer Engineering (DISCA), Valencia, Spain <br />
-	Prof. Emeritus Kevin Daimi; Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Leonidas Deligiannidis (Congress Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
-	Prof. Mary Mehrnoosh Eshaghian-Wilner (Congress Steering Committee); Professor of Engineering Practice, University of Southern California, California, USA; Adjunct Professor, Electrical Engineering, University of California Los Angeles, Los Angeles (UCLA), California, USA <br />
-	Prof. Tai-hoon Kim; School of Information and Computing Science, University of Tasmania, Australia <br />
-	Prof. Dr. Guoming Lai; Computer Science and Technology, Sun Yat-Sen University, Guangzhou, P. R. China <br />
-	Dr. Andrew Marsh; CEO, HoIP Telecom Ltd (Healthcare over Internet Protocol), UK; Secretary General of World Academy of BioMedical Sciences and Technologies (WABT) a UNESCO NGO, The United Nations <br />
-	Prof. Salahuddin Mohammad Masum; Computer Engineering Technology, Southwest Tennessee Community College, Memphis, Tennessee, USA <br />
-	Prof. Dr., Eng. Robert Ehimen Okonigene; Department of Electrical & Electronics Engineering, Faculty of Engineering and Technology, Ambrose Alli University, Nigeria <br />
-	Prof. James J. (Jong Hyuk) Park; Department of Computer Science and Engineering (DCSE), SeoulTech, Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Korea <br />
-	Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
-	Prof. Fernando G. Tinetti (Congress Steering Committee); School of CS, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
-	Prof. Hahanov Vladimir; Vice Rector, and Dean of the Computer Engineering Faculty, Kharkov National University of Radio Electronics, Ukraine and Professor of Design Automation Department, Computer Engineering Faculty, Kharkov; IEEE Computer Society Golden Core Member; National University of Radio Electronics, Ukraine <br />
-	Prof. Shiuh-Jeng Wang (Congress Steering Committee); Director of Information Cryptology and Construction Laboratory (ICCL) and Director of Chinese Cryptology and Information Security Association (CCISA); Department of Information Management, Central Police University, Taoyuan, Taiwan; Guest Ed., IEEE Journal on Selected Areas in Communications. <br />
-	Dr. Yunlong Wang; Advanced Analytics at QuintilesIMS, Pennsylvania, USA <br />
-	Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
-	Prof. Hyun Yoe; Director of Agrofood IT Research Center and Vice President of Korea Association of ICT Convergence in the Agriculture and Food Business (KAICAF); Director of Agriculture IT Convergence Support Center (AITCSC); Department of Information and Communication Engineering, Sunchon National University, Suncheon, Republic of Korea (South Korea) <br />
-	Prof. Jane You (Congress Steering Committee); Associate Head, Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong
            </div>

            <h6 className="par_header">Information & Knowledge Engineering (IKE) 2024 - Program Committee</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            -	Prof. Emeritus Nizar Al-Holou; Vice Chair, IEEE/SEM-Computer Chapter; University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Dr. Soheyla Amirian (Co-Chair, Computer Vision & AI); Faculty, School of Computing, University of Georgia, USA <br />
-	Prof. Emeritus Hamid R. Arabnia (Congress Steering Committee); The University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer); Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
-	Dr. Travis Atkison; Director, Digital Forensics and Control Systems Security Lab, Department of Computer Science, College of Engineering, The University of Alabama, Tuscaloosa, Alabama, USA <br />
-	Prof. Emeritus Kevin Daimi (Congress Steering Committee); Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Zhangisina Gulnur Davletzhanovna; Vice-rector of the Science, Central-Asian University, Kazakhstan, Almaty, Republic of Kazakhstan; Vice President of International Academy of Informatization, Kazskhstan, Almaty, Republic of Kazakhstan <br />
-	Prof. Leonidas Deligiannidis (Congress Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
-	Prof. Mary Mehrnoosh Eshaghian-Wilner (Congress Steering Committee); Professor of Engineering Practice, University of Southern California, California, USA; Adjunct Professor, Electrical Engineering, University of California Los Angeles, Los Angeles (UCLA), California, USA <br />
-	Dr. Farid Gharehmohammadi (Co-Chair, Computer Vision & AI); Department of Radiology & Center for Augmented Intelligence (CAI),  Mayo Clinic, Florida, USA <br />
-	Prof. Ray Hashemi (Session Chair, IKE & Steering Committee member); Professor of Computer Science and Information Technology, Armstrong Atlantic State University, Savannah, Georgia, USA <br />
-	Prof. Dr. Abdeldjalil Khelassi; Computer Science Department, Abou beker Belkaid University of Tlemcen, Algeria; Editor-in-Chief, Medical Technologies Journal; Associate Editor, Electronic Physician Journal (EPJ) - Pub Med Central <br />
-	Dr. Andrew Marsh; CEO, HoIP Telecom Ltd (Healthcare over Internet Protocol), UK; Secretary General of World Academy of BioMedical Sciences and Technologies (WABT) a UNESCO NGO, The United Nations <br />
-	Dr. Somya D. Mohanty; Department of CS, University of North Carolina - Greensboro, North Carolina, USA <br />
-	Dr. Ali Mostafaeipour; Civil & Environmental Engineering Department, California State University, Fullerton, California, USA <br />
-	Dr. Houssem Eddine Nouri; Informatics Applied in Management, Institut Superieur de Gestion de Tunis, University of Tunis, Tunisia <br />
-	Prof. Dr., Eng. Robert Ehimen Okonigene; Department of Electrical & Electronics Engineering, Faculty of Engineering and Technology, Ambrose Alli University, Nigeria <br />
-	Dr. Javier Ordus (Co-Chair, Quantum Computing & AI); Adjunct Faculty, School of Engineering & Computer Science, Baylor University, Waco, Texas, USA <br />
-	Prof. James J. (Jong Hyuk) Park; Department of Computer Science and Engineering (DCSE), SeoulTech, Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Korea <br />
-	Dr. Xuewei Qi; Research Faculty & PI, Center for Environmental Research and Technology, University of California, Riverside, California, USA <br />
-	Dr. Pablo Rivas (Co-Chair, Quantum Computing & AI); School of Engineering & Computer Science, Baylor University, Waco, Texas, USA <br />
-	Dr. Farzan Shenavarmasouleh (Co-Chair, Computer Vision & AI); R&D Engineer, MediaLab Inc, Georgia, USA <br />
-	Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
-	Dr. Robert Stahlbock (Co-Chair, Data Mining); Universitat Hamburg, Hamburg, Germany <br />
-	Prof. Fernando G. Tinetti (Congress Steering Committee); School of CS, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
-	Dr. Haoxiang Harry Wang; Cornell University, Ithaca, New York, USA; Founder and Director, GoPerception Laboratory, New York, USA <br />
-	Prof. Shiuh-Jeng Wang (Congress Steering Committee); Director of Information Cryptology and Construction Laboratory (ICCL) and Director of Chinese Cryptology and Information Security Association (CCISA); Department of Information Management, Central Police University, Taoyuan, Taiwan; Guest Ed., IEEE Journal on Selected Areas in Communications. <br />
-	Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
-	Prof. Jane You; Associate Head, Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong <br />
            </div>

          <h6 className="par_header">Image Processing, Computer Vision, & Pattern Recognition (IPCV) 2024 - Program Committee</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            - Prof. Emeritus Nizar Al-Holou; ECE Department; Vice Chair, IEEE/SEM-Computer Chapter; University of Detroit Mercy, Detroit, Michigan, USA <br />
- Dr. Mahmood Al-khassaweneh; Lewis University, Romeoville, Illinois, USA <br />
- Dr. Soheyla Amirian (Co-Chair, Computer Vision & AI); Faculty, School of Computing, University of Georgia, USA <br />
- Prof. Emeritus Hamid R. Arabnia (Congress Steering Committee); Department of Computer Science, The University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer); Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
- Alireza Bagheri Rajeoni; College of Engineering and Computer Science, University of South Carolina, Columbia, South Carolina, USA <br />
- Dr. Azita Bahrami (Vice-Chair); President, IT Consult, USA <br />
- Prof. Dr. Juan-Vicente Capella-Hernandez; Universitat Politecnica de Valencia (UPV), Department of Computer Engineering (DISCA), Valencia, Spain <br />
- Prof. Juan Jose Martinez Castillo; Director, The Acantelys Alan Turing Nikola Tesla Research Group and GIPEB, Universidad Nacional Abierta, Venezuela <br />
- Prof. Emeritus Kevin Daimi (Congress Steering Committee); Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
- Prof. Zhangisina Gulnur Davletzhanovna; Vice-rector of the Science, Central-Asian University, Kazakhstan, Almaty, Republic of Kazakhstan; Vice President of International Academy of Informatization, Kazskhstan, Almaty, Republic of Kazakhstan <br />
- Prof. Leonidas Deligiannidis (Congress Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
- Dr. Trung Duong; Research Faculty at Center for Advanced Infrastructure and Transportation (CAIT), Rutgers University, the State University of New Jersey, New Jersey, USA <br />
- Prof. Mary Mehrnoosh Eshaghian-Wilner (Congress Steering Committee); Professor of Engineering Practice, University of Southern California, California, USA; Adjunct Professor, Electrical Engineering, University of California Los Angeles, Los Angeles (UCLA), California, USA <br />
- Dr. Farid Gharehmohammadi (Co-Chair, Computer Vision & AI); Department of Radiology & Center for Augmented Intelligence (CAI),  Mayo Clinic, Florida, USA <br />
- Prof. Ray Hashemi (Vice-Chair); College of Engineering and Computing, Georgia Southern University, Georgia, USA <br />
- Prof. Byung-Gyu Kim; Multimedia Processing Communications Lab.(MPCL), Department of Computer Science and Engineering, College of Engineering, SunMoon University, South Korea <br />
- Prof. Tai-hoon Kim; School of Information and Computing Science, University of Tasmania, Australia <br />
- Prof. Dr. Guoming Lai; Computer Science and Technology, Sun Yat-Sen University, Guangzhou, P. R. China <br />
- Prof. Hyo Jong Lee; Director, Center for Advanced Image and Information Technology, Division of Computer Science and Engineering, Chonbuk National University, South Korea <br />
- Dr. Muhammad Naufal Bin Mansor; Faculty of Engineering Technology, Department of Electrical, Universiti Malaysia Perlis (UniMAP), Perlis, Malaysia <br />
- Dr. Andrew Marsh; CEO, HoIP Telecom Ltd (Healthcare over Internet Protocol), UK; Secretary General of World Academy of BioMedical Sciences and Technologies (WABT) a UNESCO NGO, The United Nations <br />
- Prof. Aree Ali Mohammed; Head, Computer Science Department, University of Sulaimani, Kurdistan, Iraq <br />
- Prof. Dr., Eng. Robert Ehimen Okonigene; Department of Electrical & Electronics Engineering, Faculty of Engineering and Technology, Ambrose Alli University, Nigeria <br />
- Prof. James J. (Jong Hyuk) Park; Department of Computer Science and Engineering (DCSE), SeoulTech, Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Korea <br />
- Dr. Farzan Shenavarmasouleh (Co-Chair, Computer Vision & AI); R&D Engineer, MediaLab Inc, Georgia, USA <br />
- Prof. Hayaru Shouno; Chair, Technical Committee of Neuro-Computing (NC), Institute of Electronics, Information & Communication Engineers (IEICE), Japan and University of Electro-Communications, Japan <br />
- Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
- Prof. Dr. Ir. Sim Kok Swee; Fellow, IEM; Senior Member, IEEE; Faculty of Engineering and Technology, Multimedia University, Melaka, Malaysia <br />
- Prof. Fernando G. Tinetti (Congress Steering Committee); School of CS, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
- Prof. Hahanov Vladimir; Vice Rector, and Dean of the Computer Engineering Faculty, Kharkov National University of Radio Electronics, Ukraine and Professor of Design Automation Department, Computer Engineering Faculty, Kharkov; IEEE Computer Society Golden Core Member; National University of Radio Electronics, Ukraine <br />
- Dr. Haoxiang Harry Wang; Cornell University, Ithaca, New York, USA; Founder and Director, GoPerception Laboratory, New York, USA <br />
- Prof. Shiuh-Jeng Wang (Congress Steering Committee); Director of Information Cryptology and Construction Laboratory (ICCL) and Director of Chinese Cryptology and Information Security Association (CCISA); Department of Information Management, Central Police University, Taoyuan, Taiwan; Guest Ed., IEEE Journal on Selected Areas in Communications. <br />
- Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
- Prof. Jane You; Associate Head, Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong
            </div>

            <h6 className="par_header">Modeling, Simulation & Visualization Methods (MSV) 2024 - Program Committee</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            -	Prof. Emeritus Nizar Al-Holou; Electrical and Computer Engineering Department; Vice Chair, IEEE/SEM-Computer Chapter; University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Emeritus Hamid R. Arabnia (Steering Committee); University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer);Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
-	Prof. Emeritus Kevin Daimi (Steering Committee); Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Leonidas Deligiannidis (Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
-	Dr. Richard Dill (Co-Chair, Military and Defense Modeling); Computer Science and Engineering Department, US Air Force Institute of Technology (AFIT), USA <br />
-	Prof. Michael R. Grimaila (Co-Chair, Military and Defense Modeling); Head, Systems Engineering & Management Department, US Air Force Institute of Technology (AFIT), USA <br />
-	Prof. Douglas D. Hodson (Co-Chair, Military and Defense Modeling); Computer Science and Engineering Department, US Air Force Institute of Technology (AFIT), USA <br />
-	Prof. Byung-Gyu Kim; Multimedia Processing Communications Lab.(MPCL), Department of CSE, College of Engineering, SunMoon University, South Korea <br />
-	Prof. Hyo Jong Lee; Director, Center for Advanced Image and Information Technology, Division of Computer Science and Engineering, Chonbuk National University, South Korea <br />
-	Dr. Muhammad Naufal Bin Mansor; Faculty of Engineering Technology, Department of Electrical, Universiti Malaysia Perlis (UniMAP), Perlis, Malaysia <br />
-	Prof. Aree Ali Mohammed; Head, Computer Science Department, University of Sulaimani, Kurdistan, Iraq <br />
-	Prof. James J. (Jong Hyuk) Park; DCSE, SeoulTech, Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Korea <br />
-	Dr. Xuewei Qi; Research Faculty & PI, Center for Environmental Research and Technology, University of California, Riverside, California, USA <br />
-	Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
-	Prof. Masami Takata (Co-Chair, Mathematical Modeling); Nara Women's University, Nara-shi, Nara, Japan <br />
-	Prof. Fernando G. Tinetti (Steering Committee); School of CS, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
-	Dr. Haoxiang Harry Wang; Cornell University, Ithaca, New York, USA; Founder and Director, GoPerception Laboratory, New York, USA <br />
-	Prof. Shiuh-Jeng Wang (Steering Committee); Director of Information Cryptology and Construction Laboratory (ICCL) and Director of Chinese Cryptology and Information Security Association (CCISA); Department of Information Management, Central Police University, Taoyuan, Taiwan; Guest Ed., IEEE Journal on Selected Areas in Communications. <br />
-	Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
-	Dr. Heng (Fred) Wu; Mathematics and Computer Science, West Virginia State University, Institute, West Virginia, USA <br />
-	Prof. Jane You; Associate Head, Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong
            </div>

            <h6 className="par_header">Parallel & Distributed Processing Techniques and Applications (PDPTA) 2024 - Program Committee</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            -	Prof. Emeritus Hamid R. Arabnia (Steering Committee); University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer); Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
-	Prof. Dr. Juan-Vicente Capella-Hernandez; Universitat Politecnica de Valencia (UPV), Department of Computer Engineering (DISCA), Valencia, Spain <br />
-	Prof. Juan Jose Martinez Castillo; Director, The Acantelys Alan Turing Nikola Tesla Research Group and GIPEB, Universidad Nacional Abierta, Venezuela <br />
-	Prof. Emeritus Kevin Daimi (Steering Committee); Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
-	Prof. Leonidas Deligiannidis (Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
-	Dr. Richard Dill (Co-Chair, Military and Defense Modeling); Computer Science and Engineering Department, US Air Force Institute of Technology (AFIT), USA <br />
-	Prof. Mary Mehrnoosh Eshaghian-Wilner (Steering Committee); Professor of Engineering Practice, University of Southern California, California, USA; Adjunct Professor, Electrical Engineering, University of California Los Angeles, Los Angeles (UCLA), California, USA <br />
-	Prof. Michael R. Grimaila (Co-Chair, Military and Defense Modeling); Head, Systems Engineering & Management Department, US Air Force Institute of Technology (AFIT), USA <br />
-	Prof. Houcine Hassan; Department of Computer Engineering (Systems Data Processing and Computers), Universitat Politecnica de Valencia, Spain <br />
-	Prof. Douglas D. Hodson (Co-Chair, Military and Defense Modeling); Computer Science and Engineering Department, US Air Force Institute of Technology (AFIT), USA <br />
-	Prof. Hiroshi Ishii; Department Chair, Tokai University, Minato, Tokyo, Japan <br />
-	Prof. Makoto Iwata; School of Information, Kochi University of Technology, Kami, Kochi, Japan <br />
-	Prof. George Jandieri; Georgian Technical University, Tbilisi, Georgia; Chief Scientist, The Institute of Cybernetics, Georgian Academy of Science, Georgia; Ed. Member, International Journal of Microwaves and Optical Technology, The Open Atmospheric Science Journal, American Journal of Remote Sensing, Georgia <br />
-	Prof. Kazuki Joe; Nara Women's University Nara, Japan <br />
-	Prof. Byung-Gyu Kim; Multimedia Processing Communications Lab.(MPCL), Department of CSE, College of Engineering, SunMoon University, South Korea <br />
-	Prof. Tai-hoon Kim; School of Information and Computing Science, University of Tasmania, Australia <br />
-	Prof. Dr. Guoming Lai; Computer Science and Technology, Sun Yat-Sen University, Guangzhou, P. R. China <br />
-	Prof. Hyo Jong Lee; Director, Center for Advanced Image and Information Technology, Division of Computer Science and Engineering, Chonbuk National University, South Korea <br />
-	Dr. Andrew Marsh; CEO, HoIP Telecom Ltd (Healthcare over Internet Protocol), UK; Secretary General of World Academy of BioMedical Sciences and Technologies (WABT) a UNESCO NGO, The United Nations <br />
-	Prof. Salahuddin Mohammad Masum; Computer Engineering Technology, Southwest Tennessee Community College, Memphis, Tennessee, USA <br />
-	Dr. Ali Mostafaeipour; Civil & Environmental Engineering Department, California State University, Fullerton, California, USA <br />
-	Prof. Hiroaki Nishikawa; Faculty of Engineering, Information and Systems, University of Tsukuba,  Japan <br />
-	Prof. Dr., Eng. Robert Ehimen Okonigene; Department of Electrical & Electronics Engineering, Faculty of Engineering and Technology, Ambrose Alli University, Nigeria <br />
-	Dr. Javier Ordus (Co-Chair, Quantum Computing & AI); Adjunct Faculty, School of Engineering & Computer Science, Baylor University, Waco, Texas, USA <br />
-	Prof. James J. (Jong Hyuk) Park; DCSE, SeoulTech, Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Korea <br />
-	Dr. Pablo Rivas (Co-Chair, Quantum Computing & AI); School of Engineering & Computer Science, Baylor University, Waco, Texas, USA <br />
-	Dr. Masakazu Sekijima; Tokyo Institute of Technology, Japan <br />
-	Dr. Manik Sharma; Department of Computer Science and Applications, DAV University, Jalandhar, India <br />
-	Prof. Hayaru Shouno (Steering Committee); The University of Electro-Communications, Japan <br />
-	Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
-	Prof. Masami Takata (Co-Chair, Mathematical Modeling); Nara Women's University, Nara-shi, Nara, Japan <br />
-	Prof. Fernando G. Tinetti (Steering Committee); School of CS, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
-	Prof. Hahanov Vladimir; Vice Rector, and Dean of the Computer Engineering Faculty, Kharkov National University of Radio Electronics, Ukraine and Professor of Design Automation Department, Computer Engineering Faculty, Kharkov; IEEE Computer Society Golden Core Member; National University of Radio Electronics, Ukraine <br />
-	Dr. Haoxiang Harry Wang; Cornell University, Ithaca, New York, USA; Founder and Director, GoPerception Laboratory, New York, USA <br />
-	Prof. Shiuh-Jeng Wang (Steering Committee); Director of Information Cryptology and Construction Laboratory (ICCL) and Director of Chinese Cryptology and Information Security Association (CCISA); Department of Information Management, Central Police University, Taoyuan, Taiwan; Guest Ed., IEEE Journal on Selected Areas in Communications. <br />
-	Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
-	Dr. Heng (Fred) Wu; Mathematics and Computer Science, West Virginia State University, Institute, West Virginia, USA <br />
-	Prof. Jane You; Associate Head, Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong
            </div>

            <h6 className="par_header">Security and Management (SAM) 2024 - Program Committee</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            Please check the list of committee members posted at: <br />
            <a href="http://sam.udmercy.edu/sam24/orgnaization_com.html " target="_blank" rel="noopener noreferrer">http://sam.udmercy.edu/sam24/orgnaization_com.html </a> 
            <br /> and <br />
            <a href="http://sam.udmercy.edu/sam24/program_com.html " target="_blank" rel="noopener noreferrer">http://sam.udmercy.edu/sam24/program_com.html </a>
<br />
            -	Prof. Emeritus Kevin Daimi (Chair, Security); Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA
            </div>

            <h6 className="par_header">Software Engineering Research and Practice (SERP) 2024 - Program Committee</h6>
            <div className="par" style={{fontSize: "9px"}}>            
            - Prof. Emeritus Nizar Al-Holou; Electrical & Computer Engineering Department; Vice Chair, IEEE/SEM-Computer Chapter; University of Detroit Mercy, Michigan, USA <br />
- Dr. Soheyla Amirian (Co-Chair, Computer Vision & AI); Faculty, School of Computing, University of Georgia, USA <br />
- Prof. Emeritus Hamid R. Arabnia (Congress Steering Committee); The University of Georgia, USA; Editor-in-Chief, Journal of Supercomputing (Springer); Fellow, Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC) <br />
- Dr. Travis Atkison; Director, Digital Forensics and Control Systems Security Lab, Department of Computer Science, College of Engineering, The University of Alabama, Tuscaloosa, Alabama, USA <br />
- Dr. Azita Bahrami (Vice-Chair); President, IT Consult, USA <br />
- Dr. Afsaneh Banitalebi Dehkordi; Faculty of Computer Engineering, PNU University, Shahrekord, Iran <br />
- Prof. Dr. Juan-Vicente Capella-Hernandez; Universitat Politecnica de Valencia (UPV), Department of Computer Engineering (DISCA), Valencia, Spain <br />
- Prof. Emeritus Kevin Daimi (Congress Steering Committee); Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA <br />
- Prof. Zhangisina Gulnur Davletzhanovna; Vice-rector of the Science, Central-Asian University, Kazakhstan, Almaty, Republic of Kazakhstan; Vice President of International Academy of Informatization, Kazskhstan, Almaty, Republic of Kazakhstan <br />
- Prof. Leonidas Deligiannidis (Congress Steering Committee); Department of Computer Information Systems, Wentworth Institute of Technology, Boston, Massachusetts, USA <br />
- Dr. Richard Dill (Co-Chair, Military and Defense Modeling); Computer Science and Engineering Department, US Air Force Institute of Technology (AFIT), USA <br />
- Prof. Mary Mehrnoosh Eshaghian-Wilner (Congress Steering Committee); Professor of Engineering Practice, University of Southern California, California, USA; Adjunct Professor, Electrical Engineering, University of California Los Angeles, Los Angeles (UCLA), California, USA <br />
- Dr. Farid Gharehmohammadi (Co-Chair, Computer Vision & AI); Department of Radiology & Center for Augmented Intelligence (CAI),  Mayo Clinic, Florida, USA <br />
- Prof. Michael R. Grimaila (Co-Chair, Military and Defense Modeling); Head, Systems Engineering & Management Department, US Air Force Institute of Technology (AFIT), USA <br />
- Prof. Ray Hashemi (Vice-Chair); College of Engineering and Computing, Georgia Southern University, Georgia, USA <br />
- Prof. Douglas D. Hodson (Co-Chair, Military and Defense Modeling); Computer Science and Engineering Department, US Air Force Institute of Technology (AFIT), USA <br />
- Prof. Byung-Gyu Kim; Multimedia Processing Communications Lab.(MPCL), Department of Computer Science and Engineering, College of Engineering, SunMoon University, South Korea <br />
- Dr. Vitus S. W. Lam; Senior IT Manager, Information Technology Services, The University of Hong Kong, Kennedy Town, Hong Kong; Chartered Member of The British Computer Society, UK; Former Vice Chairman of the British Computer Society (Hong Kong Section); Chartered Engineer & Fellow of the Institution of Analysts and Programmers <br />
- Dr. Andrew Marsh; CEO, HoIP Telecom Ltd (Healthcare over Internet Protocol), UK; Secretary General of World Academy of BioMedical Sciences and Technologies (WABT) a UNESCO NGO, The United Nations <br />
- Prof. Dr., Eng. Robert Ehimen Okonigene; Department of Electrical & Electronics Engineering, Faculty of Engineering and Technology, Ambrose Alli University, Nigeria <br />
- Prof. James J. (Jong Hyuk) Park; Department of Computer Science and Engineering (DCSE), SeoulTech, Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Korea <br />
- Prof. Abd-El-Kader Sahraoui; Toulouse University and LAAS CNRS, Toulouse, France <br />
- Dr. Farzan Shenavarmasouleh (Co-Chair, Computer Vision & AI); R&D Engineer, MediaLab Inc, Georgia, USA <br />
- Ashu M. G. Solo (Publicity), Fellow of British Computer Society, Principal/R&D Engineer, Maverick Technologies America Inc. <br />
- Prof. Fernando G. Tinetti (Congress Steering Committee); School of CS, Universidad Nacional de La Plata, La Plata, Argentina; also at Comision Investigaciones Cientificas de la Prov. de Bs. As., Argentina <br />
- Prof. Hahanov Vladimir; Vice Rector, and Dean of the Computer Engineering Faculty, Kharkov National University of Radio Electronics, Ukraine and Professor of Design Automation Department, Computer Engineering Faculty, Kharkov; IEEE Computer Society Golden Core Member; National University of Radio Electronics, Ukraine <br />
- Dr. Haoxiang Harry Wang; Cornell University, Ithaca, New York, USA; Founder and Director, GoPerception Laboratory, New York, USA <br />
- Prof. Shiuh-Jeng Wang (Congress Steering Committee); Director of Information Cryptology and Construction Laboratory (ICCL) and Director of Chinese Cryptology and Information Security Association (CCISA); Department of Information Management, Central Police University, Taoyuan, Taiwan; Guest Ed., IEEE Journal on Selected Areas in Communications. <br />
- Prof. Layne T. Watson; Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA <br />
- Dr. Heng (Fred) Wu; Mathematics and Computer Science, West Virginia State University, Institute, West Virginia, USA <br />
- Prof. Jane You; Associate Head, Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong
            </div>
                      
          </div>
          

        </div>
      </div>
    );
  }
}

export default Committees;