import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';
import { Button } from 'react-bootstrap';
import { push } from 'react-router-redux'
import {connect} from 'react-redux';

class Visa_start extends Component {

  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Online Request For Invitation Letters</h4>
          <h6 className="par_header">Instructions for Requesting Formal Invitation Letters for US Visa Purposes for Authors</h6>
            <div className="par">            
                Please use the online form (link provided below) to request an "Invitation Letter" for participation in 
                The 2024 World Congress in Computer Science, Computer Engineering, and Applied Computing 
                (Las Vegas, Nevada, July 22-25, 2024). 
                We will respond to your request as soon as your information is verified.
                <br/><br/>
                Please note that duplicate submissions will delay the processing of your request. Submit only one form per participant.
                <br/><br/>
                    <div style={{"text-align": "center"}}>
                        <Button style={{margin: "0 auto"}} onClick={()=>{
                            this.props.push("/csce2024/visa/form")
                        }}>
                                Get Online Invitation Letter Request Form
                        </Button>
                    </div>
            </div>
            
            <br/>


            <h6 className="par_header">US Visa Information for Scientists Traveling to the United States</h6>
            <div className="par">            
                We advise scientists traveling to the United States to apply for a US visa as early as possible. Below are helpful links to various websites with general information about US visa.
                <br/><br/>
                <Icon icon='link' size="1x"/> <a target="_blank" href="https://www.usembassy.gov/">Websites of U.S. Embassies and Consulates Around the World</a><br/>
                <Icon icon='link' size="1x"/> <a target="_blank" href="https://www.nationalacademies.org/our-work/international-visitors-office">International Visitor's Office</a><br/>
                <Icon icon='link' size="1x"/> <a target="_blank" href="https://travel.state.gov/content/travel/en/us-visas/tourism-visit/visitor.html">B1 - Visa (How to Apply)</a><br/>

                <br/>
                For additional information regarding US visa, please contact your <a target="_blank" href="https://www.usembassy.gov/">local US embassy</a>.
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
    }
  }
  
  const mapDispatchToProps = () => {
    return {
      push,
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps())(Visa_start);