import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Demo extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Demography History</h4>
            <div className="par">
            We anticipate having between 1,000 and 2,000 participants in the
Congress. The congress includes 21 major tracks, composed of: 122
technical, research, and panel sessions as well as a number of
keynote lectures and tutorials; all will be held simultaneously,
same location and dates: July 22-25, 2024. Last year, the Congress
had attracted speakers/authors and participants affiliated with over
158 different universities (including many from the top 50 ranked
institutions), major IT corporations (including: Microsoft, Google,
Apple, SAP, Facebook, Oracle, Amazon, Yahoo, Samsung, IBM, Ebay, GE,
Siemens, Philips, Ericsson, BAE Systems, Hitachi, NTT, Twitter, Uber
Technologies, ...), major corporations (including: Exxon Mobil,
Johnson & Johnson, JPMorgan Chase, PetroChina, GlaxoSmithKline, HSBC,
Airbus, Boeing, Hyundai, Goldman Sachs, Deutsche Bank, ...), government
research agencies (NSF, NIH, DoE, US Air Force, NSA National Security
Agency, Central Intelligence Agency, ...), US national laboratories
(including, NASA National Aeronautics and Space Administration, ANL
Argonne National Lab, Sandia National Lab, ORNL Oak Ridge National Lab,
Lawrence Berkeley National Lab, Lawrence Livermore National Lab,
Los Alamos National Lab, Pacific Northwest National Lab, ...), and a
number of Venture Capitalists as well as distinguished speakers
discussing Intellectual Property issues. Last year, 54% of attendees
were from academia, 25% from industry; 20% from government and funding
agencies; and 1% unknown. About half of the attendees were from outside
USA; from 69 nations.
            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Demo;