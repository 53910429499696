import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';
import { Carousel } from 'rsuite';
import GoogleMapReact from 'google-map-react';
import YouTube from 'react-youtube';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Venue extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    const opts = {
      height: '100%',
		  width: '100%',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    };

    return (
      <div className="r_container">
        <div className="home">


          {/* <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div> */}

          <div>
          <Carousel autoplayInterval={6000} autoplay className="custom-slider">
                <img
                src="/static/s3.jpg"
                height="250"
                style={{"object-fit": "cover"}}
                />
                <img
                src="/static/s2.jpg"
                height="250"
                style={{"object-fit": "cover"}}
                />
                <img
                src="/static/s1.jpg"
                height="250"
                style={{"object-fit": "cover"}}
                />
            </Carousel>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Venue</h4>
            <div className="par">
            The conference will be held at the "Luxor by MGM Resorts International", in Las Vegas, Nevada, USA
            </div>

            <h6 className="par_header">Reservation</h6>
            <div className="par">
            - Online Room Reservations: <a href="https://book.passkey.com/go/SCSC0724LX" target="_blank" rel="noopener noreferrer">https://book.passkey.com/go/SCSC0724LX</a> <br/>
            {/* <a target="_blank" href="https://book.passkey.com/go/SCSC1220LX">Click Here</a> <br/> */}
            <br/>    
            - By phone, please call 800-926-4737 or 702-262-4445. Make sure that you mention the Group Code "CSCE 2024" (this is the code that the hotel will use to handle the reserved block of rooms).
            <br/> <br/>
            -	Important Note: TO QUALIFY FOR THE CONFERENCE RATE, ROOMS MUST BE RESERVED BY July 6, 2024 (First-Come First-Served till the block of hotel rooms are fully booked.)
            </div>

            <br/>

            <h6 className="par_header">Room Rates</h6>
            <div className="par">
            The negotiated room rates appear below - all rates are for Single or Double occupancy (no extra charge for double occupancy). For more than two persons staying in the same room, there would be an additional Person Charge per night (maximum of four persons to a room). The main organizer of the conferences has reserved a large block of rooms for the duration of the conferences. These rooms are available only to our conference participants (on First-Come First-Serve basis till the block is fully booked.)
            <br/><br/>
            Below you will find the negotiated hotel room rates per night for CSCE 2024 Conference attendees (add Resort Fee & Tax to each rate, see below):
            <br/><br/>

            <Grid fluid>
              <Row className="show-grid">
                <Col xs={8}>
                July 19, 2024 (Friday): <br/>    
                July 20, 2024 (Saturday): <br/>
                July 21, 2024 (Sunday): <br/>    
                July 22, 2024 (Monday): <br/>
                July 23, 2024 (Tuesday): <br/>
                July 24, 2024 (Wednesday): <br/>
                July 25, 2024 (Thursday): <br/>
                July 26, 2024 (Friday): <br/>
                July 27, 2024 (Saturday):
                </Col>
                <Col xs={16}>
                $132.00 <br/>
                $150.00 <br/>
                $35.00 (most conference attendees arrive on this day)<br/>
                $35.00 <br/>
                $35.00 <br/>
                $35.00 <br/>
                $35.00 (most conference attendees depart on this day)<br/>
                $125.00 <br/>
                $145.00 <br/>
                </Col>
              </Row>
            </Grid>

            <br/> <br/>
            A reduced daily Resort Fee of $30 (mandatory) plus the current Clark County room tax of 13.38% (subject to change) will be charged in addition to the room rates set forth above. The Resort Fee includes: Property-wide high speed internet access (public spaces and in-room), unlimited local and toll free calls, airline boarding pass printing, and fitness center access for guests 18+. The Resort Fee is a mandatory fee set by all major hotels in Las Vegas. The block of rooms reserved for our conference are in the new wing/tower of the Luxor hotel (these rooms are premium and so nicer than other wings.)
            <br/> <br/>
            Rates for other dates (not listed above) can be obtained from the hotel. Rooms have to be reserved by no later than July 6, 2024. The hotel will honor the room reservations until the block of rooms reserved for the conference is fully booked (based on a First-come First-serve). We anticipate that the block of rooms will be fully booked prior to July 6, 2024. Therefore, attendees are strongly advised to reserve their rooms as early as possible if they plan to stay in the hotel where the conference will be held. At the time when the room reservation is made, your credit card will be billed for the first night's room rate, resort fee, and tax (a procedure followed by all major hotels in USA). Full refund will be made if the reservation is cancelled four (or more) days before the date of arrival (cancellations must be done by the attendee himself/herself by contacting the hotel directly; the conference is not permitted to cancel any reservations on behalf of the attendees.)

            </div>

            <br/>

            <h6 className="par_header">About Luxor Hotel</h6>
            <div className="par">
            One of the most iconic hotels on The Strip, the Luxor pyramid invites curiosity while its interior rewards visitors with equal parts excitement, mystery and intrigue. This 4,405-room resort features a wide variety of entertainment including the newly reimagined Blue Man Group, Criss Angel’s MINDFREAK LIVE, the provocative adult revue FANTASY and the hilarious comedy of Carrot Top.
            <br/><br/>
            Luxor provides an unparalleled travel destination with world-class exhibits including BODIES…The Exhibition and Titanic: The Artifact Exhibition, a spa and salon, a wedding chapel, an inviting pool complex and delectable restaurants. Luxor also is connected to Mandalay Bay through The Shoppes at Mandalay Place – a collection of boutiques and restaurants.
            <br/> 
            <a target="_blank" href="https://www.luxor.com/content/dam/MGM/luxor/casino/luxor-hotel-property-map.pdf">View Property Map</a>

            <br />
            <hr/>
            <div style={{fontSize: "x-large", fontWeight: "bold", marginBottom: "0.75rem"}}>COVID-19 Regulations</div>
            We are excited to announce <a target="_blank" href="https://www.mgmresorts.com/en/groups-and-weddings.html">“Convene with Confidence”</a>, a comprehensive plan designed to facilitate the safe return of meetings and conventions within all MGM Resorts properties, subject to local regulations and restrictions. 
            The Convene with Confidence plan is the result of months of work in consultation with experts and builds on the company’s <a target="_blank" href="https://www.mgmresorts.com/en/covid-19/seven-point-safety-plan.html">“Seven-Point Safety Plan”</a>. 
            This includes the option for a multi-layered health screening process using CLEAR’s Health Pass to link on-site rapid, molecular COVID-19 testing. “Convene with Confidence” consists of detailed protocols supported by highly trained employees, to incorporate health and safety into every aspect of the convention and meeting process.
            
            <br/> <br/>

            <div className="mgm_youtube">
              <YouTube videoId="8rAvhgVsMXQ" opts={opts} onReady={this._onReady} />
            </div>
            
            
            </div>

            <br/>

            <h6 className="par_header">Directions</h6>
            <div className="par">
            You can get to Luxur Hotel by shuttle (bus) service from Las Vegas airport. The shuttles run 24 hours a day (7 days a week). Ask the information desk at the airport to direct you to the shuttle stations. Taxi services are also available 24 hours a day at the airport as well as Limousine services. For driving directions <a target="_blank" href="https://www.google.com/maps/dir//Luxor+Hotel+%26+Casino,+3900+S+Las+Vegas+Blvd,+Las+Vegas,+NV+89119/@36.0979805,-115.1782453,15z/data=!4m9!4m8!1m0!1m5!1m1!1s0x80c8c5ce3e5cb447:0xb5a742eafd00138!2m2!1d-115.1760672!2d36.09551!3e0"> Click Here</a>
            <br/> <br/>
            <div style={{textAlign: "center"}}>
                3900 Las Vegas Blvd South <br/>
                Las Vegas, Nevada, 89119 <br/>
                Information or reservations: 1-800-926-4737 (from USA) or 702-262-4450 
            </div>
            </div>
            <br/>

            {/* <div style={{ height: '16rem', width: '100%' }}>
                <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyC_3tJsnBp0mcEvdOfHEUU5ZTdmca0UdJQ" }}
                defaultCenter={{lat: 36.0954318, lng: -115.1737392}}
                defaultZoom={16}
                >
                <AnyReactComponent
                    lat={36.0954318}
                    lng={-115.1737392}
                    text="Luxor"
                />
                </GoogleMapReact>
            </div> */}
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Venue;