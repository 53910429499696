import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsCSC extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">CSC'24 - The 22nd Int'l Conf on Scientific Computing</h4>
            <div className="par">
            CSC is an international conference that serves researchers, scholars, professionals, students, and academicians who are looking to both foster working relationships and gain access to the latest research results. It is being held jointly (same location and dates) with a number of other research conferences; namely, The 2024 World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE'24).
            <br/><br/>
            We anticipate having between 1,000 and 2,000 participants in the Congress. The congress includes 21 major tracks composed of: 122 technical, research, and panel sessions as well as a number of keynote lectures and tutorials; all will be held simultaneously, same location and dates: July 22-25, 2024. Last year, the Congress had attracted speakers/authors and participants affiliated with over 158 different universities (including many from the top 50 ranked institutions), major IT corporations (including: Microsoft, Google, Apple, SAP, Facebook, Oracle, Amazon, Yahoo, Samsung, IBM, Ebay, GE, Siemens, Philips, Ericsson, BAE Systems, Hitachi, NTT, Twitter, Uber Technologies, ...), major corporations (including: Exxon Mobil, Johnson & Johnson, JPMorgan Chase, PetroChina, GlaxoSmithKline, HSBC, Airbus, Boeing, Hyundai, Goldman Sachs, Deutsche Bank, ...), government research agencies (NSF, NIH, DoE, US Air Force, NSA National Security Agency, Central Intelligence Agency, ...), US national laboratories (including, NASA National Aeronautics and Space Administration, ANL Argonne National Lab, Sandia National Lab, ORNL Oak Ridge National Lab, Lawrence Berkeley National Lab, Lawrence Livermore National Lab, Los Alamos National Lab, Pacific Northwest National Lab, ...), and a number of Venture Capitalists as well as distinguished speakers discussing Intellectual Property issues. Last year, 54% of attendees were from academia, 25% from industry; 20% from government and funding agencies; and 1% unknown. About half of the attendees were from outside USA; from 69 nations.
            <br/><br/>
            The core of Scientific Computing includes the construction of mathematical models and quantitative analysis tools as well as the use of computers to analyze and solve scientific problems. 
            <br/><br/>
            The primary goal of this conference is to provide a platform for researchers, scientists, industry experts and scholars to share their novel ideas and research results on the application of human cognition models in various practical computing applications. Through this conference, the organizers would like to develop an interdisciplinary venue to contribute and discuss the ongoing innovations, applications and solutions to challenging problems of engineering human brain processes, learning mechanisms and decision making processes.
            <br/><br/>
            <b>You are invited to submit a paper for consideration.</b> All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings of Congress (CSCE). The published proceedings will be submitted for indexing in the Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; Scopus; EI Engineering Index; Google Scholar; DBLP; and others. Therefore, this year’s proceedings will have an elevated science indexation when compared to prior years’ publications.
            <br/>
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            {/* <br />
            Papers should be typeset by using the general typesetting instructions available at (select "US letter" option for accessing templates):
            <br />
            <a href="https://www.ieee.org/conferences/publishing/templates.html " target="_blank" rel="noopener noreferrer">https://www.ieee.org/conferences/publishing/templates.html</a> <br />
            (i.e., single line spacing, 2-column format). All submissions must be original (papers must not have been previously published or currently being considered by others for publication). */}

            <br /><br />
            Past publications of proceedings: <a href="https://american-cse.org/csce2024/publisher" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/publisher</a> and <a href="https://american-cse.org/csce2024/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/special_issues</a>
            <br /> <br />
            Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <br />
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a>

            <br/><br/>
            The list of topics that appears below is by no means meant to be exhaustive: <br/>
            <b>Algorithms and Methods:</b> Monte Carlo methods and applications; Numerical methods and simulation; Quantum computing; Computational number theory; Optimization and approximation methods; Probabilistic and randomized methodologies; Computational geometry; Computational biology; Computational chemistry; Computational fluid dynamics; Computational physics; Computational mechanics; Computational electromagnetics and computational electrodynamics; computational sociology; Splines and wavelets; Inversion problems; Cellular automata; Ordinary and partial differential equations; Stochastic differential equations; Integral equations; Finite element methods; Multi-level and Multi-grid methods; Operational research; Dynamical systems; Nonsymmetric solvers; Engineering problems and emerging applications.<br/>
            <b>High Performance Computing and Communication Systems:</b> Cluster computing; Supercomputing; Cloud computing; Autonomic computing; P2P computing; Mobile computing; Grid computing; Parallel/distributed architectures and algorithms; Networks and interconnection networks; Reliability and fault-tolerance; The use of building block processors; Real-time and embedded systems; Multimedia communications, systems, and applications; Software tools and environments for Scientific Computing; Performance analysis, evaluation and monitoring; Distributed systems; FPGA, multicore, GPU, SOC and applications; Nanotechnology in HPC; High-performance mobile computation and communication; Petri Nets; Web-based simulation and computing; and Emerging technologies.<br/>
            <b>Modeling, Simulation and Visualization Methods:</b> Computational modeling and simulation in science and engineering; Molecular modeling and simulation; Simulation languages and tools; Performance modeling; Information and scientific visualization; Modeling methodologies; Visual interactive simulation and modeling; Visualization tools and systems for simulation and modeling; Process, device, circuit simulation and modeling Multi-level modeling; CAD/CAE/CAM; Agent based simulation; Analytical and stochastic modeling techniques and applications; Chaos modeling, control and signal transmission; Simulation of complex systems; Simulation of intelligent systems; Vision and visualization; Prototyping and simulation; Biomedical visualization and applications; Discrete and numeric simulation; Modeling and simulation frameworks, and Virtual reality and simulation.<br/>
            <b>Big Data and Data Analytic:</b> Software and hardware architectures; Big Data visualization; Services; Data analytics; toolkits; open platforms; business processes; Managing, analyzing, and using large volumes of structured and/or unstructured data; Simulation and modeling; Big Data and decision sciences and analytics; Scientific Crowdsourcing; Case studies; and Applications.<br/>

            </div>
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsCSC;