import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsICOMP extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">ICOMP'24 - The 25th Int'l Conf on Internet Computing and Internet of Things</h4>
            <div className="par">
            ICOMP  is an international conference that serves researchers, scholars, professionals, students, and academicians who are looking to both foster working relationships and gain access to the latest research results. It is being held jointly (same location and dates) with a number of other research conferences; namely, The 2024 World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE'24).
<br/><br/>
We anticipate having between 1,000 and 2,000 participants in the Congress. The congress includes 21 major tracks composed of: 122 technical, research, and panel sessions as well as a number of keynote lectures and tutorials; all will be held simultaneously, same location and dates: July 22-25, 2024. Last year, the Congress had attracted speakers/authors and participants affiliated with over 158 different universities (including many from the top 50 ranked institutions), major IT corporations (including: Microsoft, Google, Apple, SAP, Facebook, Oracle, Amazon, Yahoo, Samsung, IBM, Ebay, GE, Siemens, Philips, Ericsson, BAE Systems, Hitachi, NTT, Twitter, Uber Technologies, ...), major corporations (including: Exxon Mobil, Johnson & Johnson, JPMorgan Chase, PetroChina, GlaxoSmithKline, HSBC, Airbus, Boeing, Hyundai, Goldman Sachs, Deutsche Bank, ...), government research agencies (NSF, NIH, DoE, US Air Force, NSA National Security Agency, Central Intelligence Agency, ...), US national laboratories (including, NASA National Aeronautics and Space Administration, ANL Argonne National Lab, Sandia National Lab, ORNL Oak Ridge National Lab, Lawrence Berkeley National Lab, Lawrence Livermore National Lab, Los Alamos National Lab, Pacific Northwest National Lab, ...), and a number of Venture Capitalists as well as distinguished speakers discussing Intellectual Property issues. Last year, 54% of attendees were from academia, 25% from industry; 20% from government and funding agencies; and 1% unknown. About half of the attendees were from outside USA; from 69 nations.
<br/><br/>
The primary goal of this conference is to provide a platform for researchers, scientists, industry experts and scholars to share their novel ideas and research results on the application of human cognition models in various practical computing applications. Through this conference, the organizers would like to develop an interdisciplinary venue to contribute and discuss the ongoing innovations, applications and solutions to challenging problems of engineering human brain processes, learning mechanisms and decision making processes.
<br/><br/>
<b>You are invited to submit a paper for consideration.</b> All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings of Congress (CSCE). The published proceedings will be submitted for indexing in the Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; Scopus; EI Engineering Index; Google Scholar; DBLP; and others. Therefore, this year’s proceedings will have an elevated science indexation when compared to prior years’ publications.
            <br/>
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            {/* <br />
            Papers should be typeset by using the general typesetting instructions available at (select "US letter" option for accessing templates):
            <br />
            <a href="https://www.ieee.org/conferences/publishing/templates.html " target="_blank" rel="noopener noreferrer">https://www.ieee.org/conferences/publishing/templates.html</a> <br />
            (i.e., single line spacing, 2-column format). All submissions must be original (papers must not have been previously published or currently being considered by others for publication). */}

            <br /><br />
            Past publications of proceedings: <a href="https://american-cse.org/csce2024/publisher" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/publisher</a> and <a href="https://american-cse.org/csce2024/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/special_issues</a>
            <br /> <br />
            Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <br />
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a>
            <br/><br/>
            Topics of interest include, but are not limited to, the following: <br/>
            <b>Internet Computing:</b><br/>
            - Social networks <br/>
            - Data traffic models and statistics <br/>
            - Electronic commerce and internet <br/>
            - Design and analysis of internet protocols and engineering <br/>
            - Web mining <br/>
            - Network architectures and network computing <br/>
            - Internet and emerging technologies <br/>
            - Internet security and trust <br/>
            - Internet law and compliance <br/>
            - Internet based decision support systems <br/>
            - Internet and enterprise management <br/>
            - Internet banking systems <br/>
            - Internet and video technologies <br/>
            - Caching algorithms for the internet<br/>
            - Grid based computing and internet tools <br/>
            - Cooperative applications <br/>
            - Tele-medical and related applications <br/>
            - Mobile computing <br/>
            - The WWW and intranets <br/>
            - Digital libraries <br/>
            - Web interfaces to databases <br/>
            - Cloud computing <br/>
            - Alternative web lifestyles, role-playing, chat, ... <br/>
            - Server space/web server performance <br/>
            - Web monitoring <br/>
            - Web site design and coordination <br/>
            - Other aspects and applications relating to internet-based computing Internet of Things (IoT)<br/>
            - Next generation networks <br/>
            - Sensor systems and devices <br/>
            - Smart environments <br/>
            - Social networks and analytics <br/>
            - Software architecture and middleware <br/>
            - Mobile services; including, mobile APIs and Apps <br/>
            - Smart city and supporting technologies <br/>
            - Intelligent guidance systems <br/>
            - Data and knowledge management <br/>
            - Context- and location-awareness <br/>
            - Iot Security, privacy and trust <br/>
            - e-Government, e-Commerce, e-Science, ... <br/>
            - Performance evaluation and modeling <br/>
            - Intelligent systems for IoT and services computing <br/>
            - Energy-aware technologies - Social impact of IoT <br/>
            - Predictive modeling and patterns <br/>
            - Scheduling methods <br/>
            - IoT emerging applications<br/>
            <br/>
            <b>Computer Games Design and Development:</b><br/>
            - Managing gaming communities <br/>
            - Augmented reality games <br/>
            - Special-purpose hardware for games <br/>
            - Computer games and education <br/>
            - Threading technologies for games <br/>
            - Assessment of new generation of computer games <br/>
            - The impact of art and culture in game design<br/>
            - Game theory as it relates to internet <br/>
            - Artificial intelligence and computer games <br/>
            - Tools for game development on the internet <br/>
            - Grid computing and games <br/>
            - 3D hardware accelerators for games on the internet <br/>
            - Audio-video communication tools for network 3D games <br/>
            - Virtual actors - Virtual world creation <br/>
            - Holographic displays and games <br/>
            - Computer graphics and virtual reality tools for games <br/>
            - Innovative products for game development <br/>
            - Case studies <br/>
            <br/>
            <b>Big Data (in the context of internet computing):</b><br/>
            - Algorithms for enhancing data quality <br/>
            - Models and frameworks for Big Data <br/>
            - Graph algorithms and Big Data <br/>
            - Machine learning <br/>
            - Natural language processing <br/>
            - Volume, Velocity, Variety, Veracity and Value <br/>
            - Cloud based infrastructures (storage & computing resources) <br/>
            - Grid and stream computing for Big Data <br/>
            - HPC, including parallel & distributed processing <br/>
            - Programming models and environments to support Big Data <br/>
            - Emerging architectural frameworks for Big Data <br/>
            - Web search technologies <br/>
            - Cleaning Big Data (noise reduction), acquisition & integration <br/>
            - Big Data as a service <br/>
            - Big Data analytics in e-Government and society <br/>
            - Applications in science, engineering, healthcare, visualization, business, education, security, humanities, bioinformatics, health informatics, medicine, finance, law, transportation, retailing, telecommunication, all search-based applications, ... <br/>
            <br/>
            <b>Semantic Web:</b><br/>
            - Machine learning approaches for semantic web <br/>
            - Semantic web for e-Business and e-Learning <br/>
            - Semantic web mining <br/>
            - Semantic web trust, privacy, and security <br/>
            - Tools, languages, and techniques for semantic annotation of web data <br/>
            - Semantic searching <br/>
            - Hypermedia and semantic web <br/>
            - Software agents for semantic web <br/>
            - Community web portals <br/>
            - Semantic interoperability <br/>
            - Ontology creation, evolution, reconciliation, and mediation <br/>
            - Semantic web<br/>
            -based knowledge management and extraction<br/>
            - Service discovery, description, composition and invocation <br/>
            - Intersection of Big Data and semantic web <br/>
            - Case studies<br/>
            <br/>
            <b>Web Services:</b><br/>
            - Web Service Technologies and Research Directions: Enhancements to the basic web services platform such as SOAP, WSDL, UDDI, ...; advanced web service technologies including security, workflow and process management, transactions, mobile and wireless, portals, services management, quality-of-service (QoS); novel web service architectures; development and modeling frameworks for web service applications; composite web services, enabling technologies and support infrastructure; data structures and models. <br/>
            - Practices for Developing Enterprise-class Web Services and Applications: Design patterns; architecting for customization, maintenance, and management; architecting for easy integration or service "consumption"; fault-tolerant architectures; service mediation systems and architectures. <br/>
            - Case Studies of Web Service Development and Deployment: e-Commerce applications using web services; Business-to-Business (B2B) applications using web services; mobile and wireless applications using web services; utility and on-demand computing using web services; government applications using web services; applications of web services in developing countries. <br/>
            - Multimedia applications using web services - Interactive TV applications using web services - Big Data: Scalable web services for Big Data; services for data integration; web services for mapping data to information and to knowledge. - Novel applications, case studies, and emerging technologies<br/>
            </div>
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsICOMP;