import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Photos extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>

          <div className="page_content">          
          <h4 className="par_header">CSCE 2024</h4>
            <div className="par">
                <div className="pic_wrapper_gal">
                    <img src="/static/csce24.JPG"></img>
                    <br/>
                <a target="_blank" href="https://photos.app.goo.gl/c3UR9ogz4gYKXn1M8">View More</a>
                </div>
                <br />
            </div>
       
          <h4 className="par_header">CSCE 2023</h4>
            <div className="par">
                <div className="pic_wrapper_gal">
                    <img src="/static/csce23.JPG"></img>
                    <br/>
                <a target="_blank" href="https://photos.app.goo.gl/btzWLFZEJk9UeQTw6">View More</a>
                </div>
                <br />
            </div>

          <h4 className="par_header">CSCI 2022</h4>
            <div className="par">
                <div className="pic_wrapper_gal">
                    <img src="/static/csci22.JPG"></img>
                    <br/>
                <a target="_blank" href="https://photos.app.goo.gl/JecPgJPkeK1SxC9F6">View More</a>
                </div>
                <br />
            </div>
          
          <h4 className="par_header">CSCE 2022</h4>
            <div className="par">
                <div className="pic_wrapper_gal">
                    <img src="/static/csce22.jpg"></img>
                    <br/>
                <a target="_blank" href="https://photos.app.goo.gl/YhDBKQ9hC1ZzQfQL6">View More</a>
                </div>
                <br />
            </div>
          
          <h4 className="par_header">CSCE 2021</h4>
            <div className="par">
                <div className="pic_wrapper_gal">
                    <img src="/static/csce21.jpg"></img>
                    <br/>
                <a target="_blank" href="https://photos.app.goo.gl/DR6XGPweJiBD62eZ9">View More</a>
                </div>
            </div>

            <h4 className="par_header">CSCI 2021</h4>
            <div className="par">
                <div className="pic_wrapper_gal">
                    <img src="/static/csci21.jpg"></img>
                    <br/>
                <a target="_blank" href="https://photos.app.goo.gl/Qgo1JkUUqThhcGXt6">View More</a>
                </div>
            </div>

            <h4 className="par_header">CSCI 2020</h4>
            <div className="par">
                <div className="pic_wrapper_gal">
                    <img src="/static/csci20.jpg"></img>
                    <br/>
                <a target="_blank" href="https://photos.app.goo.gl/q79hGhfjd9cUyca89">View More</a>
                </div>
            </div>
            
        <h4 className="par_header">Past Events</h4>
            <div className="par">
                <div className="pic_wrapper_gal">
                    <img src="/static/past_evs.jpg"></img>
                    <br/>
                <a target="_blank" href="https://photos.ucmss.com/">View More</a>
                </div>
            </div>
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Photos;