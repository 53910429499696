import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class PaperS extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Submission of Papers</h4>
            <div className="par">
            To submit your paper for Evaluation, upload to: <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a> <br />
            <br />
            All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings of Congress (CSCE). The published proceedings will be submitted for indexing in the Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; Scopus; EI Engineering Index; Google Scholar; DBLP; and others. Therefore, this year’s proceedings will have an elevated science indexation when compared to prior years’ publications.
            <br/>
            {/* <br />
            <b>CSCE 2023 Proceedings (Last Year’s Xplore Indexation) can be found at:</b>
            <br /> */}
            <a href="https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding</a>
            <br /> 
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            <br /> <br />
            Each paper will be peer-reviewed by two experts in the field for originality, significance, clarity, impact, and soundness. In cases of contradictory recommendations, a member of the conference program committee would be charged to make the final decision; often, this would involve seeking help from additional referees. Papers whose authors include a member of the conference program committee will be evaluated using the double-blinded review process. (Essay/philosophical papers will not be refereed but may be considered for discussion/panels).
            <br /><br />
            ALL REASONABLE TYPESETTING FORMATS ARE ACCEPTABLE for evaluation. Later, the authors of accepted papers will be asked to follow the publisher’s template (after the conference).

            <br /><br />
            {/* Later, authors of accepted papers must agree with the standard 
            <a href="https://www.computer.org/conferences/cps/copyright" target="_blank" rel="noopener noreferrer"> IEEE CPS statement in reference to Copyrights (Intellectual Property Rights) </a>
            and 
            <a href="https://www.ieee.org/publications/rights/index.html" target="_blank" rel="noopener noreferrer"> Policy on Electronic Dissemination</a>
            <br /> <br /> */}
            Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <br />
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/ </a>


            Submissions must be uploaded by the due date <a href="https://american-cse.org/csce2024/deadlines" target="_blank" rel="noopener noreferrer">(See Important Date) </a>
            and must be in either MS WORD doc or pdf formats (maximum of 8 pages for Regular Research Papers; maximum of 5 pages for Short Research Papers; and maximum of 3 pages for Extended Abstract/Poster Papers - the number of pages includes all figures, tables, and references). Papers must not have been previously published or currently submitted for publication elsewhere.
For more information regarding Paper Categories 
<a href="https://american-cse.org/csce2024/paper_categories" target="_blank" rel="noopener noreferrer"> Click Here</a>

              <br/>
              <br/>
              The first page of the paper should include the followings: <br/>
              - Title of the paper <br/>
              - Name, affiliation, City, Country, and email address of each author (identify the name of the Contact Author). For Science Citation Indexation (SCI) inclusion processes, we prefer that the Contact Authors to provide email addresses that can be traced (ie, SCI requires that the Contact Authors be positively identified.)<br/>
              - Abstract (between 100 and 120 words)<br/>
              - A maximum of 6 topical keywords (Indexed Terms) that would best represent the work described in the paper (It would be more helpful to provide topical keywords that do not appear in the Title of the paper, if possible.)<br/>
              - After the topical keywords, Write the type of the submission as "Regular Research Paper", "Short Research Paper", or "Extended Abstract/Poster Research Paper".<br/>
              - The actual text of the paper can start from the first page (space permitting).<br/>
              
              <br/><br/>
              A subset of past conference proceedings (publications) of CSCI and CSCE appear in the link below (there are also multiple books in the publication pipeline): <br />
              <a href="https://american-cse.org/csce2024/publisher " target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/publisher</a> and <a href="https://american-cse.org/csce2024/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/special_issues</a>
            </div>

            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default PaperS;