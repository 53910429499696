import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class Jobs extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Career, Job, & Education Fair</h4>
            <div className="par">
            The CSCE 2024 conference plans to host a Career, Job, & Education Fair during the conference dates (July 22-25, 2024). This annual strategic event provides the conference attendees and participants with opportunities to connect with employers and educators and learn more about career options. Corporations and Universities can also exhibit their products, services, and degree programs during the event.
            <br/><br/>
            Interested parties should contact cs@american-cse.org.
            </div>
            

          </div>
          

        </div>
      </div>
    );
  }
}

export default Jobs;