import React, { Component } from 'react';

class FinalPapers extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Final Papers</h4>
            <div className="par">
            
            <p style= {{"textAlign": "center", "fontSize": "larger"}}>
            <b>Instructions for the preparation of Final papers for Publication</b> <br />
            <b>Publisher: Springer Nature</b>
            <hr />
            </p>
            
            IMPORTANT publication instructions appear below (two sets of instructions): <br /> <br />
            DEADLINE:  September 3, 2024 <br />
            <br />
            <ol>
                <li>
                    <p>
                    Typesetting Instructions for Preparation of Final Papers: <br />
	                Please note that the instructions for the preparation of Final Papers are intended to give 	consistency of style and format. Authors who are challenged with these typing 	instructions should try their best to get as close as possible to the formatting instructions; 	the publisher would then attempt to modify the formatting as needed. The MS WORD 	and LaTex Templates appear in the links below together with general instructions for the preparation of final papers: <br />
                    <a href=" https://www.springer.com/gp/computer-science/lncs/conference-proceedings-guidelines" target="_blank" rel="noopener noreferrer"> https://www.springer.com/gp/computer-science/lncs/conference-proceedings-guidelines</a> <br />
                    and/or <br />
                    <a href="https://resource-cms.springernature.com/springer-cms/rest/v1/content/19242230/data/v13" target="_blank" rel="noopener noreferrer">https://resource-cms.springernature.com/springer-cms/rest/v1/content/19242230/data/v13</a>
                    <br />
                    <br />
                    IMPORTANT: (common sense) <br />
                    - The paper should be formatted using ONE-COLUMN style (Springer's formatting) <br />
                    - All papers that appear in the reference list should have COMPLETE information 	(including the publisher's name, paginations, ...) <br />
                    - All papers that appear in the reference list should be cited within the manuscript text. <br />
                    - All URL's/Web sites that appear in the reference list should also include the "Date of 	Access". <br />
                    - All figures, tables, ... should be numbered and have meaningful captions. All these should also be cited within the manuscript text. <br />
                    - All major equations should be numbered and cited within the manuscript text. <br />
                    - Full affiliation + country should be provided for each author. <br />
                    </p>
                </li>
                <br /> 
                <li>
                    <p>
                    Upload/submit Instructions: (i.e., What to Upload): <br />
                    IF THE PAPER IS FORMATTED USING MS WORD: <br />
                    <ul>
                        <li>After preparing your paper using the Word Template, save both the Word file and the PDF version of your paper in a folder/directory on your computer. Name the folder/directory using your paper ID number, which can be found in the notification of acceptance you've already received. Next Upload the folder/directory to the publication portal. The publisher (Springer Nature / EquinOCS) will send you the link to the portal directly via email. Be sure to check your spam folder in case the email ends up there.</li>                    
                    </ul>
                    IF THE PAPER IS FORMATTED USING LaTeX: <br />
                    <ul>
                        <li>After preparing your paper using the LaTeX template, save all LaTeX files, LaTeX source files (figures, tables, images, bib, main, ...) AND the PDF version of the final paper in a folder/directory on your computer. Name the folder/directory using your paper ID number, which can be found in the notification of acceptance you've already received. Next Upload the folder/directory to the publication portal. The publisher (Springer Nature / EquinOCS) will send you the link to the portal directly via email. Be sure to check your spam folder in case the email ends up there.</li>
                    </ul>
                    <br />
                    It is important to include the PDF version of the paper in the folder/directory because it 	will be used by the editorial staff for verification purposes. <br />
                    <br />
                    If you are using any material owned by third parties (such as, pictures, figures, tables, 	texts, ...), you would be responsible in getting the necessary permission to reuse the 	material from the owner.
                    </p>
                </li>
            </ol>
            <br />
            PAGE LIMITS (formatted using Springer Nature's Instructions): <br />
            - Papers accepted as "Regular Research Papers": max of 14 pages <br />
            - Papers accepted as "Short Research Papers":   max of 10 pages<br />
            - Papers accepted as "Poster Research Papers":  max of  5 pages (and preferably a minimum of 4 pages)<br />
            <br />
            Please direct any questions about the instructions mentioned above to: <br /> <br />
            Ms. Patti Davis <br />
            Springer Nature - Senior Editor <br />
            patti.davis@springernature.com  <br /> <br />


            </div>
            

          </div>
          

        </div>
      </div>
    );
  }
}

export default FinalPapers;