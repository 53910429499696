import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class Symps extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h6 className="par_header">CSCE'24 Conferences</h6>
            <div className="par">
            In order to leverage the synergies between various CS & CE fields, the program committee of the following conferences have their 2024 events held at one venue (same location and dates). Thus, this year, The Congress is composed of a number of tracks (joint-conferences, tutorials, sessions, workshops, poster and panel discussions); all will be held simultaneously, same location and dates: July 22-25, 2024. For the complete list of joint conferences, see below:
            <br/><br/>
              <div className="sym_links">
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-ACC">The 8th International Conference on Applied Cognitive Computing (ACC'24)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-BIOCOMP">The 25th International Conference on Bioinformatics & Computational Biology (BIOCOMP'24)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-BIOENG">The 10th International Conference on Biomedical Engineering & Sciences (BIOENG'24)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-CSC">The 22nd International Conference on Scientific Computing (CSC'24)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-EEE">The 23rd International Conference on e-Learning, e-Business, Enterprise Information Systems, & e-Government (EEE'24)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-ESCS">The 22nd International Conference on Embedded Systems, Cyber-physical Systems, & Applications (ESCS'24)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-FCS">The 20th International Conference on Foundations of Computer Science (FCS'24)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-FECS">The 20th International Conference on Frontiers in Education: Computer Science & Computer Engineering (FECS'24)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-GCC">The 20th International Conference on Grid, Cloud, & Cluster Computing (GCC'24)</Link><br/>
                <Icon icon='link' size="1x"/> <a  href="https://www.himscsce.org/hims24" target="_blank" rel="noopener noreferrer">The 10th International Conference on Health Informatics & Medical Systems (HIMS'24)</a> <br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-ICAI">The 26th International Conference on Artificial Intelligence (ICAI'24)</Link><br/>
                <Icon icon='link' size="1x"/> <a href="https://icdata.org" target="_blank" rel="noopener noreferrer">The 20th International Conference on Data Science (ICDATA'24)</a> <br/>
                <Icon icon='link' size="1x"/> <a href="https://baylor.ai/iceqt/" target="_blank" rel="noopener noreferrer">The 3rd International Conference on Emergent Quantum Technologies (ICEQT'24)</a> <br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-ICOMP">The 25th International Conference on Internet Computing & IoT (ICOMP'24)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-ICWN">The 23rd International Conference on Wireless Networks (ICWN'24)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-IKE">The 23rd International Conference on Information & Knowledge Engineering (IKE'24)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-IPCV">The 28th International Conference on Image Processing, Computer Vision, & Pattern Recognition (IPCV'24)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-MSV">The 21st International Conference on Modeling, Simulation & Visualization Methods (MSV'24)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-PDPTA">The 30th International Conference on Parallel & Distributed Processing Techniques & Applications (PDPTA'24)</Link><br/>
                <Icon icon='link' size="1x"/> <a href="http://sam.udmercy.edu/sam24/" target="_blank" rel="noopener noreferrer">The 23rd International Conference on Security & Management (SAM'24)</a><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/conferences-SERP">The 22nd International Conference on Software Engineering Research & Practice (SERP'24)</Link><br/>                
              </div>
              <br/>
              This year’s Congress will be a hybrid event that combines a “live” in-person event with a “virtual” online component. We anticipate that most speakers will physically be attending (ie, most talks are expected to be face-to-face).
            </div>
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Symps;