import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Keynote4 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Keynotes/Invited Lectures</h4>
            <br />
            <div style={{textAlign: "center"}}>
            <b>The Dawn of Cognitive AI, Self-Aware Robotics, and the Industrial Metaverse</b> <br/>
            Prof. Dr. Diego Galar<br/>
            Operation and Maintenance Engineering, <br />
            Lulea University of Technology (LTU), Sweden
            </div>
        
            <h6 className="par_header">Abstract:</h6>
            <div className="par">
            As we embark on the next great industrial revolution, the fusion of Artificial Intelligence (AI), Robotics, and the Metaverse is poised to redefine the landscape of modern manufacturing. This keynote speech will transport you into the future, where cutting-edge advancements in Generative AI are transforming industrial robots into cognitive, autonomous entities, seamlessly integrated with Digital Twins and the Metaverse.
            <br />
            Explore the latest trends and ground-breaking achievements that are pushing the boundaries of what's possible in industrial automation. Discover how AI is not only endowing robots with human-like cognition but also integrating them into a vast digital ecosystem. This ecosystem includes Digital Twins—virtual replicas of physical assets—that provide real-time data and analytics, and the Metaverse, an interconnected virtual world that offers immersive simulation and collaboration opportunities.
            <br />
            Intelligent systems are revolutionizing sectors such as automotive, aerospace, and consumer electronics, driving efficiency, reducing downtime, and sparking unprecedented innovation. Generative AI enables robots to learn, adapt, and evolve autonomously, creating a new paradigm of smart factories and intelligent supply chains. The Metaverse facilitates remote monitoring, predictive maintenance, and virtual prototyping, making industrial operations more agile and resilient.
            <br />
            Delve into how Generative AI, Robotics, Digital Twins, and the Metaverse converge to form a fully integrated digital framework. This framework drives unparalleled growth and innovation by blending the physical and digital worlds into a seamless, intelligent continuum. Witness the dawn of a new technological era that disrupts and redefines the landscape of modern industry.
            </div>

            <h6 className="par_header">Speaker's Biography:</h6>
            <div className="par">
            Dr. Diego Galar is a Full Professor in the Division of Operation and Maintenance Engineering at Luleå University of Technology (LTU), where he significantly contributes to the fields of maintenance, reliability, and industrial technologies. Additionally, he serves as the Research & Technology Director at Sisteplant (Spain), specializing in predictive maintenance solutions and condition monitoring.
            <br />
            Dr. Galar's academic journey and professional career have been marked by pivotal roles and accomplishments. He has held principal researcher positions at Tecnalia (Spain) and
served as the Volvo Chair as a Professor at Skovde University, emphasizing his expertise and leadership in condition monitoring. His research interests focus on predictive maintenance, condition monitoring, and the integration of Industry 4.0 technologies into maintenance practices.
<br />
He has played a crucial role in coordinating numerous European projects, emphasizing cyber-physical systems, Industry 4.0, IoT, and Industrial AI and Big Data. His collaborative efforts with the Swedish industry and national agencies, including Vinnova, have advanced maintenance technologies and practices. These projects often involve multi-disciplinary teams and international partners, reflecting Dr. Galar's ability to lead complex research initiatives.
<br />
With an extensive publication record that includes over 500 journal and conference papers, books, and technical reports, Dr. Galar's work is highly regarded in the maintenance field. His research has been widely cited, influencing the development of new methodologies and tools for predictive maintenance and condition monitoring. His scholarly contributions extend to editorial roles in leading journals and active participation in scientific committees. Dr. Galar frequently chairs international conferences, where he shares his insights as a keynote speaker.
<br />
Dr. Galar is actively involved in national and international committees for standardization and R&D in reliability and maintenance. He has been instrumental in developing standards and guidelines that have been widely adopted across various industries. His roles in these committees underscore his commitment to advancing the field through rigorous standards and best practices.
<br />
Dr. Galar's influence extends globally through visiting professorships at esteemed institutions such as the Polytechnic of Braganza (Portugal), University of Valencia (Spain), Northern Illinois University (USA), and the Universidad Pontificia Católica de Chile. He currently serves as a visiting professor at the University of Sunderland (UK), University of Maryland (USA), and Chongqing University (China). These positions reflect his international recognition and his dedication to global knowledge exchange in maintenance and reliability engineering.
<br />
In his roles as the secretary and director of industry relations at the European Federation of National Maintenance Societies (EFNMS), Dr. Galar actively promotes maintenance practices and standards across Europe. His leadership has driven significant advancements in maintenance education and the development of new certification programs for maintenance professionals.
<br />
Beyond his academic and professional pursuits, Dr. Galar is committed to community outreach and education. He regularly conducts workshops and training sessions for young engineers and technicians, emphasizing the importance of maintenance in ensuring the safety and efficiency of industrial operations. His efforts to bridge the gap between academia and industry have made him a respected figure in both realms, and his work continues to inspire the next generation of maintenance engineers and researchers.
            <br/> <br/>
            <div style={{textAlign: "center"}}>
                <img src="/static/galar21.jpg" height="300px" alt=""/>
            </div>
            </div>

          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Keynote4;