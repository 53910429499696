import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class Proceedings extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Proceedings</h4>
            <div className="par">
            Proceedings of CSCE 2024 and all its affiliated conferences are in production/preparation by IEEE Computer Society's Conference Publishing Services (CPS): 
            <br />
            <a href="https://www.computer.org/conferences/cps " target="_blank" rel="noopener noreferrer">https://www.computer.org/conferences/cps </a>
            <br />
            The proceedings will be made available by around the end of February 2024.

            <br /><br />
            Access the Table of Contents of the proceedings: <br />
            - For Word Doc version <a href="/static/table-of-contents-for-website-csce24.docx" target="_blank" rel="noopener noreferrer">CLICK HERE</a> <br />
            - FOR PDF version <a href="/static/table-of-contents-for-website-csce24.pdf" target="_blank" rel="noopener noreferrer">CLICK HERE</a>
            </div>
            

          </div>
          

        </div>
      </div>
    );
  }
}

export default Proceedings;