import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';


class Home extends Component {

  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
               
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">The 2024 World Congress in Computer Science, Computer Engineering, & Applied Computing (CSCE'24)</h4>
            <div className="par">
              Welcome to the 2024 World Congress in Computer Science, Computer Engineering, and Applied Computing website. 
              We anticipate having between 1,000 and 2,000 participants in the Congress. 
              The congress includes <b><Link to="/csce2024/conferences">21 major tracks</Link></b> composed of: 122 technical, research, and panel sessions as well as a number of keynote lectures and tutorials; all will be held simultaneously, same location and dates: July 22-25, 2024.
              This year’s Congress will be a hybrid event that combines a “live” in-person event with a “virtual” online component.
              We anticipate that most speakers will physically be attending (ie, most talks are expected to be face-to-face).
              <br/><br/>
              The Congress is among the top five largest annual gathering of researchers in computer science, computer engineering, data science, artificial intelligence, STEM, and applied computing. We anticipate to have attendees from about 75 countries and territories.
              {/* <a target="_blank" href=""> Read more </a> */}
            </div>

            <h6 className="par_header">Conference Objectives</h6>
            <div className="par">
            Last year, the Congress had attracted speakers/authors and participants affiliated with over 158 different universities (including many from the top 50 ranked institutions), major IT corporations (including: Microsoft, Google, Apple, SAP, Facebook, Oracle, Amazon, Yahoo, Samsung, IBM, Ebay, GE, Siemens, Philips, Ericsson, BAE Systems, Hitachi, NTT, Twitter, Uber Technologies, ...), major corporations (including: Exxon Mobil, Johnson & Johnson, JPMorgan Chase, PetroChina, GlaxoSmithKline, HSBC, Airbus, Boeing, Hyundai, Goldman Sachs, Deutsche Bank, ...), government research agencies (NSF, NIH, DoE, US Air Force, NSA National Security Agency, Central Intelligence Agency, ...), US national laboratories (including, NASA National Aeronautics and Space Administration, ANL Argonne National Lab, Sandia National Lab, ORNL Oak Ridge National Lab, Lawrence Berkeley National Lab, Lawrence Livermore National Lab, Los Alamos National Lab, Pacific Northwest National Lab, ...), and a number of Venture Capitalists as well as distinguished speakers discussing Intellectual Property issues. Last year, 54% of attendees were from academia, 25% from industry; 20% from government and funding agencies; and 1% unknown. About half of the attendees were from outside USA; from 69 nations.
            <br/> <br/>
            <b>An important mission of the Congress</b> is to Provide a unique platform for a diverse community of constituents composed of scholars, researchers, developers, educators, and practitioners. The Congress makes concerted effort to reach out to participants affiliated with diverse entities (such as: universities, institutions, corporations, government agencies, and research centers/labs) from all over the world. The congress also attempts to connect participants from institutions that have teaching as their main mission with those who are affiliated with institutions that have research as their main mission. The congress uses a quota system to achieve its institution and geography diversity objectives.
            <br/><br/>
            To get a feeling about the conferences' atmosphere, see:
            <br/>      
            <Icon icon='link' size="1x"/> <Link to="/csce2024/gallery">Photo Gallery</Link><br/>
            </div>

            <h6 className="par_header">INVITATION</h6>
            <div className="par">
            <b>You are invited to submit a paper for consideration.</b> All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings of Congress (CSCE). The published proceedings will be submitted for indexing in the Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; Scopus; EI Engineering Index; Google Scholar; DBLP; and others. Therefore, this year’s proceedings will have an elevated science indexation when compared to prior years’ publications.
            <br/> 
            
            {/* <br />
            <b>CSCE 2023 Proceedings (Last Year’s Xplore Indexation) can be found at:</b>
            <br /> */}
            {/* <a href="https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding</a> */}
            
             
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            {/* <br />
            Papers should be typeset by using the general typesetting instructions available at (select "US letter" option for accessing templates):
            <br />
            <a href="https://www.ieee.org/conferences/publishing/templates.html " target="_blank" rel="noopener noreferrer">https://www.ieee.org/conferences/publishing/templates.html</a> <br />
            (i.e., single line spacing, 2-column format). All submissions must be original (papers must not have been previously published or currently being considered by others for publication). */}

            <br /><br />
            Past publications of proceedings: <a href="https://american-cse.org/csce2024/publisher" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/publisher</a> and <a href="https://american-cse.org/csce2024/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/special_issues</a>
            <br /> <br />
            Paper submission portal:  <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a>
            {/* <Icon icon='link' size="1x"/> <a target="_blank" href="https://www.springer.com/series/11769">https://www.springer.com/series/11769</a><br/> */}
            <br/>

            {/* <b>Indexation:</b> Subject to Springer science indexation which includes: <a href="http://link.springer.com/" target="_blank" rel="noopener noreferrer">online Springer Link</a>, 
            <a href="http://www.info.scopus.com" target="_blank" rel="noopener noreferrer"> Scopus</a>, 
            SCI Compendex, <a href="http://www.ei.org" target="_blank" rel="noopener noreferrer">EI Compendex</a>, 
            EMBASE, Web of Science, Inspec, ACM digital library, Google Scholar, EBSCO, and others.
            <br/>
            <div style={{textAlign: "center"}}>
            <img src="/static/sp.png" alt=""/>
            </div> */}
            {/* <br/> */}
            {/* Prospective authors are invited to submit their papers by uploading them to the evaluation web site at:  <br/>
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a> 
            <br/><br/>
            See the web link below for a small subset of publications based on papers accepted in the
             CSCE proceedings: many of these books and journal special issues have already received the top 25% downloads in their 
             respective fields and/or identified as "Highly Accessed" by publishers and/or science citation index trackers.
            <br/>
            <Icon icon='link' size="1x"/> <Link to="/csce2024/special_issues">Special Issues</Link><br/>
            <br/>
            Refer to the link below for some information on citation record: <br/>
            <Icon icon='link' size="1x"/> <Link to="/csce2024/publisher">Publication</Link><br/> */}
            </div>
            
            
            <h6 className="par_header">Past Distinguished Speakers of the Congress</h6>
            <div className="par">
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. David Patterson</div>
                      Fellow: IEEE, ACM, AAAS & Member, National Academy of Engineering; Shared with John Hennessy, the IEEE John von Neumann Medal; University of California, Berkeley
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneering contributions to RISC processor design)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/patterson.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Ian Foster</div>
                      Fellow: ACM, AAAS, BCS; Distinguished Fellow, Argonne National Lab.; Distinguished Professor, University of Chicago
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Introduced Grid Computing)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/foster.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>The Late Prof. Lotfi A. Zadeh</div>
                      Fellow: IEEE, AAAS, ACM, AAAI, IFSA;
                      Member: National Academy of Engineering
                      University of California, Berkeley
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Father of Fuzzy Logic)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/zadeh.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. H. J. Siegel</div>
                      Fellow: IEEE & ACM; Distinguished Professor
                      Colorado State University (formerly at Purdue)
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneering contributions to Heterogeneous Computing)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/siegel.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>The Late Prof. John Holland</div>
                      University of Michigan
                      Ann Arbor, Michigan
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Father of Genetic Algorithms)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/holland.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Ruzena Bajcsy</div>
                      Member: National Academy of Eng., National Academy of Science; Fellow: IEEE, ACM, AAAI; Director, CITRIS
                      University of California, Berkeley
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneering contributions to AI, HCI & vision)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/bajcsy.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Firouz Naderi</div>
                      Former Director, Solar System Exploration, NASA's JPL;
                      Former NASA's Program Manager for Mars Explortion
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Contributed to some of America's most iconic robotic space missions)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/naderi.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. John R. Koza</div>
                      Former Consulting Prof., Stanford University
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Father of Genetic Programming)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/Koza.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. David Lorge Parnas</div>
                      Fellow: IEEE, ACM, RSC, CAE, GI; MRIA
                      McMaster University, Canada
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneer of Software Engineering)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/parnas-photo_02.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. John Gustafson</div>
                      Recipient of Inaugural Gordon Bell Prize;
                      CTO, Ceranovo, Inc.
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Inventor of Gustafson's Law)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/Gustafson.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Anousheh Ansari</div>
                      Chairwoman, Prodea Systems; Space Ambassador;
                      <br></br>
                      <div style={{color: '#4b6584'}}>(First Female Space Tourist)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/anousheh.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>

              <br/>
              <div style={{width: '100%', alignContent: "center", textAlign: "center", fontWeight: "bold"}}>
                <Icon icon='link' size="1x"/> <Link to="/csce2024/past_speakers">See more</Link> <Icon icon='link' size="1x"/>
              </div>
           
            </div>
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Home;