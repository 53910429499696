import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsSERP extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">SERP'24 - The 22nd Int'l Conf on Software Engineering Research and Practice</h4>
            <div className="par">
            SERP is an international conference that serves researchers, scholars, professionals, students, and academicians who are looking to both foster working relationships and gain access to the latest research results. It is being held jointly (same location and dates) with a number of other research conferences; namely, The 2024 World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE'24).
<br/><br/>
We anticipate having between 1,000 and 2,000 participants in the Congress. The congress includes 21 major tracks composed of: 122 technical, research, and panel sessions as well as a number of keynote lectures and tutorials; all will be held simultaneously, same location and dates: July 22-25, 2024. Last year, the Congress had attracted speakers/authors and participants affiliated with over 158 different universities (including many from the top 50 ranked institutions), major IT corporations (including: Microsoft, Google, Apple, SAP, Facebook, Oracle, Amazon, Yahoo, Samsung, IBM, Ebay, GE, Siemens, Philips, Ericsson, BAE Systems, Hitachi, NTT, Twitter, Uber Technologies, ...), major corporations (including: Exxon Mobil, Johnson & Johnson, JPMorgan Chase, PetroChina, GlaxoSmithKline, HSBC, Airbus, Boeing, Hyundai, Goldman Sachs, Deutsche Bank, ...), government research agencies (NSF, NIH, DoE, US Air Force, NSA National Security Agency, Central Intelligence Agency, ...), US national laboratories (including, NASA National Aeronautics and Space Administration, ANL Argonne National Lab, Sandia National Lab, ORNL Oak Ridge National Lab, Lawrence Berkeley National Lab, Lawrence Livermore National Lab, Los Alamos National Lab, Pacific Northwest National Lab, ...), and a number of Venture Capitalists as well as distinguished speakers discussing Intellectual Property issues. Last year, 54% of attendees were from academia, 25% from industry; 20% from government and funding agencies; and 1% unknown. About half of the attendees were from outside USA; from 69 nations.
<br/><br/>
The primary goal of this conference is to provide a platform for researchers, scientists, industry experts and scholars to share their novel ideas and research results on the application of human cognition models in various practical computing applications. Through this conference, the organizers would like to develop an interdisciplinary venue to contribute and discuss the ongoing innovations, applications and solutions to challenging problems of engineering human brain processes, learning mechanisms and decision making processes.
<br/><br/>
Typical formal definitions of Software Engineering include: <br/>
- "The systematic application of scientific and technological knowledge, methods, and experience to the design, implementation, testing, and documentation of software" <br/>
- "The application of a systematic, disciplined, quantifiable approach to the development, operation, and maintenance of software" <br/>
- "An engineering discipline that is concerned with all aspects of software production" <br/>
- "The establishment and use of sound engineering principles in order to economically obtain software that is reliable and works efficiently on real machines"
<br/><br/>
<b>You are invited to submit a paper for consideration.</b> All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings of Congress (CSCE). The published proceedings will be submitted for indexing in the Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; Scopus; EI Engineering Index; Google Scholar; DBLP; and others. Therefore, this year’s proceedings will have an elevated science indexation when compared to prior years’ publications.
            <br/>
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            {/* <br />
            Papers should be typeset by using the general typesetting instructions available at (select "US letter" option for accessing templates):
            <br />
            <a href="https://www.ieee.org/conferences/publishing/templates.html " target="_blank" rel="noopener noreferrer">https://www.ieee.org/conferences/publishing/templates.html</a> <br />
            (i.e., single line spacing, 2-column format). All submissions must be original (papers must not have been previously published or currently being considered by others for publication). */}

            <br /><br />
            Past publications of proceedings: <a href="https://american-cse.org/csce2024/publisher" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/publisher</a> and <a href="https://american-cse.org/csce2024/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/special_issues</a>
            <br /> <br />
            Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <br />
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a>
            <br/><br/>
            Topics of interest include, but are not limited to, the following: <br/>
            - Software Architectures<br/>
            - Software Design and Design Patterns<br/>
            - Architectural Analysis and Verifications Methods<br/>
            - Enterprise Software, Middleware, and Tools<br/>
            - Quality Oriented Software Architecture (Design and support)<br/>
            - Software Reliability, Safety and Security Methods<br/>
            - Software Reuse and Component-Based Software Engineering<br/>
            - UML/MDA and AADL<br/>
            - Agile Software Engineering and Development<br/>
            - Object Oriented Technology (Design and Analysis)<br/>
            - Software Metrics<br/>
            - Software Assurance and Dependability<br/>
            - Reverse and Architectural Recovery Methods<br/>
            - Domain Specific Software Engineering<br/>
            - Aerospace Software and System Engineering<br/>
            - Software Engineering Methodologies<br/>
            - Survivable Systems<br/>
            - Engineering of Safety/Mission Critical Systems<br/>
            - Software Testing, Evaluation and Analysis Technologies<br/>
            - Workflow Management - Computer Supported Cooperative Work (CSCW)<br/>
            - Project Management Issues<br/>
            - Distributed and Parallel Systems<br/>
            - Real-time Embedded Software Engineering<br/>
            - Automated Software Design and Synthesis<br/>
            - Theoretic Approaches (Formal Methods, Graph, ...)<br/>
            - Domain Modeling and Meta-modeling<br/>
            - Software Maintenance and Evolution<br/>
            - Component Based Software Engineering<br/>
            - Software Engineering Standards and Guidelines<br/>
            - Intelligent CASE Tools and Eclipse Plugins Issues<br/>
            - Multimedia in Software Engineering<br/>
            - Usability Engineering<br/>
            - Novel Software Tools and Environments<br/>
            - Pervasive Software Engineering<br/>
            - Requirement Engineering and Processes<br/>
            - Critical and Embedded Software Design<br/>
            - Service Oriented Software Architectures<br/>
            - Service Oriented Requirements Engineering<br/>
            - Middleware for Service Based Systems<br/>
            - Service Discovery and Composition<br/>
            - Software Product Lines<br/>
            - Semantic Web<br/>
            - Human Computer Interaction and Usability Engineering<br/>
            - Model Based Software Engineering<br/>
            - Aspect Oriented Software Engineering<br/>
            - Agent Architectures, Ontologies, Languages and Protocols<br/>
            - Multi-Agent Systems, Mobile Agents<br/>
            - E-Commerce Solutions and Applications<br/>
            - Mobile Commerce Technology and Applications<br/>
            - Programming Languages and Compilers<br/>
            - Autonomic Computing and Adaptive Systems<br/>
            - Artificial Intelligence Approaches to Software Engineering<br/>
            - Measurement and Empirical Software Engineering<br/>
            - Tutoring, Documentation Systems<br/>
            - Web-Based Tools, Applications and Environment<br/>
            - Web and Text Mining<br/>
            - Case Studies and Emerging Technologies<br/>
            </div>
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsSERP;