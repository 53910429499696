import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsICWN extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">ICWN'24 - The 23rd Int'l Conf on Wireless Networks</h4>
            <div className="par">
            ICWN is an international conference that serves researchers, scholars, professionals, students, and academicians who are looking to both foster working relationships and gain access to the latest research results. It is being held jointly (same location and dates) with a number of other research conferences; namely, The 2024 World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE'24).
<br/><br/>
We anticipate having between 1,000 and 2,000 participants in the Congress. The congress includes 21 major tracks composed of: 122 technical, research, and panel sessions as well as a number of keynote lectures and tutorials; all will be held simultaneously, same location and dates: July 22-25, 2024. Last year, the Congress had attracted speakers/authors and participants affiliated with over 158 different universities (including many from the top 50 ranked institutions), major IT corporations (including: Microsoft, Google, Apple, SAP, Facebook, Oracle, Amazon, Yahoo, Samsung, IBM, Ebay, GE, Siemens, Philips, Ericsson, BAE Systems, Hitachi, NTT, Twitter, Uber Technologies, ...), major corporations (including: Exxon Mobil, Johnson & Johnson, JPMorgan Chase, PetroChina, GlaxoSmithKline, HSBC, Airbus, Boeing, Hyundai, Goldman Sachs, Deutsche Bank, ...), government research agencies (NSF, NIH, DoE, US Air Force, NSA National Security Agency, Central Intelligence Agency, ...), US national laboratories (including, NASA National Aeronautics and Space Administration, ANL Argonne National Lab, Sandia National Lab, ORNL Oak Ridge National Lab, Lawrence Berkeley National Lab, Lawrence Livermore National Lab, Los Alamos National Lab, Pacific Northwest National Lab, ...), and a number of Venture Capitalists as well as distinguished speakers discussing Intellectual Property issues. Last year, 54% of attendees were from academia, 25% from industry; 20% from government and funding agencies; and 1% unknown. About half of the attendees were from outside USA; from 69 nations.
<br/><br/>
The primary goal of this conference is to provide a platform for researchers, scientists, industry experts and scholars to share their novel ideas and research results on the application of human cognition models in various practical computing applications. Through this conference, the organizers would like to develop an interdisciplinary venue to contribute and discuss the ongoing innovations, applications and solutions to challenging problems of engineering human brain processes, learning mechanisms and decision making processes.
<br/><br/>
<b>You are invited to submit a paper for consideration.</b> All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings of Congress (CSCE). The published proceedings will be submitted for indexing in the Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; Scopus; EI Engineering Index; Google Scholar; DBLP; and others. Therefore, this year’s proceedings will have an elevated science indexation when compared to prior years’ publications.
            <br/>
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            {/* <br />
            Papers should be typeset by using the general typesetting instructions available at (select "US letter" option for accessing templates):
            <br />
            <a href="https://www.ieee.org/conferences/publishing/templates.html " target="_blank" rel="noopener noreferrer">https://www.ieee.org/conferences/publishing/templates.html</a> <br />
            (i.e., single line spacing, 2-column format). All submissions must be original (papers must not have been previously published or currently being considered by others for publication). */}

            <br /><br />
            Past publications of proceedings: <a href="https://american-cse.org/csce2024/publisher" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/publisher</a> and <a href="https://american-cse.org/csce2024/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/special_issues</a>
            <br /> <br />
            Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <br />
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a>
            <br/><br/>
            Topics of interest include, but are not limited to, the following: <br/>
            - Power Management and Control, Low-Power Protocols<br/>
            - Wireless Applications, Mobile e-Commerce, Multimedia<br/>
            - Satellite-based Systems<br/>
            - Broadcast Networks<br/>
            - High Altitude Platforms<br/>
            - GPS, Location-based Service and Mobile Devices<br/>
            - Mobile Agents<br/>
            - Wireless and Mobile Applications<br/>
            - Routing, Multicasting, ...<br/>
            - Resource Management, Wireless QoS<br/>
            - Mobile Internet<br/>
            - Wireless Network Architectures<br/>
            - Mobile Computing<br/>
            - Wireless Security<br/>
            - Modeling, Simulation, ...<br/>
            - Personal Area Networks, Body Wireless Networks, Bluetooth<br/>
            - Wireless Sensor and Actuator Networks<br/>
            - Node Placement and Topology Control Issues<br/>
            - Coding and Modulation<br/>
            - Multi-user Detection<br/>
            - Mobile Wireless QoS, Radio Resource Management<br/>
            - Heterogeneous Wireless Networks, Radio Access Networks<br/>
            - Software-defined Radio, Reconfigurable Radio Networks<br/>
            - Wireless IP Networks, Interworking<br/>
            - Wireless Multimedia, QoS Adaptation<br/>
            - Disruption Tolerant Networks and Applications<br/>
            - Distributed Algorithms for Wireless Networks<br/>
            - Autonomous Mobile Sensor Networks<br/>
            - Emerging technologies and Related Issues<br/>
            <br/>
            <b>SENSOR NETWORKS + AD HOC NETWORKS:</b> <br/>
              - Wireless Information Networks: Power Management, Remote Sensing and Telemetry, WiFi, ZigBee, WiMax, Bluetooth, Wireless Network Protocols, Routing Techniques, Network Architecture, Ad Hoc Networks, Hierarchical Networks, Ubiquitous Computing.<br/>
              - Data Manipulation: Noise Reduction, Data Quality and Integrity, Sensor Data Fusion, Data Visualization, Aggregation, Classification and Tracking, Pattern Recognition, Reasoning on Sensor Data. <br/>
              - Sensor Networks Software: Interoperability, Scheduling, Tasking and Control, Internet of Things, Connectivity and Communication, Agent-based Simulation, Programming and Middleware. <br/>
              - Hardware and Systems: Hardware Design, Packaging, Testing and Reliability, Electronic Interfaces, RFID Readers and Tags, MEMS, Swarm Sensors, Sensor Types for Chemical and Biomedical Applications. <br/>
              - Signal Processing: Distributed and Collaborative Signal Processing, Multimedia Signal Processing, Fault Detection, Sparse Signal Processing, Statistical Signal Processing, Coding, and Compression, Neural Networks, Information Mining. <br/>
              - Sensor Networks and Challenges: Infrastructure Reliability, Energy Efficiency, Self-healing, Data Overload, Security and Vulnerability, Authentication, Fault Tolerance and Diagnosis, Environmental Impact Reduction. <br/>
              - Sensor Network Applications: Healthcare, Medical Imaging, Environment Monitoring, Industrial and Structural Monitoring, Smart Grids and Energy Control Systems, Smart Buildings and Smart Cities, Home Monitoring and Assisted Living Applications, Wireless Surveillance, Smart Fabrics and Wearables, Defense and Security, Vehicular Networks, and novel applications. <br/>
              - Ad Hoc Networks: Vehicular Networks, Underwater Networks, Underground Networks, Network Scalability and Capacity, Service Discovery, Middleware for Ad Hoc Networks, Body Area Networks, Personal Area Networks, Protocol Design, MAC and Transport Protocols, Network Control and Management, Quality of Service Provisioning, OFDM, Distributed MIMO, Node Localization, Synchronization, Reliability and Fault Tolerance, Security and Privacy, Performance Modeling and Analysis, Networks for the Smart Grid, and Applications of Ad Hoc Networks. <br/>
              <br/>
              <b>Pervasive Systems and Computing:</b> <br/>
              - Location-dependent/personalized Application<br/>
              - Hardware Support Pervasive Computing and Communication<br/>
              - Software Infrastructures for Pervasive System<br/>
              - System Architectures and Platforms<br/>
              - Embedded Computing with Applications<br/>
              - Low Power, Energy-Aware Pervasive Computing<br/>
              - Smart Devices, Spaces and Objects<br/>
              - Service Discovery Mechanisms and Protocols<br/>
              - Middleware Services and Agent Technologies<br/>
              - Positioning and Tracking Technologies<br/>
              - Integration of Wired and Wireless Networks<br/>
              - Personal Area Networks<br/>
              - Intelligent Devices and Environments<br/>
              - Context & Location Awareness, Context Based & Implicit Computing<br/>
              - User Interfaces and Interaction Models<br/>
              - Wearable Computers and Technologies<br/>
              - Resource Management in Pervasive Platforms<br/>
              - Security and Privacy Issues for Pervasive Systems<br/>
              - New Products, Standards, and Tools<br/>
              - Embodied Agents<br/>
              - Interactive Workspace<br/>
              - Ubiquitous Computing<br/>
              - Secure Collaboration in Uncertain Environments<br/>
              - Biometric Methods for Pervasive Computing<br/>
              - Applications and Case Studies<br/>
              - Emerging Topics in Pervasive Systems<br/>
            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsICWN;