import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt, faAt, faQuoteLeft, faQuoteRight, faUniversity, faClock, faTrophy,
          faGraduationCap, faLaptop, faCalendarAlt, faAtom, faCode, faChalkboardTeacher,
          faIdCard, faFileAlt, faCodeBranch, faBars, faSearch, faCalendarPlus, 
          faTimes, faTrashAlt,  faEdit, faNotesMedical, faCheckDouble, faHandshake, faWalking, faHouseUser} from '@fortawesome/free-solid-svg-icons'
import Header from './common/Header';
import Foot from './home/footer/Foot';
import {connect} from 'react-redux';
import {resizeAction, Spinner_Action} from '../actions';
// import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { Sidenav, Nav, Icon, Dropdown } from 'rsuite';
import { push } from 'react-router-redux'
import Spinner from './common/Spinner';

library.add(fab, faMapMarkerAlt, faAt, faQuoteLeft, faQuoteRight, faUniversity, faClock, faTrophy, faGraduationCap,
              faLaptop, faCalendarAlt, faAtom, faCode, faChalkboardTeacher, faIdCard, faFileAlt, faCheckDouble,
              faCodeBranch, faBars, faSearch, faCalendarPlus,  faTimes, faTrashAlt,  faEdit, faNotesMedical, faHandshake, faWalking,
              faHouseUser)

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_key: this.props.location.pathname,
      open_keys: [],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  
    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    updateWindowDimensions() {
      this.props.resizeAction({width: window.innerWidth, height: window.innerHeight, expanded: window.innerWidth >= 700? true:false })
    }

  componentDidUpdate = (prevProp) => {
    if(this.props.location.pathname != prevProp.location.pathname){
      this.setState({
        selected_key: this.props.location.pathname
      })

      if(this.props.location.pathname.includes("conferences")){
        this.setState({
          open_keys: ['/csce2024/conferences']
        })
      }else{
        this.setState({
          open_keys: []
        })
      }
    }
  }

  sideNav_select = (key) => {
    if(key == "photos") {
      window.open("https://photos.ucmss.com/", "_blank")
    }else if(key == "/csce2024/conferences-SAM"){
      window.open("http://sam.udmercy.edu/sam24/", "_blank")
    }
    else if(key == "/csce2024/conferences-ICDATA"){
      window.open("https://icdata.org", "_blank")
    }
    else if(key == "/csce2024/conferences-HIMS"){
      window.open("https://www.himscsce.org/hims24", "_blank")
    }
    else if(key == "/csce2024/conferences-ICEQT"){
      window.open("https://baylor.ai/iceqt/", "_blank")
    }
    else{
      this.setState({
        selected_key: key
      })
      this.props.push(key)
    }
  }

  render() {
    return (
      <div>
        <div>
          {this.props.Spinner_ActionReducer.show? <Spinner />: ''}
        </div>
        <div className="r_container">
        <Header />
        <div className="side_n_content">
          {this.props.resizeActionReducer.expanded &&
          <div className="nav_div">
          <Sidenav openKeys={this.state.open_keys} activeKey={this.state.selected_key} onSelect={(e)=> this.sideNav_select(e)}>
            <Sidenav.Body>
              <Nav>

                <div className="nav_logo"> 
                  <img
                    alt=""
                    src="/static/logo.png"
                  />
                </div>

                <Nav.Item eventKey="/csce2024/">
                Home
                </Nav.Item>


                {/* <Nav.Item eventKey="/csce2024/topics">
                Scope & List of Topics
                </Nav.Item> */}

                <Dropdown eventKey="/csce2024/conferences" title="Conferences" onClick={()=>{
                  let a = this.state.open_keys.length == 1? [] : ['/csce2024/conferences']
                  this.setState({
                    open_keys: a
                  }, () => {
                    this.props.push("/csce2024/conferences")
                  })
                }}>
                  <Dropdown.Item eventKey="/csce2024/conferences-ACC">ACC</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-BIOCOMP">BIOCOMP</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-BIOENG">BIOENG</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-CSC">CSC</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-EEE">EEE</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-ESCS">ESCS</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-FCS">FCS</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-FECS">FECS</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-GCC">GCC</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-HIMS">HIMS</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-ICAI">ICAI</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-ICDATA">ICDATA</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-ICEQT">ICEQT</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-ICOMP">ICOMP</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-ICWN">ICWN</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-IKE">IKE</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-IPCV">IPCV</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-MSV">MSV</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-PDPTA">PDPTA</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-SAM">SAM</Dropdown.Item>
                  <Dropdown.Item eventKey="/csce2024/conferences-SERP">SERP</Dropdown.Item>
                </Dropdown>

                {/* <Nav.Item eventKey="4">
                Proceedings & Indexation
                </Nav.Item> */}
                <Nav.Item eventKey="/csce2024/final_papers">
                Final Papers
                </Nav.Item>
                <Nav.Item eventKey="/csce2024/publisher">
                Publication
                </Nav.Item>
                <Nav.Item eventKey="/csce2024/paper_submission">
                Submission of Papers
                </Nav.Item>
                {/* <Nav.Item eventKey="/csce2024/paper_review_pocess">
                Paper Review Process
                </Nav.Item> */}
                <Nav.Item eventKey="/csce2024/paper_categories">
                Paper Categories
                </Nav.Item>
                <Nav.Item eventKey="/csce2024/program">
                Program/Schedules
                </Nav.Item>
                <Nav.Item eventKey="/csce2024/keynotes">
                Keynotes/Invited Lectures
                </Nav.Item>
                {/* <Nav.Item eventKey="/csce2024/approved_sessions">
                Approved Sessions/Journal Issues
                </Nav.Item> */}
                <Nav.Item eventKey="/csce2024/presentation_modes">
                Presentation Modes
                </Nav.Item>
                <Nav.Item eventKey="/csce2024/demography_history">
                Demography History
                </Nav.Item>
                {/* <Nav.Item eventKey="/csce2024/committees">
                Conference Organization
                </Nav.Item> */}
                <Nav.Item eventKey="/csce2024/registration">
                Registration
                </Nav.Item>
                {/* <Nav.Item eventKey="13">
                  Schedules
                </Nav.Item> */}
                <Nav.Item eventKey="/csce2024/venue">
                Venue
                </Nav.Item>
                {/* <Nav.Item eventKey="/csce2024/session_proposals">
                Session Proposals
                </Nav.Item> */}
                {/* <Nav.Item eventKey="/csce2024/authors">
                Authors of Accepted Papers
                </Nav.Item> */}
                <Nav.Item eventKey="/csce2024/deadlines">
                  Important Dates
                </Nav.Item>
                <Nav.Item eventKey="/csce2024/contact">
                  Contact
                </Nav.Item>
                <Nav.Item eventKey="/csce2024/special_issues">
                  Books & Journals Special Issues
                </Nav.Item>
                <Nav.Item eventKey="/csce2024/career_fair">
                  Career, Job, & Education Fair
                </Nav.Item>
                <Nav.Item eventKey="/csce2024/past_speakers">
                  Past Distinguished Speakers
                </Nav.Item>
                {/* <Dropdown.Item divider /> */}
                <Nav.Item eventKey="/csce2024/committees">
                  Committees
                </Nav.Item>
                <Nav.Item eventKey="/csce2024/gallery">
                  Photo Gallery
                </Nav.Item>

              </Nav>
            </Sidenav.Body>
          </Sidenav>
            </div>
            }
          <div className="content_div">
            {this.props.children}
          </div>
        </div>
        <Foot />
      </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    resizeActionReducer: state.resizeActionReducer,
    Spinner_ActionReducer: state.Spinner_ActionReducer
  }
}

const mapDispatchToProps = () => {
  return {
    push,
    resizeAction,
    Spinner_Action
  }
}
export default connect(mapStateToProps, mapDispatchToProps())(App);
