import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsSAM extends Component {

    componentWillMount = () => {
        // console.log(this.props)
        window.location.assign('http://sam.udmercy.edu/sam24/');
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">SAM'24 - The 23rd Int'l Conf on Security and Management</h4>
            <div className="par">
            SAM is an international conference that serves researchers, scholars, professionals, students, and academicians who are looking to both foster working relationships and gain access to the latest research results. It is being held jointly (same location and dates) with a number of other research conferences; namely, The 2024 World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE'24).
<br/><br/>
We anticipate having between 1,000 and 2,000 participants in the Congress. The congress includes 21 major tracks composed of: 122 technical, research, and panel sessions as well as a number of keynote lectures and tutorials; all will be held simultaneously, same location and dates: July 22-25, 2024. Last year, the Congress had attracted speakers/authors and participants affiliated with over 158 different universities (including many from the top 50 ranked institutions), major IT corporations (including: Microsoft, Google, Apple, SAP, Facebook, Oracle, Amazon, Yahoo, Samsung, IBM, Ebay, GE, Siemens, Philips, Ericsson, BAE Systems, Hitachi, NTT, Twitter, Uber Technologies, ...), major corporations (including: Exxon Mobil, Johnson & Johnson, JPMorgan Chase, PetroChina, GlaxoSmithKline, HSBC, Airbus, Boeing, Hyundai, Goldman Sachs, Deutsche Bank, ...), government research agencies (NSF, NIH, DoE, US Air Force, NSA National Security Agency, Central Intelligence Agency, ...), US national laboratories (including, NASA National Aeronautics and Space Administration, ANL Argonne National Lab, Sandia National Lab, ORNL Oak Ridge National Lab, Lawrence Berkeley National Lab, Lawrence Livermore National Lab, Los Alamos National Lab, Pacific Northwest National Lab, ...), and a number of Venture Capitalists as well as distinguished speakers discussing Intellectual Property issues. Last year, 54% of attendees were from academia, 25% from industry; 20% from government and funding agencies; and 1% unknown. About half of the attendees were from outside USA; from 69 nations.
<br/><br/>
The primary goal of this conference is to provide a platform for researchers, scientists, industry experts and scholars to share their novel ideas and research results on the application of human cognition models in various practical computing applications. Through this conference, the organizers would like to develop an interdisciplinary venue to contribute and discuss the ongoing innovations, applications and solutions to challenging problems of engineering human brain processes, learning mechanisms and decision making processes.
<br/><br/>
<b>You are invited to submit a paper for consideration.</b> All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings of Congress (CSCE). The published proceedings will be submitted for indexing in the Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; Scopus; EI Engineering Index; Google Scholar; DBLP; and others. Therefore, this year’s proceedings will have an elevated science indexation when compared to prior years’ publications.
            <br/>
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            {/* <br />
            Papers should be typeset by using the general typesetting instructions available at (select "US letter" option for accessing templates):
            <br />
            <a href="https://www.ieee.org/conferences/publishing/templates.html " target="_blank" rel="noopener noreferrer">https://www.ieee.org/conferences/publishing/templates.html</a> <br />
            (i.e., single line spacing, 2-column format). All submissions must be original (papers must not have been previously published or currently being considered by others for publication). */}

            <br /><br />
            Past publications of proceedings: <a href="https://american-cse.org/csce2024/publisher" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/publisher</a> and <a href="https://american-cse.org/csce2024/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/special_issues</a>
            <br /> <br />
            Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <br />
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a>
            <br/><br/>
            Topics of interest include, but are not limited to, the following: <br/>
            <b>Network Security:</b> <br/>
            - Security Algorithms<br/>
            - Mobile Network Security<br/>
            - Security in CDN (Contents Distribution Networks)<br/>
            - Virtual Private Network (VPN)<br/>
            - Tracing Techniques in Internet<br/>
            - Active Networks<br/>
            - Security in Grid<br/>
            - Web Monitoring<br/>
            - Control Systems/SCADA Security<br/>
            - Network Security Engineering<br/>
            - Transport-Level Security<br/>
            - Wireless Network Security<br/>
            - IP Security<br/>
            - Electronic Mail security  <br/>
            <br/>
            <b>Cryptographic Technologies:</b><br/>
            - Security Protocols<br/>
            - Key Management Techniques<br/>
            - Cryptographic Technologies<br/>
            - Applications of Block and Stream Ciphers<br/>
            - Applications of Public Key Cryptology<br/>
            - Message Authentication and Hash Functions<br/>
            - Anonymity<br/>
            - Digital Signature Schemes<br/>
            - Secret Sharing<br/>
            - Cryptanalysis<br/>
            <br/>
            <b>Security Management:</b><br/>
            - Surveillance Technologies<br/>
            - Security Policies<br/>
            - Security for Protocol Management<br/>
            - Location Management<br/>
            - QoS Management<br/>
            - Resource Management<br/>
            - Channel Management<br/>
            - Mobility Management<br/>
            - Digital Contents Copyright Protection<br/>
            - System Security Management<br/>
            - Network Security Management<br/>
            - Management in Network Equipment<br/>
            - Storage Area Networks (SAN) Management<br/>
            - Information Security Management<br/>
            - Government Security Policy<br/>
            - Web Penetration Testing<br/>
            - Security Operations<br/>
            - Vulnerabilities Management<br/>
            - Change and Patch Management<br/>
            <br/>
            <b>Security Applications: </b><br/>
            - Security in E-Commerce and M-Commerce<br/>
            - Secure OS<br/>
            - Watermarking<br/>
            - High-Tech Systems at Airports<br/>
            - Emerging Technologies and Applications<br/>
            - Cloud Computing Security<br/>
            - Database Security<br/>
            - Data Mining Security<br/>
            - Cyber Security Automation<br/>
            <br/>
            <b>Biometrics and Forensics:</b><br/>
            - Novel Biometric Methods<br/>
            - Forensics<br/>
            - Biological Security Technologies<br/>
            - Face Recognition Systems<br/>
            - Signature Recognition Systems<br/>
            - Cyber Forensics<br/>
            - Forensic Analysis<br/>
            - Biometric Technologies for Security<br/>
            - Feature Extraction and Matching Algorithms<br/>
            <br/>
            <b>Hardware Security:</b><br/>
            - Embedded Systems Security<br/>
            - Cryptographic Processors and Co-Processors<br/>
            - Security Architectures<br/>
            - True and Pseudorandom Number Generators<br/>
            - Side Channel Attacks<br/>
            - Fault Attacks<br/>
            - Hardware Tamper Resistance<br/>
            - Smart Card Processors<br/>
            - Secure Storage Devices<br/>
            <br/>
            <b>Information Assurance:</b><br/>
            - Mission Assurance<br/>
            - Risk Assessment and Risk Management<br/>
            - Continuity of Operations and Business Impact Analysis<br/>
            - Cyber Security Compliance<br/>
            - Security Auditing<br/>
            - Security-Savvy Software Development<br/>
            - Disaster Recovery<br/>
            - Business Continuity Analysis<br/>
            - Access Control<br/>
            - Secure Use of Software<br/>
            - Secure Performance<br/>
            <br/>
            <b>Computer Security: </b><br/>
            - Firewall Systems<br/>
            - Hacking Techniques and Related Issues<br/>
            - Intrusion Detection System (IDS)<br/>
            - Honeypot<br/>
            - Virus Issues (Detection, Prevention ...)<br/>
            - Trusted Computing<br/>
            - Alert Correlation<br/>
            - Attack Graphs<br/>
            - Incident Responding<br/>
            - Malware Analysis<br/>
            - Incident Responding<br/>
            - Malware Analysis<br/>
            - Intrusion Detection<br/>
            <br/>
            <b>Security Education:</b><br/>
            - Computer Security Education<br/>
            - Network Security Education<br/>
            - Cyber Security Education<br/>
            - Cyber Security Body of Knowledge<br/>
            - Information Assurance Education<br/>
            </div>

          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsSAM;