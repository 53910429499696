import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsEEE extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">EEE'24 - The 23rd Int'l Conf on e-Learning, e-Business, Enterprise Information Systems, and e-Government</h4>
            <div className="par">
            EEE is an international conference that serves researchers, scholars, professionals, students, and academicians who are looking to both foster working relationships and gain access to the latest research results. It is being held jointly (same location and dates) with a number of other research conferences; namely, The 2024 World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE'24).
            <br/><br/>
            We anticipate having between 1,000 and 2,000 participants in the Congress. The congress includes 21 major tracks composed of: 122 technical, research, and panel sessions as well as a number of keynote lectures and tutorials; all will be held simultaneously, same location and dates: July 22-25, 2024. Last year, the Congress had attracted speakers/authors and participants affiliated with over 158 different universities (including many from the top 50 ranked institutions), major IT corporations (including: Microsoft, Google, Apple, SAP, Facebook, Oracle, Amazon, Yahoo, Samsung, IBM, Ebay, GE, Siemens, Philips, Ericsson, BAE Systems, Hitachi, NTT, Twitter, Uber Technologies, ...), major corporations (including: Exxon Mobil, Johnson & Johnson, JPMorgan Chase, PetroChina, GlaxoSmithKline, HSBC, Airbus, Boeing, Hyundai, Goldman Sachs, Deutsche Bank, ...), government research agencies (NSF, NIH, DoE, US Air Force, NSA National Security Agency, Central Intelligence Agency, ...), US national laboratories (including, NASA National Aeronautics and Space Administration, ANL Argonne National Lab, Sandia National Lab, ORNL Oak Ridge National Lab, Lawrence Berkeley National Lab, Lawrence Livermore National Lab, Los Alamos National Lab, Pacific Northwest National Lab, ...), and a number of Venture Capitalists as well as distinguished speakers discussing Intellectual Property issues. Last year, 54% of attendees were from academia, 25% from industry; 20% from government and funding agencies; and 1% unknown. About half of the attendees were from outside USA; from 69 nations.
            <br/><br/>
            e-Learning is learning utilizing electronic technologies to access educational curriculum outside of a traditional classroom.  In most cases, it refers to a course, program or degree delivered completely online.. 
            <br/><br/>
            The primary goal of this conference is to provide a platform for researchers, scientists, industry experts and scholars to share their novel ideas and research results on the application of human cognition models in various practical computing applications. Through this conference, the organizers would like to develop an interdisciplinary venue to contribute and discuss the ongoing innovations, applications and solutions to challenging problems of engineering human brain processes, learning mechanisms and decision making processes.
            <br/><br/>
            <b>You are invited to submit a paper for consideration.</b> All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings of Congress (CSCE). The published proceedings will be submitted for indexing in the Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; Scopus; EI Engineering Index; Google Scholar; DBLP; and others. Therefore, this year’s proceedings will have an elevated science indexation when compared to prior years’ publications.
            <br/>
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            {/* <br />
            Papers should be typeset by using the general typesetting instructions available at (select "US letter" option for accessing templates):
            <br />
            <a href="https://www.ieee.org/conferences/publishing/templates.html " target="_blank" rel="noopener noreferrer">https://www.ieee.org/conferences/publishing/templates.html</a> <br />
            (i.e., single line spacing, 2-column format). All submissions must be original (papers must not have been previously published or currently being considered by others for publication). */}

            <br /><br />
            Past publications of proceedings: <a href="https://american-cse.org/csce2024/publisher" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/publisher</a> and <a href="https://american-cse.org/csce2024/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/special_issues</a>
            <br /> <br />
            Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <br />
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a>
            
            <br/><br/>
            Topics of interest include, but are not limited to, the following:
            <br/>
            <b>e-Learning:</b> <br/>
            - e-Learning design and methodologies<br/>
            - e-Learning portals<br/>
            - Instructional design methodologies<br/>
            - Audio and video technologies for e-Learning<br/>
            - Authoring tools<br/>
            - e-Learning technologies and tools<br/>
            - Social impact and cultural issues in e-Learning<br/>
            - Content management and development<br/>
            - Policy issues in e-Learning<br/>
            - On-demand e-Learning<br/>
            - e-Learning standards<br/>
            - Assessment methodologies<br/>
            - Knowledge management<br/>
            - Virtual learning environments<br/>
            - AI and e-Learning<br/>
            - On-line education (all levels: elementary, secondary, ...) <br/>
            - Open-source e-Learning platforms<br/>
            - Training and evaluation strategies<br/>
            - e-Universities<br/>
            - Case studies and emerging applications<br/>
            <br/>
            <b>e-Business:</b><br/>
            - e-Business systems integration and standardization<br/>
            - Electronic negotiation systems and protocols<br/>
            - Internet payment systems<br/>
            - e-Procurement methods<br/>
            - Techniques for B2B e-Commerce<br/>
            - Global e-Commerce and e-Business<br/>
            - e-Business models and architectures<br/>
            - Service-oriented e-Commerce<br/>
            - Trust, security, and privacy in e-Commerce and e-Business<br/>
            - Intelligence in e-Commerce<br/>
            - Secure Databases and e-Commerce applications<br/>
            - Business-oriented and consumer-oriented e-Commerce<br/>
            - Development of e-Business and applications<br/>
            - e-Business in developing countries<br/>
            - Novel marketing strategies on the web<br/>
            - Organizational and management issues<br/>
            - Supply chain management<br/>
            - e-Retailing and web design<br/>
            - Applications of new technologies to e-Business<br/>
            - Middleware technologies to support e-business<br/>
            - Case studies and applications<br/>
            <br/>
            <b>Enterprise Information Systems:</b><br/>
            - Strategic decision support systems<br/>
            - Organizational semiotics and semiotics in computing<br/>
            - Datacenters, data warehouses and technologies<br/>
            - Knowledge management<br/>
            - Enterprise resource planning and e-Business<br/>
            - middleware integration<br/>
            - Intranet and extranet business applications<br/>
            - Databases and information systems integration<br/>
            - Intelligent agents<br/>
            - Enterprise-wide client-server architectures<br/>
            - Information systems analysis and specification<br/>
            - Ontology engineering<br/>
            - CASE tools for system development<br/>
            - B2B and B2C applications<br/>
            - Business processes re-engineering<br/>
            - Market-spaces: market portals, hubs, auctions, ... <br/>
            - Semantic web technologies and Cloud computing<br/>
            - Web interfaces and usability<br/>
            - Human factors and e-Learning<br/>
            - Case studies and applications<br/>
            <br/>
            <b>e-Government:</b><br/>
            - e-Democracy and e-Voting<br/>
            - e-Government<br/>
            - Use of e-Government methodologies to prevent corruption<br/>
            - Legal aspects of e-Government<br/>
            - Risk management<br/>
            - Methods and tools for e-Government<br/>
            - Policies and strategies<br/>
            - Designing web services for e-Government<br/>
            - Trust and security in e-Government<br/>
            - Enterprise architecture for e-Government<br/>
            - Interoperability frameworks in e-Government<br/>
            - Inter-administration and G2G issues<br/>
            - Public and private partnership<br/>
            - Teaching e-Government<br/>
            - Case studies<br/>
            </div>

          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsEEE;