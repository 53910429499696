import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsICAI extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">ICAI'24 - The 26th Int'l Conf on Artificial Intelligence</h4>
            <div className="par">
            ICAI is an international conference that serves researchers, scholars, professionals, students, and academicians who are looking to both foster working relationships and gain access to the latest research results. It is being held jointly (same location and dates) with a number of other research conferences; namely, The 2024 World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE'24).
<br/><br/>
  We anticipate having between 1,000 and 2,000 participants in the Congress. The congress includes 21 major tracks composed of: 122 technical, research, and panel sessions as well as a number of keynote lectures and tutorials; all will be held simultaneously, same location and dates: July 22-25, 2024. Last year, the Congress had attracted speakers/authors and participants affiliated with over 158 different universities (including many from the top 50 ranked institutions), major IT corporations (including: Microsoft, Google, Apple, SAP, Facebook, Oracle, Amazon, Yahoo, Samsung, IBM, Ebay, GE, Siemens, Philips, Ericsson, BAE Systems, Hitachi, NTT, Twitter, Uber Technologies, ...), major corporations (including: Exxon Mobil, Johnson & Johnson, JPMorgan Chase, PetroChina, GlaxoSmithKline, HSBC, Airbus, Boeing, Hyundai, Goldman Sachs, Deutsche Bank, ...), government research agencies (NSF, NIH, DoE, US Air Force, NSA National Security Agency, Central Intelligence Agency, ...), US national laboratories (including, NASA National Aeronautics and Space Administration, ANL Argonne National Lab, Sandia National Lab, ORNL Oak Ridge National Lab, Lawrence Berkeley National Lab, Lawrence Livermore National Lab, Los Alamos National Lab, Pacific Northwest National Lab, ...), and a number of Venture Capitalists as well as distinguished speakers discussing Intellectual Property issues. Last year, 54% of attendees were from academia, 25% from industry; 20% from government and funding agencies; and 1% unknown. About half of the attendees were from outside USA; from 69 nations.
  <br/><br/>
  Artificial Intelligence (AI) is the science and engineering of making intelligent machines and systems. This is an important multi-disciplinary field which is now an essential part of technology industry, providing the heavy lifting for many of the most challenging problems in computer science. Since Machine Learning has strong ties with AI, the conference also covers the field of Machine Learning. The list of topics below is by no means meant to be exhaustive.  
  <br/><br/>
  The primary goal of this conference is to provide a platform for researchers, scientists, industry experts and scholars to share their novel ideas and research results on the application of human cognition models in various practical computing applications. Through this conference, the organizers would like to develop an interdisciplinary venue to contribute and discuss the ongoing innovations, applications and solutions to challenging problems of engineering human brain processes, learning mechanisms and decision making processes.
  <br/><br/>
  <b>You are invited to submit a paper for consideration.</b> All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings of Congress (CSCE). The published proceedings will be submitted for indexing in the Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; Scopus; EI Engineering Index; Google Scholar; DBLP; and others. Therefore, this year’s proceedings will have an elevated science indexation when compared to prior years’ publications.
            <br/>
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            {/* <br />
            Papers should be typeset by using the general typesetting instructions available at (select "US letter" option for accessing templates):
            <br />
            <a href="https://www.ieee.org/conferences/publishing/templates.html " target="_blank" rel="noopener noreferrer">https://www.ieee.org/conferences/publishing/templates.html</a> <br />
            (i.e., single line spacing, 2-column format). All submissions must be original (papers must not have been previously published or currently being considered by others for publication). */}

            <br /><br />
            Past publications of proceedings: <a href="https://american-cse.org/csce2024/publisher" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/publisher</a> and <a href="https://american-cse.org/csce2024/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/special_issues</a>
            <br /> <br />
            Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <br />
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a>
            <br/><br/>
            Papers that address any of the following topics in the context of Artificial Intelligence are of interest: <br/>
            <b>Artificial Intelligence:</b> <br/>
            - Brain models, Brain mapping, Cognitive science<br/>
            - Natural language processing<br/>
            - Fuzzy logic and soft computing<br/>
            - Software tools for AI<br/>
            - Expert systems<br/>
            - Decision support systems<br/>
            - Automated problem solving<br/>
            - Knowledge discovery<br/>
            - Knowledge representation<br/>
            - Knowledge acquisition<br/>
            - Knowledge-intensive problem solving techniques<br/>
            - Knowledge networks and management<br/>
            - Intelligent information systems<br/>
            - Intelligent data mining and farming<br/>
            - Intelligent web-based business<br/>
            - Intelligent agents<br/>
            - Intelligent networks<br/>
            - Intelligent databases<br/>
            - Intelligent user interface<br/>
            - AI and evolutionary algorithms<br/>
            - Intelligent tutoring systems<br/>
            - Reasoning strategies<br/>
            - Distributed AI algorithms and techniques<br/>
            - Distributed AI systems and architectures<br/>
            - Neural networks and applications<br/>
            - Heuristic searching methods<br/>
            - Languages and programming techniques for AI<br/>
            - Constraint-based reasoning and constraint programming<br/>
            - Intelligent information fusion<br/>
            - Learning and adaptive sensor fusion<br/>
            - Search and meta-heuristics<br/>
            - Multisensor data fusion using neural and fuzzy techniques<br/>
            - Integration of AI with other technologies<br/>
            - Evaluation of AI tools<br/>
            - Social intelligence (markets and computational societies) <br/>
            - Social impact of AI<br/>
            - Emerging technologies<br/>
            - Applications (including: computer vision, signal processing, military, surveillance, robotics, medicine, pattern recognition, face recognition, finger print recognition, finance and marketing, stock market, education, emerging applications, ...) <br/>
            <br/>
            <b>Machine Learning; Models, Technologies and Applications:</b><br/>
            - Statistical learning theory<br/>
            - Unsupervised and Supervised Learning<br/>
            - Multivariate analysis<br/>
            - Hierarchical learning models<br/>
            - Relational learning models<br/>
            - Bayesian methods<br/>
            - Meta learning<br/>
            - Stochastic optimization<br/>
            - Simulated annealing<br/>
            - Heuristic optimization techniques<br/>
            - Neural networks<br/>
            - Reinforcement learning<br/>
            - Multi-criteria reinforcement learning<br/>
            - General Learning models<br/>
            - Multiple hypothesis testing<br/>
            - Decision making<br/>
            - Markov chain Monte Carlo (MCMC) methods<br/>
            - Non-parametric methods<br/>
            - Graphical models<br/>
            - Gaussian graphical models<br/>
            - Bayesian networks<br/>
            - Particle filter<br/>
            - Cross-Entropy method<br/>
            - Ant colony optimization<br/>
            - Time series prediction<br/>
            - Fuzzy logic and learning<br/>
            - Inductive learning and applications<br/>
            - Grammatical inference<br/>
            - Graph kernel and graph distance methods<br/>
            - Graph-based semi-supervised learning<br/>
            - Graph clustering<br/>
            - Graph learning based on graph transformations<br/>
            - Graph learning based on graph grammars<br/>
            - Graph learning based on graph matching<br/>
            - Information-theoretical approaches to graphs<br/>
            - Motif search<br/>
            - Network inference<br/>
            - Aspects of knowledge structures<br/>
            - Computational Intelligence<br/>
            - Knowledge acquisition and discovery techniques<br/>
            - Induction of document grammars<br/>
            - General Structure-based approaches in information retrieval, web authoring, information extraction, and web content mining<br/>
            - Latent semantic analysis<br/>
            - Aspects of natural language processing<br/>
            - Intelligent linguistic<br/>
            - Aspects of text technology<br/>
            - Biostatistics<br/>
            - High-throughput data analysis<br/>
            - Computational Neuroscience<br/>
            - Computational Statistics<br/>
            </div>            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsICAI;