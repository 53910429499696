import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Keynote6 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Keynotes/Invited Lectures</h4>
            <br />
            <div style={{textAlign: "center"}}>
            <b>Prediction + Explanation: Explainable Machine Learning in Healthcare </b> <br/>
            Dr. Ahmad P. Tafti <br/>
            Director,  Pitt HexAI Research Laboratory,<br />
            Department of Health Information Management,<br />
            School of Health and Rehabilitation Sciences, <br />
            (Secondary appointment: Intelligent Systems Program (ISP), School of Computing and Information) <br />
            University of Pittsburgh, PA, USA <br /><br />
            </div>
        
            <h6 className="par_header">Abstract:</h6>
            <div className="par">
            While machine learning algorithms have demonstrated very successful applications in a variety of real-world scenarios, the adoption and implementation of machine learning methods in healthcare is a little bit behind from what we are seeing in other high-stake settings, such as airplane navigation and automotive industry. One of the major concerns beside regularity frameworks, patient private data, and healthcare policies, is explainability and interpretability of machine learning algorithms. With the use of machine learning explainability, we are thus opening the black box in a way our clinicians, surgeons, and even our patients can clearly understand how machine learning methods are operating, how they are making a decision, and why! In this talk, we will be exploring explainable machine learning in healthcare settings, particularly within stage-specific cancer survivability prediction. 
            </div>

            <h6 className="par_header">Speaker’s Biography:</h6>
            <div className="par">
            Ahmad P. Tafti is an Assistant Professor of Health Informatics in the Department of Health Information Management ( <a href="https://www.shrs.pitt.edu/him" target="_blank" rel="noopener noreferrer">https://www.shrs.pitt.edu/him</a> ) within the School of Health and Rehabilitation Sciences ( <a href="https://www.shrs.pitt.edu/" target="_blank" rel="noopener noreferrer">https://www.shrs.pitt.edu/</a> ) at the University of Pittsburgh ( <a href="https://www.pitt.edu/" target="_blank" rel="noopener noreferrer">https://www.pitt.edu/</a> ), with a secondary appointment in the Intelligent Systems Program (ISP) ( <a href="https://www.isp.pitt.edu/" target="_blank" rel="noopener noreferrer">https://www.isp.pitt.edu/</a> ), at the School of Computing and Information ( <a href="https://www.sci.pitt.edu/" target="_blank" rel="noopener noreferrer">https://www.sci.pitt.edu/</a> ). He is leading Pitt efforts at the Pitt HexAI Research Laboratory ( <a href="https://pitthexai.github.io/" target="_blank" rel="noopener noreferrer">https://pitthexai.github.io/</a> ), directing Health and Explainable AI Podcast series ( <a href="https://podcasters.spotify.com/pod/show/hexailab" target="_blank" rel="noopener noreferrer">https://podcasters.spotify.com/pod/show/hexailab</a> ). Furthermore, he is affiliated with the Center for AI Innovation in Medical Imaging (CAIIMI) ( <a href="https://www.aimi.pitt.edu/" target="_blank" rel="noopener noreferrer">https://www.aimi.pitt.edu/</a> ), also serving as an Associate Member at UPMC Hillman Cancer Center. Ahmad P. Tafti is honored to serve our community as the Vice Chair of IEEE Computer Society at Pittsburgh. He is the 2021 SiiM Imaging Informatics Innovator awardee, Oracle for Research Project awardee, University of Pittsburgh CTSI awardee, Mayo Clinic Benefactor funded CDAs Orthopedics Career Development awardee, Mayo Clinic Transform the Practice awardee, an NVIDIA GPU awardee, and GE Healthcare Honorable Mention awardee. 
            <br/> <br/>
            <div style={{textAlign: "center"}}>
                <img src="/static/tafti.png" height="250px" alt=""/>
            </div>
            </div>

          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Keynote6;