import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Keynote1 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Keynotes/Invited Lectures</h4>
            <br />
            <div style={{textAlign: "center"}}>
            <b>The Effect of Tech Savviness of the Design and Interaction of
AI Systems: The Exploration of Trust</b> <br/>
Professor John C. Murray <br/>
University of Sunderland, United Kingdom<br/>
            </div>
        
            <h6 className="par_header">Abstract:</h6>
            <div className="par">
            Examining how self-perceived tech savviness influences an individual’s reliance on AI technologies in their daily lives, we look into AI perceptions, considering comfort, trust, usefulness, ease of use, and habit formation around such AI interactions. With the increase in pervasiveness and applications of AI systems, their use in everyday lives, it is more important than ever to understand the interplay between humans and AI systems. The findings from our work emphasises the interplay of tech savviness and self-confidence in shaping AI adoption. Designing inclusive AI interfaces and creating educational interventions tailored to diverse backgrounds are crucial for promoting AI use and unlocking its full potential for all users. Within this talk we also touch on latest developments in embodiment and how the presentation of AI through different modalities can impact the perception, trust and acceptance of AI systems by its users. 
            </div>

            <h6 className="par_header">Speaker's Biography:</h6>
            <div className="par">
            Professor Murray is Academic Dean of the Faculty of Technology at the University of Sunderland, UK, and Professor of Robotics and Autonomous Systems. He has spent much of his academic research career in the area of Human-Robot Interaction, and Ethics and Trust around Artificial Intelligence and Machine Learning systems and has worked on many research projects from FP7 to H2020 and with businesses and organisations including DASA, QinetiQ, UK Home Office, Borderforce, Health organisations including the NHS and many more. He is specifically interested in the acceptance of technology, robotics and AI into society, the social challenges of this and how we design AI and Robotic systems to be socially acceptable. This includes looking at work around human factors, cognitive biases and personality and ethics. He also has interests in security, from computer security (such as ethics, encryption) to border security (through work with the UK Home Office and Border Force) to food security and policy.
            <br/> <br/>
            <div style={{textAlign: "center"}}>
                <img src="/static/murray1.jpg" height="300px" style={{padding:"5px"}} alt=""/>
                <img src="/static/murray2.jpg" height="300px"  style={{padding:"5px"}} alt=""/>
            </div>
            </div>          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Keynote1;