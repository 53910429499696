import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Keynote7 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Keynotes/Invited Lectures</h4>
            <br />
            <div style={{textAlign: "center"}}>
            <b>High-Level Development of Distributed Real-Time Applications Using Mobile Cloud and Software-Defined Networking</b> <br/>
            Professor Sergei Gorlatch<br/>
            Full Professor, Chair of Parallel and Distributed Computing<br />
            University of Muenster, Germany<br />
            </div>
        
            <h6 className="par_header">Abstract:</h6>
            <div className="par">
            We address the software development process for challenging class of Real-Time Online Interactive Applications (ROIA). ROIA are distributed applications connecting a potentially very high number of users who interact with the application and with each other in real time, i.e., a response to a user’s action happens virtually immediately. Typical representatives of ROIA are massively multiplayer online computer games, advanced simulation-based e-learning and serious gaming. These applications are characterized by high performance and Quality-of-Service (QoS) requirements, such as: short response times to user inputs (about 0.1-1.5 sec); frequent state updates (up to 100 Hz); large and frequently changing numbers of users in a single application instance (up to tens of thousands simultaneous users). This talk will present our high-level development framework RTF and address two challenging issues in future Internet-based ROIA applications: a) using Mobile Cloud Computing for allowing high application performance when a ROIA application is accessed from multiple mobile devices, and b) managing dynamic QoS requirements of ROIA applications by employing the emerging technology of Software-Defined Networking (SDN)
            </div>

            <h6 className="par_header">Speaker’s Biography:</h6>
            <div className="par">
            Prof. Dr. habil. Sergei Gorlatch is an internationally acknowledged expert in the area of algorithms, architectures, software and applications for modern and emerging computer and networked systems. Sergei Gorlatch has been Full Professor of Computer Science at the University of Muenster (Germany) since 2003. Earlier he was Associate Professor at the Technical University of Berlin, Assistant Professor at the University of Passau, and Humboldt Research Fellow at the Technical University of Munich, all in Germany. <br /> <br />
            Prof. Gorlatch has more than 200 peer-reviewed publications in refereed international books, journals, and conferences. Among his recent achievements at top conferences are the following: the paper at the ACM ICFP was recognized as an ACM SIGPLAN Research Highlight 2021 with a publication in the Communications of the ACM; the paper presented at the ACM/IEEE CGO 2018 was prized with the Best Paper Award of the conference. <br /> <br />
            Prof. Gorlatch has been principal investigator in several international research and development projects in the field of parallel, distributed, Grid and Cloud programming, software-defined networking, as well as e-Learning, funded by the European Commission and by German national bodies. His group has developed, among others, the novel Real-Time Framework (RTF) for high-level development of real-time, highly interactive applications like multi-player online games and advanced e-Learning. In the area of high-performance computing, his group has developed the high-level parallel library SkelCL, the Auto-Tuning Framework (ATF), and the PACXX framework for efficient programming of modern multi-core CPU and GPU architectures using standard C++ 14/17. <br /><br />
            Sergei Gorlatch holds PhD degree in Applied Mathematics and Computer Science from the National Institute of Cybernetics in Kiev/Ukraine and Habilitation degree in Computer Science from the University of Passau in Germany.
            <br/> <br/>
            <div style={{textAlign: "center"}}>
                <img src="/static/gorlatch.jpg" height="250px" alt=""/>
            </div>
            </div>

          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Keynote7;