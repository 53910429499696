import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Deadlines extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          {/* <h5 className="par_header">Important Dates</h5> */}
            {/* <h6 className="par_header">Paper Submission Deadline (Late Papers): June 8, 2024</h6>
            <div className="par">
            Due to numerous requests, the paper submission deadline is extended to June 8, 2024.
            Authors who have submitted their papers in response to earlier announcements, will receive the decision/status report of their papers shortly (if they have not yet received the decision).
              <br/> <br/>
              Authors who have already received a decision on their papers are to follow the instructions and the deadlines that appeared in the notification that they have received.
            </div> */}

            {/* <h6 className="par_header">Paper Submission Deadline (Late Breaking Papers): June 9, 2024 <br /> 
            <span style={{fontSize: "0.81rem"}}>Short Research Papers (Max of 6 pages) & Poster Research Papers (Max of 3 pages)</span>
             </h6>
            <div className="par"> 
            We can only consider Short Research Papers (max of 6 pages) and Poster Research Papers (max of 3 pages): <br />
            <br />
            Due to numerous requests (Easter Break, Pandemic, End of Semester, …), the paper submission deadline is extended to June 9, 2024. <br /> <br />
            Authors who have already received a decision on their papers are to follow the instructions and the deadlines that appeared in the notification that they have received.
            </div> */}

            <h4 className="par_header"><b>DATES:</b></h4>
            <div className="par">
            {/* Due to numerous requests, the paper submission deadline is extended as shown below. Authors who have submitted their papers in response to earlier announcements, will receive the decision/status report of their papers according to the original announced dates.
            <br /><br />
            Authors who have already received a decision on their papers are to follow the instructions and the deadlines that appeared in the notification that they have already received.
            <br /><br /> */}
            {/* <b>March 20, 2024:</b> <br/> 
            Opening Date for Paper Submission. <br />
            <a target="_blank" href="https://american-cse.org/drafts/">https://american-cse.org/drafts/</a>
              <br/><br /> */}

              {/* <b>May 15, 2024:</b> 
              <br /> Closing Date for Paper Submission
              <br/><br /> */}
              <b>Submission of Papers for Evaluation:</b> 
              <br /> Since the proceedings will be published AFTER the conference, CSCE is flexible with submission date (Revolving Date) – however, the absolute deadline for submission is July 7, 2024
              <br/><br />

              <b>Notification of Acceptance:</b> 
              <br /> Revolving Date (7 to 14 days after the submission)
              <br/><br />

              <b>Submission of Final Paper for Publication:</b> 
              <br /> August 10, 2024
              <br/><br />

              {/* <b>June 4, 2024:</b> 
              <br /> Closing Date for Paper Submission.
              <br /> Submission of Late Breaking Papers: <br /> <br/>
              - Full / Regular Research Papers (max of 8 pages) <br/>
              - Short Research Papers (max of 5 pages) <br/>
              - Abstract/Poster Papers (max of 3 pages) <br />
              Information about the type of papers: <br />
              <a href="https://american-cse.org/csce2024/paper_categories" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/paper_categories</a>
              <br/> */}
              {/* Decisions will be emailed to the authors about 7 days after the date of submission (The referees for this round of submissions receive a small honorarium pay; we are confident that most referees would provide their expert opinions within days after the papers are released to them for evaluation.) */}
              {/* <br /><br />
              <b>Due Dates for uploading the final manuscripts to the publication portal:</b> <br /> The date will appear in the notifications that will be sent to the authors of accepted papers.
              <br /><br />
              <b>Registration Deadline for Authors:</b> <br /> The date will appear in the notifications that will be sent to the authors of accepted papers (Online Registration System: <a href="https://american-cse.org/csce2024/registration" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/registration</a> )
              <br /><br /> */}

              {/* <b>June 10, 2024:</b> <br/> Notification
              <br/><br /> */}

              {/* <b>June 30, 2024:</b> <br/> Final Paper Submissions <br/><br />

              <b>March 20, 2024:</b> <br/>  Registration Date - started. <br />
              <br /> */}
              
              <b>Registration Date for Authors:</b> <br/> July 10, 2024 <br/>
              <a href="https://american-cse.org/csce2024/registration" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/registration</a>
              <br/><br />

              <b>Venue & Hotel Reservation:</b> <br />July 6, 2024  <br/>
              Online: &nbsp;
              <a href="https://american-cse.org/csce2024/venue" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/venue</a>
              <br /> <br />

              {/* <b>June 20, 2024:</b> <br /> Please email your presentation option to the congress.<br/>
              OPTIONS: <br />
              <a href="https://american-cse.org/csce2024/presentation_modes" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/presentation_modes</a> 
              <br />
              This year's Congress will be a hybrid event that combines a "live" 				in-person event with a "virtual" online component. We anticipate 				that most speakers will physically be attending (ie, most talks are 				expected to be face-to-face).
              <br /> */}
              
              {/* <b>May 12, 2024:</b> Final papers + Registration<br/> */}
              
              {/* <b>REGISTRATION:     Revolving Date:</b>  <br /> The deadline will appear in the notifications that
                     will be sent to the authors of accepted papers.
                     <br /> Online Registration System: <br />
                     <a href="https://american-cse.org/csce2024/registration" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/registration</a>
                     <br/><br /> */}

              {/* <b>June 21, 2024:    Hotel Reservation & Information:</b> <br />
              Online: <a href="https://american-cse.org/csce2024/venue" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/venue</a>
               <br/><br /> */}
              
               {/* <b>June 30, 2024:    Please email your presentation option to the Congress.</b> <br />
               OPTIONS: <br />
               <a href="https://american-cse.org/csce2024/presentation_modes " target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/presentation_modes</a> <br />
               This year's Congress will be a hybrid event that
                     combines a "live" in-person event with a "virtual"
                     online component. We anticipate that most speakers
                     will physically be attending (ie, most talks are
                     expected to be face-to-face).
               */}
              {/* <b>June 23, 2024: </b> Hotel Room reservation (for those who are physically attending the conference).<br/>
              Use the online room 	reservation system at: <a href="https://american-cse.org/csce2024/venue" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/venue</a> <br />
              <br /> */}
              
              <b>July 22-25, 2024:</b> <br /> The 2024 World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE'24: USA) <br/>
              Including all affiliated federated/joint conferences <br />              
              <a href="https://www.american-cse.org/csce2024/ ">https://www.american-cse.org/csce2024/</a> <br />
              <a href="https://american-cse.org/csce2024/conferences" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/conferences</a>
                
                
                {/* <br /> <br />
                Venue/Hotel: <a href="https://american-cse.org/csce2024/venue" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/venue</a>
             */}
            {/* <br /><br />
            This year's Congress will be a hybrid event that combines a "live" in-person event
with a "virtual" online component. We anticipate that most speakers will
physically be attending (ie, most talks are expected to be face-to-face). Refer to
the link below for presentation options: <br />
 <a href="https://american-cse.org/csce2024/presentation_modes" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/presentation_modes</a>
            <br /><br />
                <b>CONTACT:</b> <br />
                Questions and inquiries should be sent to: <br />
                CSCE'24 Conference Secretariat: <a href="cs@american-cse.org" target="_blank" rel="noopener noreferrer">cs@american-cse.org</a>
               <br/><br /> */}
            </div>

            {/* <br/> */}

            {/* <h5 className="par_header">Important Dates for LATE BREAKING PAPERS, POSITION PAPERS, ABSTRACTS/POSTERS</h5> */}

            {/* <div className="par">
            Due to numerous requests for extending the Paper Submission Deadline,
            we have now extended the deadlines for the following category
            of papers: The deadlines that appear below, only apply to papers submitted
            after October 18, 2024 (ie, this is an opportunity for those
            authors who missed the original deadline):
            <br/><br/>
            <b>June 5, 2024: </b>  Submission of Papers <a target="_blank" href="https://american-cse.org/">(https://american-cse.org/)</a> <br/>
            <b>Revolving Date: </b>  Notification of acceptance will be emailed in about 72 hours from the date of submission. <br/>
            <b>June 30, 2024: </b> Final papers & Author Registrations <br/> */}

            {/* <b>July 16-18, 2024: </b> The 2024 International Conference on Computational
                        Science and Computational Intelligence
                        (CSCE'24: Las Vegas, USA) <br/>
                        <a href="https://www.american-cse.org/csce2024/ ">(https://www.american-cse.org/csce2024/)</a>
              */}

              {/* <br/>
              For more details about the LATE Breaking Papers, POSITION Papers, ABSTRACT/POSTER Papers 
              and the submission instructions, refer to: <br/>
              <a href="https://american-cse.org/" target="_blank">https://american-cse.org/</a>

              <br/> <br/>
              Since the proceedings will be published soon AFTER the conference
              by IEEE CPS, we are quite FLEXIBLE with paper submission deadline.
              WE HAVE A REVOLVING DEADLINE: ie, papers will be refereed within
              72 hours from the date of submission using our fast-track evaluation
              system (FTE) where referees receive a small honorarium payment for
              their services.

            </div> */}

            
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Deadlines;