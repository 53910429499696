import React, { Component } from 'react';
import { Form, Button,Col, Row, Modal, ListGroup, Tabs, Tab, Table  } from 'react-bootstrap';
import country_list from '../Reg_Pages/_countries'
import { push, replace } from 'react-router-redux'
import {connect} from 'react-redux';
import {alertMess, Spinner_Action} from '../../actions';
import axios from "axios";
import conf from '../../_Conf'

class Visa_Form extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    onNext1 = () => {
        this.props.alertMess({isVis: false, message:'', error:false});
        let obj = {
            email: document.getElementById("visa_f_email").value,
            title: document.getElementById("visa_f_title").value,
            fn: document.getElementById("visa_f_fn").value,
            ln: document.getElementById("visa_f_ln").value,
            add1: document.getElementById("visa_f_add1").value,
            add2: document.getElementById("visa_f_add2").value,
            country: document.getElementById("visa_f_country").value,
            conf: document.getElementById("visa_f_confs").value,
            pid: document.getElementById("visa_f_paperid").value,
            pt: document.getElementById("visa_f_papert").value,
            cms: document.getElementById("visa_f_comments").value,
        }

        if(obj.email == ""){this.props.alertMess({isVis: true, message:'Please insert email', error:true}); return;}
        if(obj.title == "Select"){this.props.alertMess({isVis: true, message:'Please select title', error:true}); return;}
        if(obj.fn == ""){this.props.alertMess({isVis: true, message:'Please insert first name', error:true}); return;}
        if(obj.ln == ""){this.props.alertMess({isVis: true, message:'Please insert last name', error:true}); return;}
        if(obj.add1 == ""){this.props.alertMess({isVis: true, message:'Please insert address', error:true}); return;}
        if(obj.country == "Select"){this.props.alertMess({isVis: true, message:'Please select country', error:true}); return;}
        if(obj.conf == "Select"){this.props.alertMess({isVis: true, message:'Please select conference', error:true}); return;}
        if(obj.pid == ""){this.props.alertMess({isVis: true, message:'Please insert paper id', error:true}); return;}
        if(obj.pt == ""){this.props.alertMess({isVis: true, message:'Please insert paper title', error:true}); return;}        

        this.props.Spinner_Action({show:true})
        axios.post(conf.Production? conf.ProductionAddress + 'csce2024/visa_form_req/' : 'http://127.0.0.1:5050/' + 'csce2024/visa_form_req/',
        obj,
        { headers: { 'Content-Type': 'application/json' } }
        ).then(response => {
            this.props.replace(
            {
                pathname: '/csce2024/visa/done',
                state: { form: obj }
            })
            this.props.Spinner_Action({show:false})
        }
        ).catch( err => {
            this.props.alertMess({isVis: true, message:err.response.data.errors, error:true})
            this.props.Spinner_Action({show:false})
          })
    }

    render() {
    return (
        <div className="r_container">
        <div className="home">
            <div className="new_banner">
                <div className="banner_content">
                <h1>CSCE 2024</h1>
                <h6>The 2024 World Congress in</h6>
                <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
                <h6>July 22-25, 2024, Las Vegas, USA</h6>
                </div>

                <div className="new_banner_overlay"></div>
            </div>

            <div className="page_content">
                <h6 className="par_header">Visa - Personal Info</h6>
                <div className="reg_form" style={{"max-width": "40rem", margin: "2rem auto"}}>
                    <Form>
                    <Row>
                        <Col sm={4}>
                            <Form.Label>Email: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="visa_f_email" />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <Form.Label>Title: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="visa_f_title" as="select">
                                <option>Select</option>
                                <option>Mr.</option>
                                <option>Ms.</option>
                                <option>Prof.</option>
                                <option>Dr.</option>
                            </Form.Control>
                        </Col>
                    </Row>
    
                    <Row>
                        <Col sm={4}>
                            <Form.Label>First Name: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="visa_f_fn" />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <Form.Label>Last Name: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="visa_f_ln" />
                        </Col>  
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <Form.Label>Address - Line 1: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="visa_f_add1" />
                        </Col>  
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <Form.Label>Address - Line 2: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="visa_f_add2" />
                        </Col>  
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <Form.Label>Country: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="visa_f_country" as="select" >
                                <option>Select</option>
                                {
                                    country_list.map((i)=>{
                                        return <option id={i.code}>{i.name}</option>
                                    })
                                }
                            </Form.Control>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <Form.Label>Conference: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="visa_f_confs" as="select">
                                <option>Select</option>
                                <option>ACC</option>
                                <option>BIOCOMP</option>
                                <option>BIOENG</option>
                                <option>CSC</option>
                                <option>EEE</option>
                                <option>ESCS</option>
                                <option>FCS</option>
                                <option>FECS</option>
                                <option>GCC</option>
                                <option>HIMS</option>
                                <option>ICAI</option>
                                <option>ICDATA</option>
                                <option>ICEQT</option>
                                <option>ICOMP</option>
                                <option>ICWN</option>
                                <option>IKE</option>
                                <option>IPCV</option>
                                <option>MSV</option>
                                <option>PDPTA</option>
                                <option>SAM</option>
                                <option>SERP</option>
                            </Form.Control>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <Form.Label>Paper ID: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="visa_f_paperid" />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <Form.Label>Title of Paper: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="visa_f_papert" />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <Form.Label>Comments: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="visa_f_comments" />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Button onClick={this.onNext1}>
                            Next
                        </Button>
                    </Row>
                </Form>
              </div>

            </div>
        
        
        </div>
        </div>

    );
  }
}

const mapStateToProps = state => {
    return {
        alertReducer: state.alertReducer,
        Spinner_ActionReducer: state.Spinner_ActionReducer,
    }
  }
  
  const mapDispatchToProps = () => {
    return {
      push,
      alertMess,
      replace,
      Spinner_Action
    }
  }
export default connect(mapStateToProps, mapDispatchToProps())(Visa_Form);