import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsPDPTA extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">PDPTA'24- The 30th Int'l Conf on Parallel and Distributed Processing Techniques and Applications</h4>
            <div className="par">
            PDPTA is an international conference that serves researchers, scholars, professionals, students, and academicians who are looking to both foster working relationships and gain access to the latest research results. It is being held jointly (same location and dates) with a number of other research conferences; namely, The 2024 World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE'24).
<br/><br/>
We anticipate having between 1,000 and 2,000 participants in the Congress. The congress includes 21 major tracks composed of: 122 technical, research, and panel sessions as well as a number of keynote lectures and tutorials; all will be held simultaneously, same location and dates: July 22-25, 2024. Last year, the Congress had attracted speakers/authors and participants affiliated with over 158 different universities (including many from the top 50 ranked institutions), major IT corporations (including: Microsoft, Google, Apple, SAP, Facebook, Oracle, Amazon, Yahoo, Samsung, IBM, Ebay, GE, Siemens, Philips, Ericsson, BAE Systems, Hitachi, NTT, Twitter, Uber Technologies, ...), major corporations (including: Exxon Mobil, Johnson & Johnson, JPMorgan Chase, PetroChina, GlaxoSmithKline, HSBC, Airbus, Boeing, Hyundai, Goldman Sachs, Deutsche Bank, ...), government research agencies (NSF, NIH, DoE, US Air Force, NSA National Security Agency, Central Intelligence Agency, ...), US national laboratories (including, NASA National Aeronautics and Space Administration, ANL Argonne National Lab, Sandia National Lab, ORNL Oak Ridge National Lab, Lawrence Berkeley National Lab, Lawrence Livermore National Lab, Los Alamos National Lab, Pacific Northwest National Lab, ...), and a number of Venture Capitalists as well as distinguished speakers discussing Intellectual Property issues. Last year, 54% of attendees were from academia, 25% from industry; 20% from government and funding agencies; and 1% unknown. About half of the attendees were from outside USA; from 69 nations.
<br/><br/>
The primary goal of this conference is to provide a platform for researchers, scientists, industry experts and scholars to share their novel ideas and research results on the application of human cognition models in various practical computing applications. Through this conference, the organizers would like to develop an interdisciplinary venue to contribute and discuss the ongoing innovations, applications and solutions to challenging problems of engineering human brain processes, learning mechanisms and decision making processes.
<br/><br/>
<b>You are invited to submit a paper for consideration.</b> All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings of Congress (CSCE). The published proceedings will be submitted for indexing in the Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; Scopus; EI Engineering Index; Google Scholar; DBLP; and others. Therefore, this year’s proceedings will have an elevated science indexation when compared to prior years’ publications.
            <br/>
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            {/* <br />
            Papers should be typeset by using the general typesetting instructions available at (select "US letter" option for accessing templates):
            <br />
            <a href="https://www.ieee.org/conferences/publishing/templates.html " target="_blank" rel="noopener noreferrer">https://www.ieee.org/conferences/publishing/templates.html</a> <br />
            (i.e., single line spacing, 2-column format). All submissions must be original (papers must not have been previously published or currently being considered by others for publication). */}

            <br /><br />
            Past publications of proceedings: <a href="https://american-cse.org/csce2024/publisher" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/publisher</a> and <a href="https://american-cse.org/csce2024/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2024/special_issues</a>
            <br /> <br />
            Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <br />
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a>
            <br/><br/>
            Topics of interest include, but are not limited to, the following: <br/>
            - Big Data and High-Performance Computing: Novel computational methodologies, HPC and scalable algorithms for enhancing data quality, models and frameworks for Big Data, graph algorithms, computational science, computational intelligence, Scalable systems and architectures for Big Data, ...<br/>
            - Parallel/Distributed Applications: Numerical computations/methods, neural networks and fuzzy logic, medicine, remote sensing, computer vision, computer graphics and virtual reality, parallel/distributed databases, banking, financial markets, high-performance computational biology, ...<br/>
            - Parallel/Distributed Architectures: Clusters and parallel systems of various topologies, supercomputers, shared memory, distributed memory, general- and special-purpose architectures, instructional level parallelism, ...<br/>
            - Networks and Interconnection Networks: Scalable networks, reconfigurable networks, routing issues, general-purpose network performance for distributed applications, network protocols, internet technology, optical interconnections and computing, novel network topologies, ...<br/>
            - Energy/power-aware Computing: Energy-aware resource management, green computing, energy efficiency planning, energy-aware technologies, low power virtualization and software techniques, low power logic/circuit/process technology design, methods and tools for forecasting, estimating, and validating power use, ...<br/>
            - Reliability and Fault-tolerance: Software and hardware fault-tolerance (system- and application-level), fault diagnosis, fault-tolerance measurement.<br/>
            - Building Block Processors: Applications of processors that can be used as basic building blocks for multicomputer systems.<br/>
            - Real-time and Embedded Systems: Small-scale parallel systems for high-performance control, data acquisition, and analysis; configuration, routing, scheduling, performance guarantees, ...<br/>
            - Parallel/Distributed Algorithms: Algorithms exploiting clusters and general-purpose distributed and parallel systems, new vector/pipeline issues, shared memory, distributed memory, virtual memory, ...<br/>
            - Multimedia Communications, Systems, and Applications: High-speed networking, multimedia architectures and protocols, multimedia applications, quality of service support, operating system and networking support, internet tools and applications, audio/video delivery over the internet, ...<br/>
            - Software Tools and Environments for Parallel and Distributed Platforms: Operating systems, compilers, languages, debuggers, monitoring tools, software engineering on parallel/distributed systems, ...<br/>
            - High-performance Computing in Computational Science: Intra-disciplinary and multi-disciplinary research programs and applications.<br/>
            - Performance Evaluation and Management of Wireless Networks and Distributed Systems.<br/>
            - FPGA-based Design.<br/>
            - Performance Analysis, Evaluation, Prediction, ...<br/>
            - Nanotechnology in HPC.<br/>
            - High-performance Mobile Computation and Communication.<br/>
            - Object Oriented Technology and Related Issues.<br/>
            - Scheduling and Resource Management.<br/>
            - Petri Nets: Theory, Analysis, Tools and Applications.<br/>
            - Web-based Simulation and Computing.<br/>
            - Cloud Computing.<br/>
            - Complex Systems, Theory and Applications.<br/>
            - Other Aspects and Applications relating to High-Performance Computations.<br/>
            - Emerging Technologies.<br/>
            </div>
              
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsPDPTA;