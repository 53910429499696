import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';
import { Accordion, Card, Button  } from 'react-bootstrap';

class Approved_Sesss extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2024</h1>
              <h6>The 2024 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 22-25, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Approved Sessions and Journal Special Issues</h4>
            <div className="par">To Be Announced Later.</div>
            
            {/* <h6 className="par_header">The Journal of Supercomputing</h6>
            <div className="par">
              <b>In association with&nbsp;
                <a href="https://www.american-cse.org/csce2024/conferences-PDPTA" target="_blank" rel="noopener noreferrer">PDPTA</a>&nbsp;&&nbsp;
                <a href="https://www.american-cse.org/csce2024/conferences-GCC" target="_blank" rel="noopener noreferrer">GCC</a>&nbsp;&&nbsp;
                <a href="https://www.american-cse.org/csce2024/conferences-ICOMP" target="_blank" rel="noopener noreferrer">ICOMP</a>: Multiple Special Issues
              </b>            
            <br/> <br/>
                Journal Special Issues (Specific Issue Titles: TBA)
                <div style={{textAlign: "center"}}>
                    <a href="https://www.springer.com/journal/11227" target="_blank">
                      <div className="readMoreBtnAS">
                        <b> Read More </b>
                      </div>
                    </a>
                </div>    
            </div>

            <hr/>

            <h6 className="par_header">Journal Special Issue "Data Science and Big Data in Biology, Physical Science and Engineering"</h6>
            <div className="par">
            <b>In association with <a href="https://icdatascience.org/" target="_blank" rel="noopener noreferrer">ICDATA</a> & <a href="https://www.american-cse.org/csce2024/conferences-IKE" target="_blank" rel="noopener noreferrer">IKE</a></b>            
            <br/> <br/>
                <div style={{textAlign: "center"}}>
                    <a href="https://www.mdpi.com/journal/technologies/special_issues/Data_Science_Biology" target="_blank">
                      <div className="readMoreBtnAS">
                        <b> Read More </b>
                      </div>
                    </a>
                </div>    
            </div>

            <hr/>

            <h6 className="par_header">Special Session on Blockchain Technology and Applications (BTA 2024)</h6>
            <div className="par">
                <b>In association with <a href="https://www.american-cse.org/csce2024/conferences-PDPTA" target="_blank" rel="noopener noreferrer">PDPTA'24- The 27th Int'l Conf on Parallel and Distributed Processing Techniques and Applications</a></b>            
                <br/> <br/>
                <div style={{textAlign: "center"}}>
                    <Link to="/csce2024/approved_sessions/BTA21">
                      <div className="readMoreBtnAS">
                        <b> Read More </b>
                      </div>
                    </Link>
                </div>    
            </div>

            <hr/>

            <h6 className="par_header">Special Session on Emerging Trends & Technologies in Computing & Management (ETTICM)</h6>
            <div className="par">
            <b>In association with <a href="https://www.american-cse.org/csce2024/conferences-EEE" target="_blank" rel="noopener noreferrer">EEE'24 - The 20th Int'l Conf on e-Learning, e-Business, Enterprise Information Systems, and e-Government</a></b>            
            <br/> <br/>
                <div style={{textAlign: "center"}}>
                    <Link to="/csce2024/approved_sessions/ETTICM">
                      <div className="readMoreBtnAS">
                        <b> Read More </b>
                      </div>
                    </Link>
                </div>    
            </div>


            <hr/>

            <h6 className="par_header">Special Session on Agile IT Service Practices for the Cloud – a Customer Perspective</h6>
            <div className="par">
            <b>In association with <a href="https://www.american-cse.org/csce2024/conferences-EEE" target="_blank" rel="noopener noreferrer">EEE'24 - The 20th Int'l Conf on e-Learning, e-Business, Enterprise Information Systems, and e-Government</a></b>            
            <br/> <br/>
                <div style={{textAlign: "center"}}>
                    <Link to="/csce2024/approved_sessions/AgileIT">
                      <div className="readMoreBtnAS">
                        <b> Read More </b>
                      </div>
                    </Link>
                </div>    
            </div> */}
            
          </div>

          
          

        </div>
      </div>
    );
  }
}

export default Approved_Sesss;